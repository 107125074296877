import { useBoolean } from '@chakra-ui/react';
import { captureException } from '@sentry/nextjs';
import download from 'downloadjs';
import Cookies from 'js-cookie';
import { isUndefined } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';

import {
  convertRawBookingRequest,
  ServerResponse,
  RawLoadDetails,
  convertRawLoadDetails,
  RawUpchargeDetails,
  convertRawUpchargeDetails,
  RawTerminalInfo,
  convertRawTerminalInfo,
  ErrorCode,
  convertLegacyBooking,
  convertFtlRfqToQuery,
  convertQueryFTLQuote,
  convertLtlRfqToQuery,
  convertQueryLTLQuote,
} from '@/backend/BackendTypes';
import { GSAppContext } from '@/components/context/GSAppContext';
import useBalanceCheckout from '@/utils/hooks/useBalanceCheckout';
import {
  RFQ,
  BookingConfirmation,
  BookingRequest,
  Booking,
  Quote,
  PaymentInfo,
  CostBreakdown,
  isLtlRfq,
  LoadDetails,
  ShipmentType,
  FTL_RFQ,
  LTL_RFQ,
  UpchargeConfig,
  UpchargeDetails,
  TerminalInfo,
  SignedInError,
  PreconditionError,
  LTLQuote,
  PrepareBookingError,
} from '@/utils/types';
import {
  BrokerContext,
  FetchBookingError,
  GetLoadDetailsError,
  CalculationError,
  DownloadError,
  RequestQuotesError,
  ShippingDocumentType,
} from '@/utils/types/BrokerContext';

import 'isomorphic-fetch';
import { CostCalculateInput, Log_Status, Shipment_Type } from '../types.d';

import getCarriers from './graphql/address/getCarriers';
import getLegacyBookings from './graphql/address/getLegacyBooking';
import useBookShipment from './graphql/booking/useBookShipment';
import useCalculateCost from './graphql/booking/useCalculateCost';
import usePrepareBooking from './graphql/booking/usePrepareBooking';
import useSaveBookingLogGQL from './graphql/bookingLog/useSaveBookingLogGQL';
import useUpdateBookingLogGQL from './graphql/bookingLog/useUpdateBookingLogGQL';
import requestFTLQuote from './graphql/quotes/requestFTLQuote';
import requestLTLQuote from './graphql/quotes/requestLTLQuote';
import createUpchargeTransactionMutation from './graphql/transaction/createUpchargeTransactionMutation';
import useCityStateSearch from './graphqlpublic/addressSearch/useCityStateSearch';
import { getLatestAccessToken, isSignedIn } from './Tokens';

const useBackendBrokerContext = (backend: string): BrokerContext => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [txnId, setTxnId] = useState('');
  const [bookingId, setBookingId] = useState('');
  const [bookingLogId, setBookingLogId] = useState('');
  const [quoteLogId, setQuoteLogId] = useState('');

  const useRequestQuotes = (
    rfq: RFQ,
  ): {
    error: RequestQuotesError | PreconditionError | null;
    errorMessage: string;
    loading: boolean;
    quotes: Quote[];
  } => {
    const [loading, setLoading] = useState(false);
    const [quotes, setQuotes] = useState([] as Quote[]);
    const [error, setError] = useState<
      RequestQuotesError | PreconditionError | null
    >(null);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
      const loadLTL = async (ltlRFQ: LTL_RFQ) => {
        try {
          const accessToken = await getLatestAccessToken();
          const uuid = crypto.randomUUID();
          const gaCid = Cookies.get('_ga');
          setQuoteLogId(uuid);
          const quotes = await requestLTLQuote(
            { ...convertLtlRfqToQuery(ltlRFQ), uuid, gaCid },
            accessToken ? true : false,
          );
          if (!quotes) {
            setError('NetworkError');
            setErrorMessage('Problem fetching quotes');
          } else {
            //filter for alcohol
            const description = rfq.items.reduce(
              (desc, item) => desc + ' ' + item.description,
              '',
            );
            let filteredQuotes = quotes;
            if (
              description.toLowerCase().includes('alcohol') ||
              description.toLowerCase().includes('scotch') ||
              description.toLowerCase().includes('liquor') ||
              description.toLowerCase().includes('beer') ||
              description.toLowerCase().includes('rum') ||
              description.toLowerCase().includes('whiskey') ||
              description.toLowerCase().includes('vodka') ||
              description.toLowerCase().includes('tequila') ||
              description.toLowerCase().includes('gin') ||
              description.toLowerCase().includes('brandy') ||
              description.toLowerCase().includes('ale') ||
              description.toLowerCase().includes('wine')
            ) {
              filteredQuotes = quotes.filter(
                q =>
                  q.proposal.pricingResult.scac === 'ABFS' ||
                  q.proposal.pricingResult.scac === 'EXLA' ||
                  q.proposal.pricingResult.scac === 'FXFE' ||
                  q.proposal.pricingResult.scac === 'FXNL' ||
                  q.proposal.pricingResult.scac === 'ODFL' ||
                  q.proposal.pricingResult.scac === 'RDFS' ||
                  q.proposal.pricingResult.scac === 'SEFL' ||
                  q.proposal.pricingResult.scac === 'CNWY' ||
                  q.proposal.pricingResult.scac === 'XPOL' ||
                  q.proposal.pricingResult.scac === 'UPGF',
              );
            }
            setQuotes(convertQueryLTLQuote(filteredQuotes));
          }
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      };

      const loadFTL = async (ftlRFQ: FTL_RFQ) => {
        try {
          const accessToken = await getLatestAccessToken();
          const uuid = crypto.randomUUID();
          const gaCid = Cookies.get('_ga');
          setQuoteLogId(uuid);
          const quotes = await requestFTLQuote(
            { ...convertFtlRfqToQuery(ftlRFQ), uuid, gaCid },
            accessToken ? true : false,
          );
          if (!quotes) {
            setError('NetworkError');
          } else {
            setQuotes(convertQueryFTLQuote(quotes));
          }
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      };

      if (rfq) {
        try {
          setLoading(true);
          if (isLtlRfq(rfq)) {
            loadLTL(rfq);
          } else {
            loadFTL(rfq);
          }
        } catch (e) {
          console.error(e);
          setQuotes([]);
          setError('NetworkError');
          setLoading(false);
        }
      }
    }, [rfq]);

    return { loading, quotes, error, errorMessage };
  };

  const useMakeBooking = (): {
    book: (
      bookingRequest: BookingRequest,
      costBreakdown: CostBreakdown,
      paymentInfo: PaymentInfo,
      payingWithCreditInFull?: boolean,
    ) => void;
    loading: boolean;
    errorMessage: string;
    errorCode: ErrorCode;
    confirmation: BookingConfirmation | null;
  } => {
    const [loading, setLoading] = useState(false);
    const [
      confirmation,
      setConfirmation,
    ] = useState<BookingConfirmation | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorCode, setErrorCode] = useState(ErrorCode.BOOK_UNKNOWN_FAILURE);
    const [bookShipment] = useBookShipment();
    const {
      user: { user, organizationName },
      tracking: { trackPurchase },
    } = useContext(GSAppContext);

    const convertErrorMessageToErrorCode = (errorMessage: string) => {
      if (errorMessage) {
        if (
          errorMessage.toUpperCase().includes('PICKUPDATE=LESS_THAN') ||
          errorMessage.toUpperCase().includes('SAMEDAYSHIPPING=LESS_THAN')
        )
          return ErrorCode.BOOK_TOO_LATE;
      }
      return ErrorCode.BOOK_UNKNOWN_FAILURE;
    };

    const book = async (
      bookingRequest: BookingRequest,
      costBreakdown: CostBreakdown,
      paymentInfo: PaymentInfo,
      payingWithCreditInFull = false,
    ) => {
      setLoading(true);
      try {
        const data = convertRawBookingRequest(
          bookingRequest,
          costBreakdown,
          paymentInfo,
          payingWithCreditInFull,
          user,
        );
        const response = await bookShipment(bookingId, data);
        if (!response || response.error || !response.loadId) {
          setErrorMessage(response?.error || '');
          setErrorCode(convertErrorMessageToErrorCode(response?.error || ''));
        } else {
          setConfirmation({
            bookingId: bookingId,
            confirmationNumber: response.loadId,
            costBreakdown,
            quote: bookingRequest.quote,
            loadType: bookingRequest.quote.shipmentType,
          });
          let carrier = 'GoShip Carrier';
          if (bookingRequest.quote.shipmentType === 'LTL') {
            const quote = bookingRequest.quote as LTLQuote;
            carrier = quote.carrier.name;
          }
          trackPurchase(
            response.loadId,
            costBreakdown.finalCost,
            bookingRequest.quote.cost,
            carrier,
            organizationName,
            bookingRequest.bookingDetails.promoCode,
            costBreakdown.promoCodeDiscount,
            bookingRequest.quote.shipmentType,
            bookingId,
          );
          setQuoteLogId('');
        }
      } catch (e) {
        if (e && typeof e === 'object') {
          setErrorMessage(e.toString());
        }
        console.error('useMakeBooking error', e);
        captureException(e);
      } finally {
        setLoading(false);
      }
    };
    return { book, loading, errorMessage, errorCode, confirmation };
  };

  const useRequestPaymentInfo = (reCalculate?: boolean) => {
    const [error, setError] = useState<PrepareBookingError | null>(null);
    const [prepareBooking] = usePrepareBooking();
    const {
      user: { organizationName, userViewer },
      tracking: { trackStartPayment },
    } = useContext(GSAppContext);
    const requestPaymentInfo = async (
      bookingRequest: BookingRequest,
      costBreakdown: CostBreakdown,
    ): Promise<PaymentInfo> => {
      try {
        const response = await prepareBooking(
          bookingRequest as BookingRequest,
          costBreakdown,
          bookingLogId,
        );

        if (!response) {
          setError('NetworkError');
          return {
            id: '',
            token: '',
            status: '',
            amount: costBreakdown.finalCost,
          };
        }
        if (response.error) {
          setError(response.error as PrepareBookingError);
          return {
            id: '',
            token: '',
            status: '',
            amount: costBreakdown.finalCost,
          };
        }
        if (!response.booking) {
          setError('NetworkError');
          return {
            id: '',
            token: '',
            status: '',
            amount: costBreakdown.finalCost,
          };
        }
        setTxnId(response.booking.transactionId);
        setBookingId(response.booking.bookingId);
        let carrier = 'GoShip Carrier';
        if (bookingRequest.quote.shipmentType === 'LTL') {
          const quote = bookingRequest.quote as LTLQuote;
          carrier = quote.carrier.name;
        }
        trackStartPayment(
          costBreakdown.finalCost,
          carrier,
          organizationName,
          bookingRequest.bookingDetails.promoCode,
          costBreakdown.promoCodeDiscount,
          bookingRequest.quote.shipmentType,
          bookingRequest.quote.cost,
          response.booking.bookingId,
          userViewer,
        );
        return {
          id: response.booking.transactionId,
          token: response.booking.transactionToken,
          status: response.booking.transactionStatus,
          amount: costBreakdown.finalCost,
        };
      } catch (e) {
        if (e && typeof e === 'object') {
          setError('NetworkError');
        }
        captureException(e, { extra: bookingRequest });
        return {
          id: '',
          token: '',
          status: '',
          amount: costBreakdown.finalCost,
        };
      }
    };
    useEffect(() => {
      if (isSignedIn()) {
        setError(null);
      } else {
        setError('SignedInError');
      }
    }, [reCalculate]);
    return { requestPaymentInfo, error };
  };

  const createUpchargeTransaction = async ({
    cost,
    upchargeId,
  }: {
    cost: number;
    upchargeId: string;
  }): Promise<PaymentInfo> => {
    const { data } = await createUpchargeTransactionMutation(cost, upchargeId);

    return { ...data };
  };

  const useCalculateBookingCost = (
    bookingRequest: BookingRequest | null,
    credit?: number,
    reCalculate?: boolean,
  ) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<CalculationError | null>(null);
    const [signedInError, setSignedInError] = useState<SignedInError | null>(
      null,
    );
    const [costBreakdown, setPaymentDetails] = useState<CostBreakdown | null>(
      null,
    );
    const [calculateCost] = useCalculateCost();
    useEffect(() => {
      setError(null);
      setPaymentDetails(null);
      if (!bookingRequest) {
        return;
      }
      const costCalculateInput: CostCalculateInput = {
        cost: parseFloat(bookingRequest.quote.cost.toFixed(2)),
        insurance: bookingRequest.bookingDetails.insuredValue > 0,
        commodityValue: bookingRequest.bookingDetails.insuredValue,
        promoCode: !credit ? bookingRequest.bookingDetails.promoCode : null,
        loadType:
          bookingRequest.quote.shipmentType === 'LTL'
            ? Shipment_Type.Ltl
            : Shipment_Type.Ftl,
        creditAmount: credit,
      };

      const resp = async () => {
        setLoading(true);
        const response = await calculateCost(costCalculateInput);

        if (!response || response.error || !response.costBreakdown) {
          setLoading(false);
          //setSignedInError(d.status === 401 ? 'SignedInError' : 'NetworkError');
          const error: CalculationError = {
            insurance: response?.error?.includes('Premium')
              ? response?.error
              : '',
            promo: response?.error?.includes('Promo') ? response?.error : '',
            cost: '',
          };
          setError(error);
          return;
        } else {
          setPaymentDetails({
            ...response.costBreakdown,
            cost: parseFloat(response.costBreakdown.cost.toFixed(2)),
            finalCost: parseFloat(response.costBreakdown.finalCost.toFixed(2)),
            premium: response.costBreakdown.premium || 0,
          });
        }
        setLoading(false);
      };
      if (isSignedIn()) {
        setSignedInError(null);
        resp();
      } else {
        setSignedInError('SignedInError');
      }
    }, [credit, bookingRequest, reCalculate]);
    return {
      loading,
      error,
      signedInError,
      costBreakdown,
    };
  };

  const useFetchBookings = (): {
    error: FetchBookingError | null;
    loading: boolean;
    bookings: Booking[];
  } => {
    const {
      user: { user },
    } = useContext(GSAppContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<FetchBookingError | null>(null);
    useEffect(() => {
      const load = async () => {
        setError(null);
        setLoading(true);
        setBookings([]);
        try {
          const { bookings } = await getLegacyBookings();
          if (!bookings || !bookings.length) {
            setError('NetworkError');
          } else {
            setBookings(bookings.map(convertLegacyBooking));
          }
        } catch (e) {
          setError('NetworkError');
        } finally {
          setLoading(false);
        }
      };

      if (isSignedIn() && user) {
        load();
      } else {
        setError('SignedInError');
        setBookings([]);
      }
    }, [user]);

    return { loading, error, bookings };
  };

  const getDocumentURL = (
    id: string,
    shipmentType: ShipmentType,
    documentType: ShippingDocumentType,
  ) =>
    documentType === 'cert-of-ins'
      ? shipmentType === 'FTL'
        ? `${backend}/pls30Secure/customer/ftl-loads/${id}/download-insurancecert`
        : `${backend}/insurance/certificate/${id}`
      : documentType === 'BOL' && shipmentType === 'FTL'
      ? `${backend}/pls30Secure/customer/ftl-loads/${id}/download`
      : `${backend}/getCustomsDocument/${id}/true/${documentType}`;

  const useDownloadDocument = (): {
    error: DownloadError | null;
    loading: ShippingDocumentType | false;
    downloadDocument: (
      {
        id,
        shipmentType,
      }: {
        id: string;
        shipmentType: ShipmentType;
      },
      documentType: ShippingDocumentType,
    ) => Promise<void>;
  } => {
    const [loading, setLoading] = useState<ShippingDocumentType | false>(false);
    const [error, setError] = useState<DownloadError | null>(null);

    const downloadDocument = useCallback(
      async (
        { id, shipmentType }: { id: string; shipmentType: ShipmentType },
        documentType: ShippingDocumentType,
      ) => {
        if (loading) {
          // not re-entrant!
          return;
        }
        setError(null);
        setLoading(documentType);
        try {
          const accessToken = await getLatestAccessToken();
          const url = getDocumentURL(id, shipmentType, documentType);

          const resp = await fetch(url, {
            mode: 'cors',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const blob = await resp.blob();
          const bolUrl = await new Promise<string>((resolve, reject) => {
            try {
              const reader = new FileReader();
              reader.onloadend = ev =>
                resolve(
                  typeof ev?.target?.result === 'string'
                    ? ev.target.result
                    : '',
                );
              reader.onerror = ev => reject(ev?.target?.error);
              reader.readAsDataURL(blob);
            } catch (e) {
              reject(e);
            }
          });
          download(bolUrl, `${documentType.toLowerCase()}-${id}.pdf`);
        } catch (e) {
          setError('NetworkError');
        } finally {
          setLoading(false);
        }
      },
      [loading],
    );
    return {
      error,
      loading,
      downloadDocument,
    };
  };

  const useGetDocumentUrl = (): {
    error: string | null;
    loading: ShippingDocumentType | false;
    getDocumentUrl: (
      {
        id,
        shipmentType,
      }: {
        id: string;
        shipmentType: ShipmentType;
      },
      documentType: ShippingDocumentType,
    ) => Promise<string | undefined>;
  } => {
    const [loading, setLoading] = useState<ShippingDocumentType | false>(false);
    const [error, setError] = useState<string | null>(null);

    const getDocumentUrl = useCallback(
      async (
        { id, shipmentType }: { id: string; shipmentType: ShipmentType },
        documentType: ShippingDocumentType,
      ) => {
        if (loading) {
          // not re-entrant!
          return;
        }
        setError(null);
        setLoading(documentType);
        try {
          const url = getDocumentURL(id, shipmentType, documentType);
          const accessToken = await getLatestAccessToken();
          const resp = await fetch(url, {
            mode: 'cors',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const blob = await resp.blob();
          const documentUrl = await new Promise<string>((resolve, reject) => {
            try {
              const reader = new FileReader();
              reader.onloadend = ev =>
                resolve(
                  typeof ev?.target?.result === 'string'
                    ? ev.target.result
                    : '',
                );
              reader.onerror = ev => reject(ev?.target?.error);
              reader.readAsDataURL(blob);
            } catch (e) {
              reject(e);
            }
          });
          return documentUrl;
        } catch (e) {
          setError('NetworkError');
        } finally {
          setLoading(false);
        }
      },
      [loading],
    );
    return {
      error,
      loading,
      getDocumentUrl,
    };
  };

  const useGetLoadDetails = ({
    bolId,
    shipmentType,
  }: {
    bolId?: string;
    shipmentType?: ShipmentType;
  }): {
    error: GetLoadDetailsError | null;
    loading: boolean;
    loadDetails: LoadDetails | null;
  } => {
    const [loading, { on: setLoading, off: clearLoading }] = useBoolean(true);
    const [loadDetails, setLoadDetails] = useState<LoadDetails | null>(null);
    const [error, setError] = useState<GetLoadDetailsError | null>(null);

    useEffect(() => {
      const getLoadDetails = async () => {
        const url =
          shipmentType === 'LTL'
            ? `${backend}/getLoadDetails?bolId=${bolId}`
            : `${backend}/pls30Secure/customer/ftl-loads/${bolId}?bolId=${bolId}`;
        const accessToken = await getLatestAccessToken();
        const resp = await fetch(url, {
          mode: 'cors',
          cache: 'no-cache',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: bolId,
        });

        if (!resp.ok) {
          clearLoading();
          setError(resp.status === 401 ? 'SignedInError' : 'NetworkError');
          setLoadDetails(null);
          return;
        }

        try {
          const {
            status,
            payload: rawLoadDetails,
          } = (await resp.json()) as ServerResponse<RawLoadDetails>;

          if (isUndefined(status) || status === -10) {
            setError('NetworkError');
          } else {
            const loadDetails = convertRawLoadDetails(rawLoadDetails);
            const { carriers } = await getCarriers([loadDetails.scacCode]);
            if (carriers?.length) {
              loadDetails.carrierPhoneNumber = carriers?.[0]?.phoneNumber || '';
            }
            setLoadDetails(loadDetails);
          }
        } catch (e) {
          setError('NetworkError');
        } finally {
          clearLoading();
        }
      };

      if (isSignedIn()) {
        setError(null);
        setLoading();
        setLoadDetails(null);
        if (bolId) {
          getLoadDetails();
        } else {
          setLoadDetails(null);
        }
      } else {
        setError('SignedInError');
      }
    }, [bolId, setLoading, clearLoading, shipmentType]);

    return { loading, error, loadDetails };
  };

  /**
   * This is used to fetch either 1) whether or not teh user has unpaid charges
   * or 2) whether they have nagging enabled, and what the nagging limits are.
   * We have not implemented nagging in cuttlefish yet, so we're primarily
   * concerned w/ the former.
   */
  const useGetUpchargeConfig = () => {
    const [loading, { on: setLoading, off: clearLoading }] = useBoolean(true);
    const [upchargeConfig, setUpchargeConfig] = useState<UpchargeConfig | null>(
      null,
    );
    const [error, setError] = useState<SignedInError | null>(null);
    const url = `${backend}/upCharge/getUpchargeConfig?loadId=-1`;

    useEffect(() => {
      const getUpchargeConfig = async () => {
        setLoading();
        const accessToken = await getLatestAccessToken();

        const resp = await fetch(url, {
          mode: 'cors',
          cache: 'no-cache',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!resp.ok) {
          clearLoading();
          setError(resp.status === 401 ? 'SignedInError' : 'NetworkError');
          setUpchargeConfig(null);
          return;
        }

        try {
          const {
            status,
            payload,
          } = (await resp.json()) as ServerResponse<UpchargeConfig>;

          if (isUndefined(status) || status === -10) {
            setError('NetworkError');
          } else {
            setUpchargeConfig(payload);
          }
        } catch (e) {
          setError('NetworkError');
        } finally {
          clearLoading();
        }
      };

      if (isSignedIn()) {
        setError(null);
        setUpchargeConfig(null);
        getUpchargeConfig();
      } else {
        setError('SignedInError');
      }
    }, [clearLoading, setLoading, url]);

    return {
      error,
      loading,
      upchargeConfig,
    };
  };

  const useGetUpchargeDetails = () => {
    const [loading, { on: setLoading, off: clearLoading }] = useBoolean(true);
    const [upchargeDetails, setUpchargeDetails] = useState<
      Array<UpchargeDetails>
    >([]);
    const [error, setError] = useState<SignedInError | null>(null);
    const url = `${backend}/upCharge/upChargeInfo?loadId=-1`;

    useEffect(() => {
      const getUpchargeDetails = async () => {
        setLoading();
        const accessToken = await getLatestAccessToken();
        const resp = await fetch(url, {
          mode: 'cors',
          cache: 'no-cache',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!resp.ok) {
          clearLoading();
          setError(resp.status === 401 ? 'SignedInError' : 'NetworkError');
          setUpchargeDetails([]);
          return;
        }

        try {
          const { status, payload } = (await resp.json()) as ServerResponse<
            Array<RawUpchargeDetails>
          >;

          if (isUndefined(status) || status === -10) {
            setError('NetworkError');
          } else {
            setUpchargeDetails(
              payload.map(upcharge => convertRawUpchargeDetails(upcharge)),
            );
          }
        } catch (e) {
          setError('NetworkError');
        } finally {
          clearLoading();
        }
      };

      if (isSignedIn()) {
        setError(null);
        setUpchargeDetails([]);
        getUpchargeDetails();
      } else {
        setError('SignedInError');
      }
    }, [clearLoading, setLoading, url]);

    return {
      error,
      loading,
      upchargeDetails,
    };
  };

  const markUpchargePaid = async (
    upChargeTransactionId: string | null,
    upchargeDetails: Array<RawUpchargeDetails>,
  ): Promise<
    ServerResponse<{
      paidAmount: number;
      transactionId: string;
      remainingCharges: Array<RawUpchargeDetails>;
    }>
  > => {
    const accessToken = await getLatestAccessToken();
    const url = `${backend}/purchase/markUpchargePaid`;
    const resp = await fetch(url, {
      mode: 'cors',
      cache: 'no-cache',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        upChargeTransactionId,
        data: upchargeDetails,
      }),
    });

    if (!resp.ok) {
      throw new Error(`An error occurred ${resp.status} ${resp.statusText}`);
    }

    return await resp.json();
  };

  const useGetTerminalInfo = (bolId: string) => {
    const [loading, { on: setLoading, off: clearLoading }] = useBoolean(true);
    const [terminalInfo, setTerminalInfo] = useState<TerminalInfo | null>(null);
    const [error, setError] = useState<SignedInError | null>(null);
    const url = `${backend}/terminalInfo?shipmentId=${bolId}`;

    useEffect(() => {
      const getTerminalInfo = async () => {
        setLoading();

        const accessToken = await getLatestAccessToken();
        const resp = await fetch(url, {
          mode: 'cors',
          cache: 'no-cache',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!resp.ok) {
          clearLoading();
          setError(resp.status === 401 ? 'SignedInError' : 'NetworkError');
          setTerminalInfo(null);
          return;
        }

        try {
          const {
            payload,
          }: ServerResponse<RawTerminalInfo> = await resp.json();
          setTerminalInfo(convertRawTerminalInfo(payload));
        } catch (e) {
          setError('NetworkError');
        } finally {
          clearLoading();
        }
      };

      if (isSignedIn()) {
        setError(null);
        setTerminalInfo(null);
        getTerminalInfo();
      } else {
        setError('SignedInError');
      }
    }, [clearLoading, setLoading, url]);

    return {
      error,
      loading,
      terminalInfo,
    };
  };

  const useUpdateBookingLog = (): {
    updateBookingL: (
      rfq: RFQ,
      quotes?: Quote[],
      selectedQuote?: Quote,
      logStatus?: Log_Status,
    ) => void;
  } => {
    const [updateBookingLog] = useUpdateBookingLogGQL();
    const [saveBookingLog] = useSaveBookingLogGQL();

    const updateBookingL = async (
      rfq: RFQ,
      quotes?: Quote[],
      selectedQuote?: Quote,
      logStatus?: Log_Status,
    ) => {
      try {
        let response;
        if (!bookingLogId.length) {
          response = await saveBookingLog({
            originZip: rfq.origin.address.postalCode,
            destinationZip: rfq.destination.address.postalCode,
            amount: selectedQuote?.cost ?? 10,
            pickupDate: rfq.origin.date.toISOString().split('T')[0],
            rfq,
            selectedQuote,
            quotes,
            quoteLogUuid: quoteLogId,
          });
        } else {
          response = await updateBookingLog({
            id: bookingLogId,
            amount: selectedQuote?.cost,
            rfq,
            selectedQuote,
            quotes,
            logStatus,
            quoteLogUuid: quoteLogId,
          });
        }

        if (response && !response.error) {
          setBookingLogId(response.bookingLog?.id);
        }
      } catch (e) {
        captureException(e);
      }
    };
    return { updateBookingL };
  };

  const useSaveBookingLog = (): {
    saveBookingL: (rfq: RFQ) => void;
  } => {
    const [saveBookingLog] = useSaveBookingLogGQL();
    const saveBookingL = async (rfq: RFQ) => {
      try {
        const response = await saveBookingLog({
          originZip: rfq.origin.address.postalCode,
          destinationZip: rfq.destination.address.postalCode,
          amount: 10,
          pickupDate: rfq.origin.date.toISOString().split('T')[0],
          rfq,
          quoteLogUuid: quoteLogId,
        });

        if (response && !response.error) {
          setBookingLogId(response.bookingLog?.id);
        }
      } catch (e) {
        captureException(e);
      }
    };
    return { saveBookingL };
  };

  const brokerContext: BrokerContext = {
    useRequestQuotes,
    useMakeBooking,
    requestPaymentInfo: useRequestPaymentInfo,
    createUpchargeTransaction,
    useFetchBookings,
    useAddressAutocomplete: useCityStateSearch,
    useDownloadDocument,
    useCalculateBookingCost,
    useCheckout: useBalanceCheckout,
    useGetLoadDetails,
    useGetUpchargeConfig,
    useGetUpchargeDetails,
    markUpchargePaid,
    useGetTerminalInfo,
    useUpdateBookingLog,
    useSaveBookingLog,
    txnId,
    bookings,
    bookingId,
    useGetDocumentUrl,
  };

  return brokerContext;
};

export default useBackendBrokerContext;
