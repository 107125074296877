import { Formik } from 'formik';
import { FC, useContext } from 'react';

import Button from '@/components/Button';
import { GSAppContext } from '@/components/context/GSAppContext';
import TOSCallout from '@/components/TOSCallout';
import { SigninSchema } from '@/utils/validations';

import ValidatedField from './ValidatedField';
export const UnknownError = 'We had an unknown problem';

const SignInForm: FC<{ onComplete: () => void; onForgot: () => void }> = ({
  onComplete,
  onForgot,
}) => {
  const {
    user: { loading, signIn },
  } = useContext(GSAppContext);
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={async ({ email, password }, { setErrors }) => {
        const error = await signIn(email, password);
        if (error) {
          setErrors({
            password:
              error === 'UNAUTHORIZED'
                ? 'This email/password combination not found'
                : error === 'INACTIVE'
                ? 'The user is inactive. Please contact Support at customer_service@goship.com'
                : UnknownError,
          });
        } else {
          onComplete();
        }
      }}
      validationSchema={SigninSchema}>
      {({ isValid, dirty, submitForm }) => (
        <>
          <div className="flex pt-3 w-full justify-center font-bold text-2xl mb-4">
            Sign in
          </div>

          <div className="mb-3 w-full">
            <ValidatedField
              data-cy="sign-in-email-input"
              name="email"
              type="email"
              placeholder="user@example.org"
            />
          </div>
          <div className="mb-3 w-full">
            <ValidatedField
              data-cy="sign-in-password-input"
              name="password"
              type="password"
              placeholder="******"
            />
          </div>

          <TOSCallout />
          <div className="flex pt-3 w-full justify-center">
            <Button
              data-cy="sign-in-login-button"
              isLoading={loading}
              disabled={!dirty || !isValid}
              onClick={submitForm}
              variant="solid">
              <span className="text-xl">LOGIN</span>
            </Button>
          </div>
          <div className="flex pt-3 w-full justify-center">
            <Button
              size="sm"
              onClick={onForgot}
              variant="link"
              className="mb-2">
              Forgot Password
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
};

export default SignInForm;
