import { useToast } from '@chakra-ui/react';
import { useCallback, useContext } from 'react';

import { GSAppContext } from '@/components/context/GSAppContext';

const useSignOut = () => {
  const {
    user: { user, signOut },
  } = useContext(GSAppContext);
  const toast = useToast();

  const performSignOut = useCallback(() => {
    if (user) {
      signOut();
      toast({
        title: 'You have been signed out',
        description: 'Come back soon!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    }
  }, [signOut, toast, user]);
  return performSignOut;
};
export default useSignOut;
