import { RawProposal, RawUpchargeDetails } from '@/backend/BackendTypes';

import { Truck_Type } from '../../publicTypes.d';

export type DistanceUoM = 'in' | 'cm';
export type WeightUoM = 'kg' | 'lbs';

export type FreightClass =
  | 'CLASS_50'
  | 'CLASS_55'
  | 'CLASS_60'
  | 'CLASS_65'
  | 'CLASS_70'
  | 'CLASS_77_5'
  | 'CLASS_85'
  | 'CLASS_92_5'
  | 'CLASS_100'
  | 'CLASS_110'
  | 'CLASS_125'
  | 'CLASS_150'
  | 'CLASS_175'
  | 'CLASS_200'
  | 'CLASS_250'
  | 'CLASS_300'
  | 'CLASS_400'
  | 'CLASS_500';

export type AddressType =
  | 'RESIDENTIAL'
  | 'BUSINESS_WOUT_DOCK'
  | 'BUSINESS_W_DOCK'
  | 'FARM'
  | 'SELF_STORAGE'
  | 'CONSTRUCTION'
  | 'SCHOOL'
  | 'GOVT'
  | 'RELIGIOUS'
  | 'HOTEL'
  | 'MILITARY'
  | 'AIRPORT'
  | 'TRADE_SHOW'
  | 'GROCERY'
  | 'LIMITED_ACCESS';

export type Country = 'USA' | 'CAN';

export type LoadStatus =
  | 'dispatched'
  | 'picked_up'
  | 'in_transit'
  | 'delivered'
  | 'canceled'
  | 'unknown';

export type AddressSummary = {
  city: string;
  state: string;
};

export type ZipAddress = {
  postalCode: string;
  city: string;
  state: string;
  country: Country;
  lat: number;
  lng: number;
};

export type TimeAddress = ZipAddress & {
  timeOffset: number; // Offset from GMT
  streetAddress?: string;
  streetAddress1?: string;
};

export type EndPointSummary = {
  date: Date;
  address: AddressSummary;
};

// All the accessorials that are common to the whole booking
export type BasicCommonAccessorials = {
  chains?: boolean;
  coilRacks?: boolean;
  edgeProtectors?: boolean;
  loadBars?: boolean;
  loadLocks?: boolean;
  ppe?: boolean;
  straps?: boolean;
  tarps?: boolean;
  noFreeze?: boolean;
};

// All the accessorials specific to the pickup or delivery
export type EndPointAccessorials = {
  driverAssist?: boolean;
  liftgate?: boolean;
  inside?: boolean;
  appointment?: boolean;
  noFreeze?: boolean;
};

// Temperature is a weird Accessorials — it’s not a bool, only applies to Reefers, etc.
export type CommonAccessorials = BasicCommonAccessorials & {
  temperature?: number;
};

export type EndPoint = EndPointSummary & {
  address: TimeAddress;
  type: AddressType;
  accessorials: EndPointAccessorials;
  carrierAddedAccessorials?: string[];
};

export type ItemCondition = 'NEW' | 'USED';

export type HazMatClass =
  | '1'
  | '1.1'
  | '1.2'
  | '1.3'
  | '1.4'
  | '1.5'
  | '1.6'
  | '2'
  | '2.1'
  | '2.2'
  | '2.3'
  | '3'
  | '4'
  | '4.1'
  | '4.2'
  | '4.3'
  | '5'
  | '5.1'
  | '5.2'
  | '6'
  | '6.1'
  | '7'
  | '8'
  | '9';

export type HazMatDetails = {
  reportableQty: boolean;
  unNumber: string;
  packGroupNumber: 'I' | 'II' | 'III' | 'N/A' | '';
  emergencyContactCompany?: string;
  emergencyContactPhone?: string;
  hazmatClass: HazMatClass | '';
  contractNumber: string;
  instructions?: string;
};

export type HazardousItem = Item & {
  hazardous: true;
  hazmatDetails?: HazMatDetails;
};

export type Item = {
  quantity: number | undefined;
  packaging: string;
  sizeUoM: DistanceUoM;
  length: number | undefined;
  width: number | undefined;
  height: number | undefined;
  weightUoM: WeightUoM;
  weight: number | undefined;
  freightClass: FreightClass;
  value: number | undefined;
  itemCondition: ItemCondition;
  stackable: boolean;
  hazardous: boolean;
  hazmatDetails?: HazMatDetails;
  description: string;
  tariff: string;
  countryOfOrigin: string;
  dimWeightLbs?: number | undefined;
  acceptOverdimension?: boolean | undefined;
  acceptOverdimensionDate?: Date | undefined;
};

export type DraftItem = Partial<Item>;

export type ShipmentType = 'LTL' | 'FTL';

type BaseRFQ = {
  shipmentType: ShipmentType;
  origin: EndPoint;
  destination: EndPoint;
  items: Array<HazardousItem | Item>;
  commonAccessorials: CommonAccessorials;
};

export type LTL_RFQ = BaseRFQ & {
  shipmentType: 'LTL';
  items: Array<HazardousItem | Item>;
  showLandingPrompt?: boolean;
};

export type TruckType = 'reefer' | 'van' | 'flatbed';

export type FTL_RFQ = BaseRFQ & {
  shipmentType: 'FTL';
  truckType: TruckType;
};

export type RFQ = FTL_RFQ | LTL_RFQ;

export function isLtlRfq(rfq: RFQ): rfq is LTL_RFQ {
  return rfq.shipmentType === 'LTL';
}

export function isLtlQuote(quote?: Quote): quote is LTLQuote {
  return quote?.shipmentType === 'LTL';
}

export function isDraftComplete(rfq?: RFQ): rfq is RFQ {
  if (!rfq) return false;
  const { shipmentType, origin, destination, items } = rfq;
  return !!(
    shipmentType &&
    origin &&
    destination &&
    (shipmentType === 'FTL' || (items?.length && items[0]?.quantity))
  );
}

export type CarrierSummary = {
  name: string;
};

export type Carrier = CarrierSummary & {
  newLiability: number;
  usedLiability: number;
  scac?: string;
  reliabilityScore?: number;
};

type BaseQuote = {
  guid: string;
  transitTime: number;
  deliveryDate: Date;
  cost: number;
};

export type FTLQuote = BaseQuote & {
  truckType: Truck_Type;
  baseRate: number;
  pickupDate: Date;
  shipmentType: ShipmentType;
  ftlAccessorialCharges?: AccessorialCharge[] | null;
};

export type LTLQuote = BaseQuote & {
  shipmentType: 'LTL';
  carrier: Carrier;
  baseRate: number;
  proposal: RawProposal | undefined;
  ltlAccessorialCharges?: AccessorialCharge[] | null;
};

export type Quote = FTLQuote | LTLQuote;

export type AccessorialCharge = {
  code: string;
  description: string;
  charge: number;
};

export type AuthUser = {
  fullName: string;
  personId: number;
};

export type User = {
  personId: string;
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  password: string;
  phoneNumber: string;
};

export type DraftUser = Omit<User, 'personId'>;

export type FullAddress = Address & Contact;

export type Contact = {
  contactId?: string;
  firstName: string;
  lastName: string;
  companyName: string;
  phone: string;
  email: string;
};

export type Address = {
  addressId?: string;
  streetAddress: string;
  streetAddress1: string | null;
} & TimeAddress;

export type FullEndPoint = EndPoint & {
  address: FullAddress;
  instructions: string;
  startTime: Date;
  endTime: Date;
  saveToAddressBook: boolean;
};

export type PaymentInfo = {
  token: string;
  id: string;
  status: string;
  amount: number;
};

export enum InsuranceValue {
  Unset = -1,
  Uncalculated = -2,
}

export type BookingDetails = {
  insuredValue: number;
  promoCode: string;
  acknowledgeShipmentAccuracy: boolean;
};

type CostBreakdownSummary = {
  finalCost: number;
};

export type CostBreakdown = CostBreakdownSummary & {
  cost: number;
  premium: number;
  promoCodeDiscount: number;
  creditAmount: number;
};

export type PaymentInfoRequest = {
  email: string;
  amount: number;
  title: string;
};

export type TermsOfSale =
  | 'NET 30'
  | 'COLLECT'
  | 'CASH'
  | 'PAYMENT IN ADVANCE'
  | 'OTHER'
  | 'LESS PERCENT DISCOUNT';

export type BrokerAddress = FullAddress & {
  usePlsBroker: boolean;
  brokerAuthorized: boolean;
};
export type CustomsAddress = FullAddress & {
  usePickup: boolean;
  useDelivery: boolean;
};

export type Customs = {
  broker: BrokerAddress;
  termsOfSale: TermsOfSale | '';
  vendor: CustomsAddress;
  purchaser: CustomsAddress;
  createCustomsInvoice: boolean;
};

export type BookingRequest = {
  origin: FullEndPoint;
  destination: FullEndPoint;
  billing: FullEndPoint;
  items: Item[];
  quote: Quote;
  commonAccessorials: CommonAccessorials;
  bookingDetails: BookingDetails;
  customs: Customs;
};

export type Booking = {
  shipmentType: ShipmentType;
  id: string;
  origin: EndPointSummary;
  destination: EndPointSummary;
  carrier?: CarrierSummary;
  costBreakdown?: CostBreakdownSummary;
  loadStatus: string;
  trackingNumber?: string;
  txnId?: string;
  scacCode: string;
  carrierPhoneNumber?: string;
  url?: string;
};

export type BookingConfirmation = {
  bookingId: string;
  confirmationNumber: string;
  costBreakdown: CostBreakdown;
  quote: Quote;
  loadType: ShipmentType;
};

type LoadSummary = {
  id: string;
  orderDate: string;
  status?: LoadStatus;
  notes: string;
  totalPaid: number;
  totalValue: number;
  totalWeight: number;
  transCountry: boolean;
  insurancePolicy: string;
  insuredSum: number;
  insurancePremium: number;
};

type LoadEndpoint = {
  date: string;
  startTime: Date;
  endTime: Date;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  fullAddress: string;
  country: Country;
};

export type LoadDetails = {
  bolId: string;
  load: LoadSummary;
  carrier: CarrierSummary;
  origin: LoadEndpoint;
  destination: LoadEndpoint;
  txnId: string;
  scacCode: string;
  carrierPhoneNumber?: string;
};

export type NetworkError = 'NetworkError';
export type PreconditionError = 'PreconditionError';

// an error from any function that requires the user to be logged in.
export type SignedInError = NetworkError | 'SignedInError';

export type PrepareBookingError =
  | 'CREATE_TRANSACTION_ERROR'
  | 'ERROR_CREATING_BOOKING'
  | 'PROMO_CODE_ERROR'
  | 'PROMO_CODE_EXPIRED'
  | 'LEGACY_SERVICE_NOT_RESPONDING'
  | 'LEGACY_SERVICE_ACTIVEMQ_NOT_RESPONDING'
  | 'LEGACY_SERVICE_DB_NOT_RESPONDING'
  | 'LEGACY_SERVICE_FALVEY_NOT_RESPONDING'
  | 'LEGACY_SERVICE_PLS_SERVICE_NOT_RESPONDING'
  | SignedInError;

export type UpchargeConfig = {
  canNagUser: boolean;
  doesUserHaveCharges: boolean;
  isNaggingEnabledApp: boolean;
  numTimesToNagUserGlobal: number;
  numTimesToNagUserSession: number;
  numTimesUserNaggedGlobal: number;
  numTimesUserNaggedSession: number;
};

export type UpchargeDetails = {
  bolId: string;
  shipDate: string;
  deliveryDate: string;
  reason: string;
  originalCost: number;
  updatedCost: number;
  balanceDue: number;
  raw: RawUpchargeDetails;
};

export type TerminalInfo = {
  origin: FullAddress;
  destination: FullAddress;
  mileageBetween: number;
  mileageToOrigin: number;
  mileageFromDest: number;
};

export type BookingLog = {
  id?: string;
  amount?: number;
  originZip?: string;
  destinationZip?: string;
  pickupDate?: Date | string;
  logStatus?: string;
  rfq?: RFQ;
  quotes?: Quote[];
  selectedQuote?: Quote;
  bolId?: string;
  quoteLogUuid?: string;
};
