import { gql } from '@apollo/client';

import graphqlClient from '@/backend/graphql';
import { Query } from 'types';

const GET_USER = gql`
  query GetUserQuery {
    viewer {
      id
      featureFlags
      vendor
      organizationName
      fullName
      email
      phoneNumber
      insuranceDisabled
    }
  }
`;

export default async function getUser() {
  const resp = await graphqlClient.query<Query>({
    query: GET_USER,
  });

  const user = resp?.data?.viewer;
  return { user };
}
