import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { getLatestAccessToken } from '../Tokens';

const NEXT_PUBLIC_NAUTILUS_GRAPHQL_ENDPOINT =
  process.env.NEXT_PUBLIC_NAUTILUS_GRAPHQL_ENDPOINT;
const NEXT_PUBLIC_NAUTILUS_PUBLIC_GRAPHQL_ENDPOINT =
  process.env.NEXT_PUBLIC_NAUTILUS_PUBLIC_GRAPHQL_ENDPOINT;

const httpLink = createHttpLink({
  uri: NEXT_PUBLIC_NAUTILUS_GRAPHQL_ENDPOINT,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getLatestAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export default new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const publicClient = new ApolloClient({
  uri: NEXT_PUBLIC_NAUTILUS_PUBLIC_GRAPHQL_ENDPOINT,
  cache: new InMemoryCache(),
});
