/* eslint-disable react/jsx-no-target-blank */
import { Modal, ModalOverlay, ModalContent, useToast } from '@chakra-ui/react';
import { Formik } from 'formik';
import { FC, useContext } from 'react';

import Button from '@/components/Button';
import { GSAppContext } from '@/components/context/GSAppContext';
import SignUpForm from '@/components/SignUpForm';
import { UserContext } from '@/utils/types/UserContext';
import { ForgotPasswordSchema } from '@/utils/validations';

import SignInForm, { UnknownError } from './SignInForm';
import ValidatedField from './ValidatedField';

type SuccessFunction = (success: boolean) => void;

type PanelProps = {
  userContext: UserContext;
  closeModal: SuccessFunction;
  toggle?: () => void;
};

export const SignUpPanel: FC<PanelProps> = ({ closeModal, toggle }) => (
  <div className="md:pb-4">
    <div className="flex flex-row w-full justify-center mt-2">
      <div className="italic pr-2">Already have an account?</div>
      <Button
        variant="link"
        data-cy="sign-in-button"
        onClick={toggle}
        className="underline focus:outline-none"
        style={{ boxShadow: 'none' }}>
        Sign In
      </Button>
    </div>
    <SignUpForm onComplete={() => closeModal(true)} />
    <div className="border-t-2 items-center flex flex-col pt-3 w-full justify-center mb-12 md:mb-0">
      <div className="italic pb-2">Already have an account?</div>
      <Button data-cy="sign-in-button" onClick={toggle} variant="outline">
        Sign In
      </Button>
    </div>
  </div>
);

const SignInPanel: FC<{
  closeModal: SuccessFunction;
  onForgot: () => void;
  toggle: () => void;
}> = ({ toggle, closeModal, onForgot, ...props }) => (
  <div className="flex flex-col items-center mx-3 my-4 space-between">
    <SignInForm
      onForgot={onForgot}
      onComplete={() => closeModal(true)}
      {...props}
    />
    <div className="mt-4 border-t-2 flex pt-3 w-full justify-center">
      <Button onClick={toggle} variant="outline">
        Sign up
      </Button>
    </div>
  </div>
);

const ForgotPasswordModal: FC<PanelProps> = ({
  toggle,
  closeModal,
  userContext: { useResetPassword },
}) => {
  const { loading, reset, resetPassword } = useResetPassword();
  return (
    <div className="flex flex-col items-center mx-3 my-4 space-between">
      {reset ? (
        <>
          <div className="font-bold text-2xl mb-4">Reset Password</div>
          <div className="italic text-sm">
            Reset password link has been sent to your email.
          </div>
          <Button
            onClick={() => closeModal(false)}
            variant="solid"
            className="mt-4 mb-2">
            Done
          </Button>
        </>
      ) : (
        <Formik
          initialValues={{ email: '' }}
          onSubmit={async ({ email }, { setErrors }) => {
            const error = await resetPassword(email);
            if (error) {
              setErrors({
                email:
                  error === 'NotFound'
                    ? 'That email you provided is not in our system'
                    : UnknownError,
              });
            }
          }}
          validationSchema={ForgotPasswordSchema}>
          {({ isValid, dirty, submitForm }) => (
            <>
              <div className="font-bold text-2xl mb-4">Reset Password</div>

              <div className="mb-1 w-full">
                <ValidatedField
                  name="email"
                  type="email"
                  placeholder="user@example.org"
                />
              </div>
              <div className="mb-3 italic text-sm">
                A temporary link will be sent to your email.
              </div>
              <Button
                isLoading={loading}
                disabled={!dirty || !isValid}
                onClick={submitForm}
                variant="solid">
                <span className="text-xl">RESET PASSWORD</span>
              </Button>
              <Button onClick={toggle} variant="link" className="mt-4 mb-2">
                Cancel
              </Button>
            </>
          )}
        </Formik>
      )}
    </div>
  );
};

const CloseToastTitles = {
  signin: 'Welcome!',
  signup: 'Welcome!',
  forgot: 'Success!',
};

const CloseToastMessages = {
  signin: 'You have been signed in.',
  signup: 'Your account has been created.',
  forgot: 'An email has been sent to you with further instructions.',
};

export default function SignInModal() {
  const {
    user,
    signIn: { signInMode, openSignInModal, closeSignInModal },
  } = useContext(GSAppContext);
  const toast = useToast();

  const handleClose = (success = false) => {
    if (success) {
      toast({
        // @ts-expect-error TODO Couldn't get to some of these in
        // https://plslogistics.atlassian.net/browse/GS-736
        title: CloseToastTitles[signInMode],
        // @ts-expect-error TODO Couldn't get to some of these in
        // https://plslogistics.atlassian.net/browse/GS-736
        description: CloseToastMessages[signInMode],
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    }
    closeSignInModal(success);
  };

  return (
    <Modal isOpen={!!signInMode} onClose={() => handleClose(false)}>
      <ModalOverlay />
      <ModalContent className="px-4">
        {signInMode === 'signin' ? (
          <SignInPanel
            closeModal={handleClose}
            toggle={() => openSignInModal('signup')}
            onForgot={() => openSignInModal('forgot')}
          />
        ) : signInMode === 'signup' ? (
          <SignUpPanel
            userContext={user}
            closeModal={handleClose}
            toggle={() => openSignInModal('signin')}
          />
        ) : (
          <ForgotPasswordModal
            userContext={user}
            closeModal={handleClose}
            toggle={() => openSignInModal('signin')}
          />
        )}
      </ModalContent>
    </Modal>
  );
}
