import dayjs from 'dayjs';

import { BlankCustoms, BlankHazMatDetails } from '@/utils/BlankObject';
import {
  BookingRequest,
  LTL_RFQ,
  FTL_RFQ,
  InsuranceValue,
  Item,
  FullEndPoint,
} from '@/utils/types';

import { Truck_Type } from '../../../publicTypes.d';

const Origin: FullEndPoint = {
  address: {
    companyName: 'ACME Inc.',
    streetAddress: '3120 Unionville Rd',
    streetAddress1: '',
    postalCode: '16066',
    city: 'Cranberry Township',
    state: 'PA',
    country: 'USA',
    timeOffset: -5,
    firstName: 'John',
    lastName: 'Doe',
    phone: '+1(415)-555-1212',
    email: 'john@doe.com',
    lat: 40.7164495,
    lng: -80.1205256,
  },
  type: 'BUSINESS_W_DOCK',
  accessorials: { liftgate: true, inside: true, appointment: false },
  date: dayjs().add(8, 'day').toDate(),
  instructions: '',
  saveToAddressBook: false,
  startTime: dayjs()
    .add(7, 'day')
    .hour(8)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toDate(),
  endTime: dayjs()
    .add(7, 'day')
    .hour(16)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toDate(),
};

const USDestination: FullEndPoint = {
  address: {
    companyName: 'PLS Inc.',
    streetAddress: '3120 Unionville Rd',
    streetAddress1: '',
    postalCode: '16066',
    city: 'Cranberry Township',
    state: 'PA',
    country: 'USA',
    timeOffset: -5,
    firstName: 'John',
    lastName: 'Doe',
    phone: '+1(415)-555-1212',
    email: 'john@doe.com',
    lat: 40.7164495,
    lng: -80.1205256,
  },
  type: 'BUSINESS_WOUT_DOCK',
  accessorials: { liftgate: true, inside: true },
  date: dayjs().add(14, 'day').toDate(),
  instructions: '',
  saveToAddressBook: false,
  startTime: dayjs()
    .add(14, 'day')
    .hour(8)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toDate(),
  endTime: dayjs()
    .add(14, 'day')
    .hour(16)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toDate(),
} as const;

const CanadaDestination: FullEndPoint = {
  address: {
    companyName: 'ACME Inc.',
    streetAddress: '3120 Unionville Rd',
    streetAddress1: '',
    postalCode: 'K1A 0A9',
    city: 'Ottawa',
    state: 'ON',
    country: 'CAN',
    timeOffset: -8,
    firstName: 'John',
    lastName: 'Doe',
    phone: '+1(415)-555-1212',
    email: 'john@doe.com',
    lat: 45.4247204,
    lng: -75.7003546,
  },
  type: 'BUSINESS_WOUT_DOCK',
  accessorials: { liftgate: true, inside: true },
  date: dayjs().add(14, 'day').toDate(),
  instructions: '',
  saveToAddressBook: false,
  startTime: dayjs()
    .add(14, 'day')
    .hour(8)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toDate(),
  endTime: dayjs()
    .add(14, 'day')
    .hour(16)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toDate(),
} as const;

const RequestAddresses = {
  origin: Origin,
  destination: CanadaDestination,
};

const DomesticRequestAddresses = {
  origin: Origin,
  destination: USDestination,
};

const RequestItems: Item[] = [
  {
    quantity: 5,
    packaging: 'PLT',
    sizeUoM: 'in',
    length: 24,
    width: 24,
    height: 24,
    weightUoM: 'lbs',
    weight: 500,
    freightClass: 'CLASS_50',
    value: 2555,
    itemCondition: 'NEW',
    stackable: false,
    hazardous: false,
    description: 'Various Items',
    tariff: '',
    countryOfOrigin: '',
    hazmatDetails: BlankHazMatDetails,
  },
];

export const LTL_RFQ_FIXTURE: LTL_RFQ = {
  shipmentType: 'LTL',
  ...RequestAddresses,
  commonAccessorials: { noFreeze: true },
  items: RequestItems,
};

export const DOMESTIC_LTL_RFQ_FIXTURE: LTL_RFQ = {
  shipmentType: 'LTL',
  ...DomesticRequestAddresses,
  commonAccessorials: { noFreeze: true },
  items: RequestItems,
};

export const FTL_FLATBED_RFQ_FIXTURE: FTL_RFQ = {
  shipmentType: 'FTL',
  ...DomesticRequestAddresses,
  commonAccessorials: { straps: true, tarps: true },
  truckType: 'flatbed',
  items: RequestItems,
};

export const FTL_VAN_RFQ_FIXTURE: FTL_RFQ = {
  shipmentType: 'FTL',
  ...DomesticRequestAddresses,
  commonAccessorials: { straps: true },
  truckType: 'van',
  items: RequestItems,
};

export const FTL_REEFER_RFQ_FIXTURE: FTL_RFQ = {
  shipmentType: 'FTL',
  ...DomesticRequestAddresses,
  commonAccessorials: { straps: true, temperature: 32 },
  truckType: 'reefer',
  items: RequestItems,
};

const BaseLTLRequestFixture = {
  quote: {
    shipmentType: 'LTL',
    guid: '9ce0bcc5-3106-478d-91b1-0377d1d77bca',
    transitTime: 6,
    deliveryDate: dayjs().add(7, 'day').toDate(),
    cost: 166.447,
    carrier: {
      id: 15662,
      name: 'ABF FREIGHT SYSTEM',
      newLiability: 2220,
      usedLiability: 11.1,
      reliabilityScore: 99.0,
    },
    proposal: undefined,
    baseRate: 166.23,
    ltlAccessorialCharges: undefined,
  },
  bookingDetails: {
    insuredValue: InsuranceValue.Unset,
    promoCode: '',
    acknowledgeShipmentAccuracy: true,
  },
  items: RequestItems,
  commonAccessorials: {},
  customs: BlankCustoms,
} as const;

export const LTL_REQUEST_FIXTURE: BookingRequest = {
  ...RequestAddresses,
  billing: Origin,
  ...BaseLTLRequestFixture,
};

export const DOMESTIC_LTL_REQUEST_FIXTURE: BookingRequest = {
  ...RequestAddresses,
  ...DomesticRequestAddresses,
  ...BaseLTLRequestFixture,
  billing: Origin,
};

export const FTL_VAN_REQUEST_FIXTURE: BookingRequest = {
  ...DomesticRequestAddresses,
  billing: Origin,
  quote: {
    shipmentType: 'FTL',
    guid: '9ce0bcc5-3106-478d-91b1-0377d1d77bca',
    transitTime: 6,
    deliveryDate: dayjs().add(7, 'day').toDate(),
    pickupDate: dayjs().add(3, 'day').toDate(),
    cost: 166.44,
    truckType: Truck_Type.Van,
    baseRate: 166.23,
    ftlAccessorialCharges: [],
  },
  bookingDetails: {
    insuredValue: InsuranceValue.Unset,
    promoCode: '',
    acknowledgeShipmentAccuracy: false,
  },
  items: [
    {
      quantity: 5,
      packaging: 'PLT',
      sizeUoM: 'in',
      length: 24,
      width: 24,
      height: 24,
      weightUoM: 'lbs',
      weight: 500,
      freightClass: 'CLASS_50',
      value: 2555,
      itemCondition: 'NEW',
      stackable: false,
      hazardous: false,
      hazmatDetails: BlankHazMatDetails,
      description: 'various items',
      tariff: '',
      countryOfOrigin: '',
    },
  ],
  commonAccessorials: { straps: true },
  customs: BlankCustoms,
};

export const FTL_FLATBED_REQUEST_FIXTURE: BookingRequest = {
  ...DomesticRequestAddresses,
  billing: Origin,
  quote: {
    shipmentType: 'FTL',
    guid: '9ce0bcc5-3106-478d-91b1-0377d1d77bca',
    transitTime: 6,
    deliveryDate: dayjs().add(7, 'day').toDate(),
    pickupDate: dayjs().add(3, 'day').toDate(),
    cost: 166.44,
    truckType: Truck_Type.Flatbed,
    baseRate: 166.23,
    ftlAccessorialCharges: [],
  },
  bookingDetails: {
    insuredValue: InsuranceValue.Unset,
    promoCode: '',
    acknowledgeShipmentAccuracy: false,
  },
  items: [
    {
      quantity: 5,
      packaging: 'PLT',
      sizeUoM: 'in',
      length: 24,
      width: 24,
      height: 24,
      weightUoM: 'lbs',
      weight: 500,
      freightClass: 'CLASS_50',
      value: 2555,
      itemCondition: 'NEW',
      stackable: false,
      hazardous: false,
      hazmatDetails: BlankHazMatDetails,
      description: 'various items',
      tariff: '',
      countryOfOrigin: '',
    },
  ],
  commonAccessorials: { straps: true, tarps: true },
  customs: BlankCustoms,
};

export const FTL_REEFER_REQUEST_FIXTURE: BookingRequest = {
  ...DomesticRequestAddresses,
  billing: Origin,
  quote: {
    shipmentType: 'FTL',
    guid: '9ce0bcc5-3106-478d-91b1-0377d1d77bca',
    transitTime: 6,
    deliveryDate: dayjs().add(7, 'day').toDate(),
    pickupDate: dayjs().add(3, 'day').toDate(),
    cost: 166.44,
    truckType: Truck_Type.Reefer,
    baseRate: 166.23,
    ftlAccessorialCharges: [],
  },
  bookingDetails: {
    insuredValue: InsuranceValue.Unset,
    promoCode: '',
    acknowledgeShipmentAccuracy: false,
  },
  items: [
    {
      quantity: 5,
      packaging: 'PLT',
      sizeUoM: 'in',
      length: 24,
      width: 24,
      height: 24,
      weightUoM: 'lbs',
      weight: 500,
      freightClass: 'CLASS_50',
      value: 2555,
      itemCondition: 'NEW',
      stackable: false,
      hazardous: false,
      hazmatDetails: BlankHazMatDetails,
      description: 'Refrigerated steaks',
      tariff: '',
      countryOfOrigin: '',
    },
  ],
  commonAccessorials: { straps: true, tarps: true, temperature: 32 },
  customs: BlankCustoms,
};

export const retrieveFixtures = (fixtureId: string) => {
  const rfq =
    fixtureId === 'ltl'
      ? LTL_RFQ_FIXTURE
      : fixtureId === 'dltl'
      ? DOMESTIC_LTL_RFQ_FIXTURE
      : fixtureId === 'fftl'
      ? FTL_FLATBED_RFQ_FIXTURE
      : fixtureId === 'vftl'
      ? FTL_VAN_RFQ_FIXTURE
      : fixtureId === 'rftl'
      ? FTL_REEFER_RFQ_FIXTURE
      : null;

  const bookingRequest =
    fixtureId === 'ltl'
      ? LTL_REQUEST_FIXTURE
      : fixtureId === 'dltl'
      ? DOMESTIC_LTL_REQUEST_FIXTURE
      : fixtureId === 'fftl'
      ? FTL_FLATBED_REQUEST_FIXTURE
      : fixtureId === 'vftl'
      ? FTL_VAN_REQUEST_FIXTURE
      : fixtureId === 'rftl'
      ? FTL_REEFER_REQUEST_FIXTURE
      : null;

  return {
    rfq: rfq,
    bookingRequest: bookingRequest,
  };
};
