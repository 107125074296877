/**
 * Colors specific to the GoShip application go here!
 */
export const footerHeadingGreen = 'rgba(151, 182, 85, 1)';
export const goShipGreen = '#0A9E18';
export const progressBarBgGray = '#c1c1c1';
export const dividerColor = '#c1c1c1';
export const inputLabelGreen = '#324B43';
export const greenPrimary = '#83BE51';
export const greenPrimaryLight = '#7EBE41';
export const formLabelGrey = '#3E3E3E';
export const primaryDarkText = '#2F7437';
export const panelBackgroundColor = '#F5F5F5';
export const panelTextColor = '#737373';
export const creamBackground = '#FBFBF7';
export const appBgColor = creamBackground;
export const greyText = 'rgba(0, 0, 0, .65)';
export const accentRed = '#D6001A';
export const formErrorText = accentRed;
export const mainGreyText = '#3D3D3D';
export const lightGreyText = 'rgba(0, 0, 0, .35)';
export const anchorLink = greenPrimary;
export const taskRabbitBg = 'rgba(205, 227, 210, 0.22)';
export const facebookBlue = '#3b5998';
export const linkedInBlue = '#0077b5';

export default {
  appBgColor,
  footerHeadingGreen,
  goShipGreen,
  progressBarBgGray,
  inputLabelGreen,
  greenPrimary,
  greenPrimaryLight,
  formLabelGrey,
  primaryDarkText,
  dividerColor,
  panelBackgroundColor,
  panelTextColor,
  creamBackground,
  greyText,
  accentRed,
  formErrorText,
  mainGreyText,
  lightGreyText,
  anchorLink,
  taskRabbitBg,
  facebookBlue,
  linkedInBlue,
};
