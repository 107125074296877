import { gql, useMutation } from '@apollo/client';

import { BookingLog } from '@/utils/types';
import { Mutation } from 'types';

const UPDATE_BOOKING_LOG = gql`
  mutation UpdateBookingLog(
    $uuid: UUID!
    $amount: Float
    $logStatus: LOG_STATUS
    $rfq: JSON
    $quotes: JSON
    $selectedQuote: JSON
    $quoteLogUuid: String
  ) {
    updateBookingLog(
      input: {
        uuid: $uuid
        amount: $amount
        logStatus: $logStatus
        rfq: $rfq
        quotes: $quotes
        selectedQuote: $selectedQuote
        quoteLogUuid: $quoteLogUuid
      }
    ) {
      bookingLog {
        id
      }
      error
    }
  }
`;

export default function useUpdateBookingLogGQL() {
  const [updateBookingLogQL, { data, error, loading }] = useMutation<Mutation>(
    UPDATE_BOOKING_LOG,
  );
  const updateBookingLog = async (bookingLog: BookingLog) => {
    if (bookingLog) {
      const { data } = await updateBookingLogQL({
        variables: { ...bookingLog, uuid: bookingLog.id },
      });
      return data ? data.updateBookingLog : null;
    }
  };
  return [updateBookingLog, { data, error, loading }] as const;
}
