import dayjs from 'dayjs';
import { get, range } from 'lodash';

import { EndPoint } from '@/utils/types';

const hourInTimezone = (now: dayjs.Dayjs, timeOffset: number) =>
  (24 + now.utc().get('hour') + timeOffset) % 24;

const isWeekday = (now: dayjs.Dayjs, timeOffset: number) => {
  const day = now.utc().add(timeOffset, 'hours').get('day');
  return !(day === 0 || day === 6);
};
export const Holidays: {
  [year: number]: { [month: number]: { [date: number]: boolean } };
} = {
  2021: {
    7: {
      4: true, // Independence Day
      5: true, // Monday off
    },
    9: {
      6: true, // Labor Day
    },
    11: { 25: true, 26: true },
    12: { 24: true, 31: true },
  },
  2022: {
    5: {
      30: true, // Memorial Day
    },
    7: { 4: true }, // Independence Day
    9: { 5: true }, // Labor Day
    11: {
      11: true, // Veterans Day
      24: true, // Thanksgiving
      25: true, // Thanksgiving
    },
    12: {
      25: true, // Christmas Day
      26: true,
      31: true, // New Years Eve
    },
  },
  2023: {
    1: {
      2: true, //new year celebration
    },
    4: {
      10: true, // Easter
    },
    5: {
      29: true, // Memorial Day
    },
    7: { 4: true }, // Independence Day
    9: { 4: true }, // Labor Day
    11: {
      23: true, // Thanksgiving
    },
    12: {
      25: true, // Christmas Day
    },
  },
  2024: {
    1: {
      2: true, //new year celebration
    },
    5: {
      27: true, // Memorial Day
    },
    7: { 4: true }, // Independence Day
    9: { 2: true }, // Labor Day
    11: {
      28: true, // Thanksgiving
    },
    12: {
      25: true, // Christmas Day
    },
  },
};

const isHoliday = (s: dayjs.Dayjs) =>
  get(Holidays, [s.get('year'), s.get('month') + 1, s.get('date')]);

export const isPastNoon = (timeOffset: number) =>
  hourInTimezone(dayjs(), timeOffset) > 11;

/**
 * the allows dates for a pickup
 * 1. today or later, if it’s before noon at the origin; else tomorrow or later
 * 2. within 14 days
 * 3. weekdays only for LTL
 */
const getPickupDates = (endPoint: EndPoint | null): Date[] => {
  const timeOffset = endPoint?.address.timeOffset ?? -5;
  const now = dayjs();
  return range(isPastNoon(timeOffset) ? 1 : 0, 14)
    .map(n => now.add(n, 'day'))
    .filter(d => !isHoliday(d) && isWeekday(d, timeOffset))
    .map(d => d.toDate());
};

export default getPickupDates;
