import { gql } from '@apollo/client';

import { publicClient } from '../../graphql';

const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($uuid: String!, $password: String!) {
    updatePassword(input: { uuid: $uuid, password: $password }) {
      error
      success
    }
  }
`;

export default async function updatePasswordGQL(
  uuid: string,
  password: string,
) {
  const resp = await publicClient.mutate({
    mutation: UPDATE_PASSWORD,
    variables: {
      uuid,
      password,
    },
  });

  const data = resp.data.updatePassword;

  return { data };
}
