import { useFormikContext } from 'formik';
import { KeyboardEventHandler, KeyboardEvent, useCallback } from 'react';

export const useSubmitOnEnter = () => {
  const { submitForm } = useFormikContext();
  const onKeyPress: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        e.preventDefault();
        submitForm();
      }
    },
    [submitForm],
  );
  return onKeyPress;
};
