import { useBoolean } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { AuthUser, User, DraftUser } from '@/utils/types';
import {
  ResetPasswordError,
  SignInError,
  SignUpError,
  UserContext,
} from '@/utils/types/UserContext';

const storeLogin = (isLoggedIn: boolean) => {
  typeof window !== 'undefined' &&
    sessionStorage.setItem('MockLogin', JSON.stringify(isLoggedIn));
};
const retrieveLogin = (): boolean => {
  const s =
    typeof window !== 'undefined' && sessionStorage.getItem('MockLogin');
  return JSON.parse(s || 'false');
};

const MockUser: User = {
  personId: '25',
  email: 'mock@goship.com',
  firstName: 'Mock',
  lastName: 'Last',
  company: 'Test Company',
  phoneNumber: '1111111111',
  password: 'Test1234',
};

const MockAuthUser: AuthUser = {
  fullName: 'Mock Mockerson',
  personId: 33141,
};

const useMockUserContext = (): UserContext => {
  const [loading, { on: setLoading, off: clearLoading }] = useBoolean();
  const [initialized, { on: setInitialized }] = useBoolean();
  const mockSignin = async (email: string) => {
    setLoading();
    return new Promise<SignInError | null>(resolve => {
      setTimeout(() => {
        if (email === 'bad') {
          resolve('UNAUTHORIZED');
        } else {
          storeLogin(true);
          resolve(null);
        }
        clearLoading();
      }, 200);
    });
  };

  const mockSignUp = async (email: string) => {
    setLoading();
    return new Promise<SignUpError | null>(resolve => {
      setTimeout(() => {
        if (email === 'bad') {
          storeLogin(false);
          resolve('AlreadyInUse');
        } else {
          storeLogin(true);
          resolve(null);
        }
        clearLoading();
      }, 200);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateUser = (_user: Partial<DraftUser> & { password?: string }) =>
    Promise.resolve(MockUser);

  const signOut = () => {
    setLoading();
    setTimeout(() => {
      storeLogin(false);
      clearLoading();
    }, 100);
  };

  const changePassword = (
    _oldPassword: string,
    _newPassword: string,
  ): Promise<boolean> =>
    new Promise(resolve => {
      setTimeout(resolve, 500);
    });

  const updatePassword = (
    _uuid: string,
    _password: string,
  ): Promise<boolean | string> =>
    new Promise(resolve => {
      setTimeout(resolve, 500);
    });

  const useGetUser = () => {
    const [loading, { on: setLoading, off: clearLoading }] = useBoolean(false);
    const error = null;
    const [fullUser, setFullUser] = useState(null);

    useEffect(() => {
      if (!error && !fullUser) {
        setLoading();

        setTimeout(() => {
          setFullUser({
            // @ts-expect-error TODO Couldn't get to some of these in
            // https://plslogistics.atlassian.net/browse/GS-736
            firstName: 'John',
            lastName: 'Doe',
            email: 'john@test.com',
          });
          clearLoading();
        }, 500);
      }
    }, [error, fullUser, setLoading, clearLoading]);

    return { error, loading, user: fullUser };
  };

  const user = retrieveLogin() ? MockAuthUser : null;
  const signUp = ({ email }: { email: string }) => mockSignUp(email);
  const checkUser = () => Promise.resolve(false);
  const recordPaymentAttempt = () => Promise.resolve(false);
  const getIp = () =>
    Promise.resolve({
      ip: '',
      country: '',
      city: '',
      state: '',
    });
  setTimeout(() => {
    setInitialized();
  }, 200);

  const useResetPassword = () => {
    const [loading, { on: setLoading, off: clearLoading }] = useBoolean();
    const [reset, { on: setReset }] = useBoolean();
    return {
      error: null,
      reset,
      loading,
      resetPassword: (_: string) => {
        setLoading();
        return new Promise<ResetPasswordError | null>(resolve => {
          setTimeout(() => {
            setReset();
            clearLoading();
            resolve(null);
          }, 2000);
        });
      },
    };
  };

  const resendVerificationEmail = () => {
    setLoading();
    return new Promise<boolean>(resolve => {
      clearLoading();
      resolve(false);
    });
  };

  const useGetUserStatus = () => {
    setLoading();
    return true;
  };

  return {
    initialized,
    loading,
    user,
    signIn: mockSignin,
    signUp,
    updateUser,
    signOut,
    checkUser,
    recordPaymentAttempt,
    getIp,
    changePassword,
    useGetUser,
    useResetPassword,
    featureFlags: {},
    resendVerificationEmail,
    useGetUserStatus,
    vendor: false,
    userId: null,
    fullName: '',
    organizationName: '',
    updatePassword,
    userViewer: undefined,
  };
};
export default useMockUserContext;
