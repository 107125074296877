import { AppProps } from 'next/app';
import { createContext, FC } from 'react';

import useBackendBrokerContext from '@/backend/BackendBrokerContext';
import useBackendUserContext from '@/backend/BackendUserContext';
import MockBrokerContext from '@/backend/mocks/MockBrokerContext';
import useMockUserContext from '@/backend/mocks/MockUserContext';
import MockUtilityContext from '@/backend/mocks/MockUtilityContext';
import useSignInContext, {
  SignInContext,
} from '@/components/context/useSignInContext';
import SignInModal from '@/components/SignInModal';
import useTracking, { TrackingContext } from '@/utils/hooks/useTracking';
import { BrokerContext } from '@/utils/types/BrokerContext';
import { UserContext } from '@/utils/types/UserContext';
import { UtilityContext } from '@/utils/types/UtilityContext';

type GSAppContext = {
  user: UserContext;
  broker: BrokerContext;
  utility: UtilityContext;
  signIn: SignInContext;
  tracking: TrackingContext;
};

// @ts-expect-error TODO Couldn't get to some of these in
// https://plslogistics.atlassian.net/browse/GS-736
export const GSAppContext = createContext<GSAppContext>(null);

export const GSAppContextProvider: FC<AppProps> = ({ children }) => {
  // Defined in .env.local
  const backendURL = process.env.NEXT_PUBLIC_BACKEND_URL;

  const mockUserContext = useMockUserContext();
  // @ts-expect-error TODO Couldn't get to some of these in
  // https://plslogistics.atlassian.net/browse/GS-736
  const backendUserContext = useBackendUserContext(backendURL);
  // @ts-expect-error TODO Couldn't get to some of these in
  // https://plslogistics.atlassian.net/browse/GS-736
  const backendBrokerContext = useBackendBrokerContext(backendURL);
  const userContext = backendURL ? backendUserContext : mockUserContext;
  const brokerContext = backendURL ? backendBrokerContext : MockBrokerContext;
  const utilityContext = MockUtilityContext;
  const signInContext = useSignInContext();

  const tracking = useTracking();

  // and BrokerageContext and Utility Context...
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return (
    <GSAppContext.Provider
      value={{
        user: userContext,
        broker: brokerContext,
        utility: utilityContext,
        signIn: signInContext,
        tracking,
      }}>
      <SignInModal />
      {children}
    </GSAppContext.Provider>
  );
};
