import { gql } from '@apollo/client';

import { publicClient } from '../../graphql';

const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(input: { email: $email, password: $password }) {
      accessToken
      refreshToken
      error
    }
  }
`;

export default async function signIn({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  const resp = await publicClient.mutate({
    mutation: SIGN_IN,
    variables: {
      email,
      password,
    },
  });

  if (resp.errors) {
    return { error: 'NetworkError' };
  }

  const accessToken = resp.data?.signIn?.accessToken;
  const refreshToken = resp.data?.signIn?.refreshToken;
  const error = resp.data?.signIn?.error;

  return { error, accessToken, refreshToken };
}
