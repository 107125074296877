import {
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import React, { FC } from 'react';

import HelpIcon from './HelpIcon';

export const HelpPopup: FC<{
  text?: string;
  isDisabled?: boolean;
  color?: string;
}> = ({ text = '', isDisabled, children, color = 'text-grey-500' }) =>
  !isDisabled ? (
    <Popover trigger="hover">
      <PopoverTrigger>
        <span className={color}>
          {text}
          <HelpIcon />
        </span>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverBody>
          <div className="max-h-80 overflow-y-scroll font-normal leading-6 text-black text-base normal-case p-4">
            {children}
          </div>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  ) : null;
