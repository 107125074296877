const VERSION = 'v1';
export const ACCESS_TOKENS_KEY = `access_tokens_${VERSION}`;
const REFRESH_TOKENS_KEY = `refresh_tokens_${VERSION}`;
const IP_LOCATION = `location_${VERSION}`;

const getFromLocalStore = (name: string): string =>
  (typeof window !== 'undefined' && localStorage.getItem(name)) || '';

export const retrieveAccessToken = () => getFromLocalStore(ACCESS_TOKENS_KEY);

export const saveAccessToken = (value: string) =>
  localStorage.setItem(ACCESS_TOKENS_KEY, value);

export const retrieveRefreshToken = () => getFromLocalStore(REFRESH_TOKENS_KEY);

export const saveRefreshToken = (value: string) =>
  localStorage.setItem(REFRESH_TOKENS_KEY, value);

export const getIPLocation = () => atob(getFromLocalStore(IP_LOCATION));

export const saveIPLocation = (value: string) =>
  localStorage.setItem(IP_LOCATION, btoa(value));

export const clearTokens = () => localStorage.clear();
