import cx from 'classnames';

import { formLabelGrey } from '@/styles/Colors';

export default function LabelText({
  children,
  className,
  sm,
}: {
  children?: React.ReactNode;
  className?: string;
  sm?: boolean;
}) {
  return (
    <span
      className={cx(className, 'font-normal leading-none uppercase', {
        'text-sm': !sm,
        'text-xs': sm,
      })}
      style={{ color: formLabelGrey }}>
      {children}
    </span>
  );
}
