import { gql } from '@apollo/client';

import { publicClient } from '../../graphql';

const SIGN_UP = gql`
  mutation SignUp(
    $email: String!
    $firstName: String!
    $lastName: String!
    $company: String!
    $password: String!
    $phoneNumber: String!
    $referralCode: String
  ) {
    signUp(
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        companyName: $company
        password: $password
        phone: $phoneNumber
        referralCode: $referralCode
      }
    ) {
      accessToken
      refreshToken
      error
    }
  }
`;

export default async function signUp(
  {
    email,
    firstName,
    lastName,
    company,
    password,
    phoneNumber,
  }: {
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    password: string;
    phoneNumber: string;
  },
  referralCode?: string,
) {
  const resp = await publicClient.mutate({
    mutation: SIGN_UP,
    variables: {
      email,
      firstName,
      lastName,
      company,
      password,
      phoneNumber,
      referralCode,
    },
  });

  const accessToken = resp.data?.signUp?.accessToken;
  const refreshToken = resp.data?.signUp?.refreshToken;
  const error = resp.data?.signUp?.error;

  return { error, accessToken, refreshToken };
}
