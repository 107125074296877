/**
 * This hook supports integration with Ba/ance Checkout (getbalance.com)
 *
 */

import { useBoolean } from '@chakra-ui/react';
import { identity } from 'lodash';
import { useContext, useEffect, useState } from 'react';

import { GSAppContext } from '@/components/context/GSAppContext';

import { PaymentInfo } from '../types';

const balanceScriptUrl = process.env.NEXT_PUBLIC_BALANCE_SCRIPT_URL;

const loadScript = (id: string, url: string, onload?: () => void) => {
  const existingScript = document.getElementById(id);
  if (existingScript) {
    onload && onload();
  } else {
    const script = document.createElement('script');
    script.src = url;
    script.id = id;
    document.body.appendChild(script);
    script.onload = onload || identity;
  }
};

function openCheckout(
  checkoutToken: string,
  logoURL: string,
  onSuccess: () => void,
  onError: (err: any) => void,
  onClose: () => void,
) {
  // @ts-expect-error TS doesn't know about balance on window obj
  const api = window?.balanceSDK?.checkout;

  const checkoutOptions = {
    isAuth: true,
    skipSuccessPage: false,
    logoURL,
    onClose: () => {
      api?.destroy();
      onClose();
    },
    onCancel: () => {
      onClose();
    },
    onSuccess,
    onError,
  };
  api?.init(checkoutOptions);
  api?.render(checkoutToken, '#balance-checkout');
}

const ScriptID = 'balanceScript';

const useBalanceCheckout = (logoURL: string) => {
  const [loaded, { on: setLoaded }] = useBoolean();
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | null>(null);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [paymentMade, setPaymentMade] = useState<PaymentInfo | null>(null);
  const [paymentError, setPaymentError] = useState('');
  const [paymentBlockedError, setPaymentBlockedError] = useState(false);

  const {
    user: { recordPaymentAttempt },
  } = useContext(GSAppContext);

  useEffect(() => {
    if (!loaded) {
      loadScript(ScriptID, balanceScriptUrl || '', setLoaded);
    }
  }, [loaded, setLoaded]);

  useEffect(() => {
    if (loaded && paymentInfo) {
      openCheckout(
        paymentInfo.token,
        logoURL,
        () => {
          setPaymentMade(paymentInfo);
        },
        async err => {
          const block = await recordPaymentAttempt(
            err.error.code,
            paymentInfo.id,
            paymentInfo.amount,
          );
          if (block) {
            setPaymentBlockedError(true);
          }
          setPaymentError('Error making payment');
        },
        () => {
          setIsPaymentOpen(false);
        },
      );
      setPaymentInfo(null);
      setIsPaymentOpen(true);
    }
  }, [loaded, logoURL, paymentInfo, recordPaymentAttempt]);

  return {
    openCheckout: setPaymentInfo,
    isPaymentOpen,
    paymentMade,
    paymentError,
    paymentBlockedError,
  };
};

export default useBalanceCheckout;
