import { useToast } from '@chakra-ui/react';
import { captureException } from '@sentry/nextjs';
import { Formik } from 'formik';
import { useContext } from 'react';

import Button from '@/components/Button';
import { GSAppContext } from '@/components/context/GSAppContext';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import TOSCallout from '@/components/TOSCallout';
import ValidatedField from '@/components/ValidatedField';
import { SignupSchema } from '@/utils/validations';

export default function SignUpForm({
  onComplete,
  referralCode,
}: {
  onComplete: () => void;
  referralCode?: string;
}) {
  const {
    user: { loading, signUp },
    tracking: { trackSignUp },
  } = useContext(GSAppContext);
  const toast = useToast();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        cpassword: '',
        company: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
      }}
      onSubmit={async ({ cpassword: _, ...draftUser }, { setErrors }) => {
        draftUser.email = draftUser.email.toLocaleLowerCase();
        const error = await signUp(draftUser, referralCode);
        if (error === 'AlreadyInUse') {
          setErrors({ email: 'This email is already in use' });
        } else if (error) {
          toast({
            title: 'An unknown error occurred',
            description: 'Please try again, or contact support',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          captureException(`Error signing up: ${error}`);
        } else {
          toast({
            title: 'Success!',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          trackSignUp({ referralCode });
          onComplete();
        }
      }}
      validationSchema={SignupSchema}>
      {({ isValid, dirty, submitForm }) => (
        <div className="flex flex-col items-center mx-3 my-4 space-between">
          <div className="font-bold text-2xl mb-2">Create New Account</div>
          <div className="mb-1 w-full">
            <ValidatedField
              data-cy="sign-up-first-name-input"
              label="First name"
              placeholder="First name"
              name="firstName"
            />
          </div>
          <div className="mb-1 w-full">
            <ValidatedField
              data-cy="sign-up-last-name-input"
              label="Last name"
              placeholder="LastName"
              name="lastName"
            />
          </div>
          <div className="mb-1 w-full">
            <ValidatedField
              data-cy="sign-up-email-input"
              label="Email"
              name="email"
              type="email"
              placeholder="user@example.org"
            />
          </div>
          <div className="mb-1 w-full">
            <ValidatedField
              data-cy="sign-up-company-input"
              label="Company"
              name="company"
              placeholder="Enter text"
            />
          </div>
          <div className="mb-1 w-full">
            <ValidatedField label="Phone Number" name="phoneNumber">
              {({ field }) => (
                <PhoneNumberInput
                  data-cy="sign-up-phone-input"
                  {...field}
                  placeholder="(xxx) xxx-xxxxx"
                />
              )}
            </ValidatedField>
          </div>
          <div className="mb-1 w-full">
            <ValidatedField
              data-cy="sign-up-password-input"
              label="Create Password"
              name="password"
              type="password"
              placeholder="******"
            />
          </div>
          <div className="mb-1 w-full">
            <ValidatedField
              data-cy="sign-up-confirm-password-input"
              label="Confirm Password"
              name="cpassword"
              type="password"
              placeholder="******"
            />
          </div>
          <div className="text-xs mb-2 px-2">
            Transit times are estimates, and do not include the pickup date as a
            transit day. Transit time are not guaranteed. Payments only accepted
            in USD. Payments made via CC
          </div>
          <TOSCallout />
          <Button
            data-cy="create-account-button"
            isLoading={loading}
            disabled={!dirty || !isValid}
            onClick={submitForm}
            variant="solid">
            <span className="text-xl">CREATE ACCOUNT</span>
          </Button>
        </div>
      )}
    </Formik>
  );
}
