import { gql, useMutation } from '@apollo/client';

import { BookCuttlefishShipment } from './__generated__/BookCuttlefishShipment';

const BOOK_SHIPMENT = gql`
  mutation BookCuttlefishShipment($bookingId: String!, $legacyPayload: JSON!) {
    bookCuttlefishShipment(
      input: { bookingId: $bookingId, legacyPayload: $legacyPayload }
    ) {
      error
      loadId
    }
  }
`;

export default function useBookShipment() {
  const [
    bookShipmentQL,
    { data, loading, error },
  ] = useMutation<BookCuttlefishShipment>(BOOK_SHIPMENT);

  const bookShipment = async (bookingId: string, legacyPayload: any) => {
    if (bookingId) {
      const { data } = await bookShipmentQL({
        variables: { bookingId, legacyPayload },
      });
      return data ? data.bookCuttlefishShipment : null;
    }
    return Promise.resolve(null);
  };
  return [bookShipment, { data, loading, error }] as const;
}
