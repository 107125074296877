import { Button, ButtonProps } from '@chakra-ui/react';
import cx from 'classnames';
import { forwardRef } from 'react';

import { inputLabelGreen, primaryDarkText, goShipGreen } from '@/styles/Colors';

type GoShipButtonVariant = 'link' | 'solid' | 'outline';
export type GoShipButtonProps = {
  variant?: GoShipButtonVariant;
  children: React.ReactNode;
  className?: string;
  style?: { [key: string]: string | number | undefined };
  onClick?: () => void;
} & ButtonProps;

const PropsByVariant: { [key in GoShipButtonVariant]: ButtonProps } = {
  link: {
    variant: 'link',
    style: { color: inputLabelGreen, fontWeight: 400 },
  },
  solid: {
    variant: 'solid',
    className: 'text-white shadow-lg',
    style: { backgroundColor: goShipGreen },
  },
  outline: {
    variant: 'outline',
    style: { borderColor: primaryDarkText, color: primaryDarkText },
  },
};

const GoShipButton = forwardRef(
  (
    {
      variant,
      style,
      className,
      onClick,
      children,
      ...props
    }: GoShipButtonProps,
    ref,
  ) => {
    // @ts-expect-error TODO Couldn't get to some of these in
    // https://plslogistics.atlassian.net/browse/GS-736
    const buttonProps = PropsByVariant[variant];
    return (
      <Button
        ref={ref}
        {...buttonProps}
        onClick={onClick}
        style={{ ...buttonProps.style, ...(style || {}) }}
        className={cx(buttonProps.className, className)}
        {...props}>
        {children}
      </Button>
    );
  },
);

export default GoShipButton;
