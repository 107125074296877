/* eslint-disable react/jsx-no-target-blank */
import { TriangleDownIcon } from '@chakra-ui/icons';
import {
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  MenuDivider,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
} from '@chakra-ui/react';
import { MenuIcon } from '@heroicons/react/outline';
import cx from 'classnames';
import NextLink from 'next/link';
import { useContext, useMemo } from 'react';

import { greenPrimary } from '@/styles/Colors';
import useSignOut from '@/utils/hooks/useSignOut';

import { GSAppContext } from './context/GSAppContext';

export const resources = {
  title: 'Resources',
  links: [
    {
      label: 'About',
      url: 'https://www.goship.com/about-us/',
    },
    {
      label: 'Industry Shipping',
      url: 'https://www.goship.com/industry-shipping/',
    },
    {
      label: 'LTL Carriers For Freight',
      url: 'https://www.goship.com/ltl-carriers/',
    },
    {
      label: 'Taskrabbit',
      url: 'https://www.goship.com/task-rabbit/',
    },
    { label: 'Blog', url: 'https://www.goship.com/blog/' },
    { label: 'News', url: 'https://www.goship.com/blog/category/news/' },
    {
      label: 'Freight Insurance & Claims',
      url: 'https://www.goship.com/resources/freight-insurance-claims/',
    },
  ],
};
export const services = {
  title: 'Services',
  links: [
    {
      label: 'LTL Freight',
      url: 'https://www.goship.com/shipping-services/ltl-freight-shipping/',
    },
    {
      label: 'Truckload Freight',
      url:
        'https://www.goship.com/shipping-services/truckload-freight-shipping/',
    },
    {
      label: 'Small Business',
      url: 'https://www.goship.com/shipping-services/small-business-shipping/',
    },
    {
      label: 'Large Items',
      url: 'https://www.goship.com/shipping-services/large-item-shipping/',
    },
    {
      label: 'International',
      url: 'https://www.goship.com/shipping-services/international-shipping/',
    },
    {
      label: 'LTL Quote',
      url: 'https://www.goship.com/shipping-services/ltl-carrier/',
    },
    {
      label: 'FTL Quote',
      url: 'https://www.goship.com/shipping-services/truckload-quote/',
    },
  ],
};

const HeaderLink = ({
  href,
  className,
  onClick,
  label,
  dataCyId,
}: {
  href?: string;
  onClick?: () => void;
  label: string;
  className?: string;
  dataCyId?: string;
}) => (
  <Link
    onClick={onClick}
    target="_blank"
    className={cx(className, 'mr-3 text-white')}
    href={href}
    data-cy={dataCyId}>
    <span className="text-white">{label}</span>
  </Link>
);

/**
 * Only visible on screens smaller than md breakpoint (786px)
 */
const MobileMenu = () => {
  const {
    user: { signOut, user, loading: userLoading },
    signIn: { openSignInModal },
  } = useContext(GSAppContext);

  const isLoggedIn = useMemo(() => !userLoading && user !== null, [
    user,
    userLoading,
  ]);

  return (
    <div className="md:hidden">
      <Menu closeOnSelect={false}>
        <MenuButton>
          <MenuIcon className="h-6 w-6 text-white" />
        </MenuButton>
        <MenuList>
          {isLoggedIn ? (
            <MenuItem onClick={signOut}>Sign out</MenuItem>
          ) : (
            <>
              <MenuItem onClick={() => openSignInModal('signin')}>
                Sign in
              </MenuItem>
            </>
          )}
          <MenuDivider />

          {isLoggedIn && (
            <>
              <MenuItem>
                <NextLink href="/">Shipping Dashboard</NextLink>
              </MenuItem>
              <MenuDivider />
            </>
          )}

          <MenuGroup title={resources.title}>
            {resources.links.map(({ label, url }) => (
              <MenuItem key={url}>
                <a target="_blank" href={url}>
                  {label}
                </a>
              </MenuItem>
            ))}
          </MenuGroup>
          <MenuDivider />
          <MenuGroup title={services.title}>
            {services.links.map(({ label, url }) => (
              <MenuItem key={url}>
                <a target="_blank" href={url}>
                  {label}
                </a>
              </MenuItem>
            ))}
          </MenuGroup>
          <MenuDivider />

          <MenuItem>
            <a target="_blank" href="https://www.goship.com/api/">
              API Integration
            </a>
          </MenuItem>
          <MenuDivider />
          <MenuItem>
            <a
              target="_blank"
              href="https://www.goship.com/resources/contact-us/">
              Contact Us
            </a>
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
};
/**
 * Only visble on md breakpoint and larger
 */
const DesktopMenu = () => {
  const {
    user: { user, loading: userLoading },
    signIn: { openSignInModal },
  } = useContext(GSAppContext);
  const performSignout = useSignOut();

  const isLoggedIn = useMemo(() => !userLoading && user !== null, [
    user,
    userLoading,
  ]);

  return (
    <div className="hidden md:flex justify-end items-center flex-grow font-bold">
      {isLoggedIn && (
        <NextLink href="/">
          <span className="cursor-pointer mr-5 text-white">
            Shipping Dashboard
          </span>
        </NextLink>
      )}

      <Popover trigger="hover" placement="bottom-end">
        <PopoverTrigger>
          <span className="mr-5 text-white cursor-pointer">
            {resources.title} <TriangleDownIcon boxSize={2}></TriangleDownIcon>
          </span>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <div className="p-4 pb-2">
              {resources.links.map(l => (
                <div key={l.url} className="mb-2">
                  <a target="_blank" href={l.url}>
                    {l.label}
                  </a>
                </div>
              ))}
            </div>
          </PopoverContent>
        </Portal>
      </Popover>
      <Popover trigger="hover" placement="bottom-end">
        <PopoverTrigger>
          <span className="mr-5 text-white cursor-pointer">
            {services.title} <TriangleDownIcon boxSize={2}></TriangleDownIcon>
          </span>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <div className="p-4 pb-2">
              {services.links.map(l => (
                <div key={l.url} className="mb-2">
                  <a target="_blank" href={l.url}>
                    {l.label}
                  </a>
                </div>
              ))}
            </div>
          </PopoverContent>
        </Portal>
      </Popover>

      <HeaderLink
        href="https://www.goship.com/api/"
        label="API Integration"
        className="mr-5 cursor-pointer"
      />

      {isLoggedIn ? (
        <HeaderLink
          className="mr-5"
          onClick={performSignout}
          label="Sign out"
        />
      ) : (
        <HeaderLink
          className="mr-5"
          onClick={() => openSignInModal('signin')}
          label="Sign in"
          dataCyId="sign-in-button"
        />
      )}
      <HeaderLink
        href="https://www.goship.com/resources/contact-us/"
        className="mr-5"
        label="Contact Us"
      />
    </div>
  );
};

export default function AppHeader() {
  const {
    user: { user },
  } = useContext(GSAppContext);

  return (
    <div
      className="px-2 py-4 flex h-24 justify-between"
      style={{ backgroundColor: greenPrimary }}>
      <Link
        href={user ? '/' : '/booking/request-quote'}
        className="md:ml-56 md:mt-2">
        <img
          data-cy="goship-logo"
          className="h-12 cursor-pointer"
          src="/logo-lg-white.png"
          alt="Goship logo"
        />
      </Link>

      <DesktopMenu />
      <MobileMenu />
    </div>
  );
}
