import { Divider } from '@chakra-ui/react';
import cx from 'classnames';
import React, { FC } from 'react';

import { greenPrimary } from '@/styles/Colors';

import { resources, services } from './AppHeader';

const SocialLink = ({
  src,
  alt,
  url,
}: {
  src: string;
  alt: string;
  url: string;
}) => (
  <div>
    <a href={url} target="_blank" rel="noreferrer">
      <img className={cx('h-10 w-auto md:mb-2')} src={src} alt={alt} />
    </a>
  </div>
);

const FooterBlock: FC<{ title: string }> = ({ title, children }) => (
  <div className="mb-2">
    <div className="font-bold text-2xl text-white">{title}</div>
    <div className="mt-4 font-bold text-white">{children}</div>
  </div>
);

export default function AppFooter() {
  return (
    <div className="md:px-56 px-6" style={{ backgroundColor: greenPrimary }}>
      <div className="grid md:grid-cols-4 grid-cols-1 py-8">
        <FooterBlock title="Contact Us">
          <div>Phone Number:</div>
          <div className="mt-4">
            {' '}
            <a href="tel:+18338467447">
              <u>833-U-GOSHIP</u>
            </a>
          </div>
          <div className="mt-4">
            {' '}
            <a
              href="tel:+18338467447"
              data-type="tel"
              data-id="tel:+18338467447">
              <u>(833-846-7447)</u>
            </a>
          </div>
          <div className="mt-4"> Support Hours:</div>
          <div className="mt-4"> Monday - Friday:</div>
          <div className="mt-4">8:00 AM - 5:00 PM EST </div>
        </FooterBlock>
        <FooterBlock title="Resources">
          {resources.links.map(({ label, url }) => (
            <div key={url}>
              <a target="_blank" href={url} rel="noreferrer">
                <u>{label}</u>
              </a>
              <br />
            </div>
          ))}
        </FooterBlock>
        <FooterBlock title="Quick Links">
          {services.links.map(({ label, url }) => (
            <div key={url}>
              <a target="_blank" href={url} rel="noreferrer">
                <u>{label}</u>
              </a>
              <br />
            </div>
          ))}
        </FooterBlock>

        <FooterBlock title="Social">
          <div className="grid grid-cols-4">
            <SocialLink
              url="https://www.facebook.com/GoShipOfficial/"
              src="/facebook-white.png"
              alt="Facebook"
            />
            <SocialLink
              url="https://twitter.com/GoShipFreight"
              src="/twitter-white.png"
              alt="Twitter"
            />
            <SocialLink
              url="https://www.linkedin.com/company/go-ship/"
              src="/linkedin-white.png"
              alt="LinkedIn"
            />
            <SocialLink
              url="https://www.youtube.com/channel/UCqVXnrFC9Std_QSzIWNCNBw"
              src="/youtube-white.png"
              alt="YouTube"
            />
          </div>
        </FooterBlock>
      </div>
      <Divider></Divider>
      <div className="flex items-stretch py-2 md:py-4 text-white">
        <div className="flex-1">
          © {new Date().getFullYear()} GoShip. All Rights Reserved.
        </div>
        <div className="flex-1">
          <a
            target="_blank"
            href="https://www.goship.com/goship-terms/"
            rel="noreferrer">
            Terms & Conditions
          </a>{' '}
          |{' '}
          <a
            target="_blank"
            href="https://www.goship.com/privacy-policy/"
            rel="noreferrer">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
}
