import { isWeekend } from 'date-fns';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { isUndefined } from 'lodash';
import * as yup from 'yup';

import {
  CUBIC_CM_TO_CUBIC_INCHES,
  DistanceInInches,
  KG_TO_LBS,
  WeightInLbs,
} from '@/utils/calculateClass';
import { isPastNoon, Holidays } from '@/utils/getPickupDates';
import {
  BookingRequestLTLItem,
  FTLItemSchema,
  testBookingRequestItems,
  FreeFieldsStringSchema,
} from '@/utils/validations/items';

import { BookingRequest, InsuranceValue, isLtlQuote } from '../types';

import { isDomestic } from './isDomestic';
import { PhoneSchema } from './PhoneSchema';

dayjs.extend(isToday);

export const ForgotPasswordSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Required'),
});

export const SigninSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Required'),
  password: yup.string().required('Required'),
});

export const SignupSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email must be in valid format')
    .required('Email is required'),
  password: yup
    .string()
    .required('Required')
    .test(
      'password-long',
      'Password must be between 6 and 20 characters',
      password => !!(password && password.length > 5 && password.length < 21),
    )
    .test(
      'password-strong',
      'Password must include an upper-case letter, a lower-case letter, and a digit',
      password =>
        !!(
          password &&
          !!/\d/.exec(password) &&
          !!/[a-z]/.exec(password) &&
          !!/[A-Z]/.exec(password)
        ),
    ),
  company: FreeFieldsStringSchema.required('Company is required').test(
    // Not only is it bad practice, it actually messes up some back-end functions
    // to use "Goship" as a name.  See JIRA ticket GS-842.
    'company-not-goship',
    'Company is required',
    company =>
      !(
        !company ||
        company.replace(/\W/g, '').toLocaleLowerCase().startsWith('goship')
      ),
  ),
  firstName: FreeFieldsStringSchema.required('First name is required'),
  lastName: FreeFieldsStringSchema.required('Last name is required'),
  phoneNumber: PhoneSchema,
  cpassword: yup
    .string()
    .test(
      'cpassword-matches',
      'Passwords must match',
      (cpassword, { parent: { password } }) => cpassword === password,
    ),
});

export const ChangePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required('Required'),
  newPassword: yup.string().required('Required'),
  confirmNewPassword: yup
    .string()
    .test(
      'newPassword-matches',
      'Passwords must match',
      (password, { parent: { newPassword } }) => password === newPassword,
    ),
});

export const UpdatePasswordSchema = yup.object().shape({
  newPassword: yup.string().required('Required'),
  confirmNewPassword: yup
    .string()
    .test(
      'newPassword-matches',
      'Passwords must match',
      (password, { parent: { newPassword } }) => password === newPassword,
    ),
});

const AddressSchema = yup
  .object()
  .shape({
    companyName: FreeFieldsStringSchema.required('Company is required'),
    streetAddress: FreeFieldsStringSchema.required('Address is required').test(
      'streetAddress',
      'Address is too long',
      (_, { parent: { streetAddress, streetAddress1 } }) =>
        (streetAddress ? streetAddress.length : 0) +
          (streetAddress1 ? streetAddress1.length : 0) <=
        200,
    ),
    postalCode: yup.string().required('Postal code is required'),
    firstName: FreeFieldsStringSchema.required('First name is required'),
    lastName: FreeFieldsStringSchema.required('Last name is required'),
    phone: PhoneSchema,
    email: yup
      .string()
      .required('Contact email is required')
      .matches(
        /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
        'Email must be in valid format',
      ),
  })
  .test('full-name-max-length', '', (value, testContext: yup.TestContext) => {
    if (
      value.firstName &&
      value.lastName &&
      value.firstName.length + value.lastName.length > 46
    ) {
      throw testContext.createError({
        message: `Full Name exceeds the character limit`,
        path: testContext.path + '.full-name-max-length',
      });
    }

    return true;
  });

// minimum 2 hour between start and end
// cannot be earlier than 7am
// cannot be later than 4pm
// @ts-expect-error TODO Couldn't get to some of these in
// https://plslogistics.atlassian.net/browse/GS-736
const timeWindowTest = (time, { parent: { startTime, endTime } }) => {
  const diff = dayjs(startTime).diff(endTime, 'hours');

  const startTimeHour = dayjs(startTime).hour();
  const endTimeHour = dayjs(endTime).hour();
  const endTimeMinute = dayjs(endTime).minute();

  return (
    diff <= -2 &&
    startTimeHour >= 7 &&
    (endTimeHour < 16 || (endTimeHour === 16 && endTimeMinute === 0))
  );
};

export const EndPointSchema = yup.object().shape({
  address: AddressSchema,
  startTime: yup
    .date()
    .required('Start time is required')
    .test(
      'startTime',
      'Time must be between 7am and 4pm with a minimum 2 hours pickup window.',
      timeWindowTest,
    ),
  endTime: yup
    .date()
    .required('End time is required')
    .test(
      'endTime',
      'Time must be between 7am and 4pm with a minimum 2 hours pickup window.',
      timeWindowTest,
    ),
});

const isReefer = (bookingRequest: BookingRequest) => {
  const { quote } = bookingRequest;
  if (isLtlQuote(quote)) {
    return false;
  }
  return quote.truckType === 'reefer';
};

const CommonAccessorialsSchema = yup.object().shape({
  temperature: yup
    .number()
    .test(
      'check-temperature-set',
      'Temperature is required',
      // @ts-expect-error TODO Couldn't get to some of these in
      // https://plslogistics.atlassian.net/browse/GS-736
      (
        value,
        testContext: yup.TestContext<{ bookingRequest: BookingRequest }>,
      ) =>
        !isUndefined(value) ||
        // @ts-expect-error TODO Couldn't get to some of these in
        // https://plslogistics.atlassian.net/browse/GS-736
        !isReefer(testContext.options.context?.bookingRequest),
    )
    .test(
      'check-temperature-low',
      'Temperature must be below +100°.',
      // @ts-expect-error TODO Couldn't get to some of these in
      // https://plslogistics.atlassian.net/browse/GS-736
      (
        value,
        testContext: yup.TestContext<{ bookingRequest: BookingRequest }>,
      ) =>
        // @ts-expect-error TODO Couldn't get to some of these in
        // https://plslogistics.atlassian.net/browse/GS-736
        !isReefer(testContext.options.context?.bookingRequest) || value < 100,
    )
    .test(
      'check-temperature-high',
      'Temperature must be above -100°.',
      // @ts-expect-error TODO Couldn't get to some of these in
      // https://plslogistics.atlassian.net/browse/GS-736
      (
        value,
        testContext: yup.TestContext<{ bookingRequest: BookingRequest }>,
      ) =>
        // @ts-expect-error TODO Couldn't get to some of these in
        // https://plslogistics.atlassian.net/browse/GS-736
        !isReefer(testContext.options.context?.bookingRequest) || value > -100,
    ),
});

const CustomsAddressSchema = yup.object().test(
  'customs-address',
  'Customs address is invalid',
  // @ts-expect-error TODO Couldn't get to some of these in
  // https://plslogistics.atlassian.net/browse/GS-736
  (value, testContext: yup.TestContext<{ bookingRequest: BookingRequest }>) => {
    if (
      // @ts-expect-error TODO Couldn't get to some of these in
      // https://plslogistics.atlassian.net/browse/GS-736
      !isDomestic(testContext.options.context?.bookingRequest) &&
      testContext.options.context?.bookingRequest.customs.createCustomsInvoice
    ) {
      AddressSchema.validateSync(value, testContext.options);
    }
    return true;
  },
);

const BookingDetailsSchema = yup.object().shape({
  acknowledgeShipmentAccuracy: yup
    .boolean()
    .oneOf([true], 'You  must acknowledge the shipment accuracy'),
});

const CustomsSchema = yup
  .object()
  .shape({
    broker: yup
      .object()
      .shape({
        brokerAuthorized: yup.boolean().test(
          'broker-auth',
          'You must authorize a broker',
          // @ts-expect-error TODO Couldn't get to some of these in
          // https://plslogistics.atlassian.net/browse/GS-736
          (
            value,
            testContext: yup.TestContext<{ bookingRequest: BookingRequest }>,
          ) =>
            // @ts-expect-error TODO Couldn't get to some of these in
            // https://plslogistics.atlassian.net/browse/GS-736
            isDomestic(testContext.options.context?.bookingRequest) || value,
        ),
        usePlsBroker: yup.boolean(),
      })
      .test(
        'broker-address',
        'Broker address must be valid',
        // @ts-expect-error TODO Couldn't get to some of these in
        // https://plslogistics.atlassian.net/browse/GS-736
        (
          value,
          testContext: yup.TestContext<{ bookingRequest: BookingRequest }>,
        ) => {
          if (
            // @ts-expect-error TODO Couldn't get to some of these in
            // https://plslogistics.atlassian.net/browse/GS-736
            !isDomestic(testContext.options.context?.bookingRequest) &&
            !value.usePlsBroker
          ) {
            AddressSchema.validateSync(value, testContext.options);
          }
          return true;
        },
      ),
    vendor: CustomsAddressSchema,
    purchaser: CustomsAddressSchema,
    termsOfSale: yup.string().test(
      'broker-address',
      'Select terms of sale',
      // @ts-expect-error TODO Couldn't get to some of these in
      // https://plslogistics.atlassian.net/browse/GS-736
      (
        value,
        testContext: yup.TestContext<{ bookingRequest: BookingRequest }>,
      ) =>
        // @ts-expect-error TODO Couldn't get to some of these in
        // https://plslogistics.atlassian.net/browse/GS-736
        isDomestic(testContext.options.context?.bookingRequest) ||
        !testContext.options.context?.bookingRequest.customs
          .createCustomsInvoice ||
        !!value,
    ),
  })
  .nullable();

export const BookingRequestSchema = yup.object().shape({
  bookingRequest: yup
    .object()
    .shape({
      origin: EndPointSchema,
      destination: EndPointSchema,
      billing: yup.object().shape({
        address: AddressSchema,
      }),
      quote: yup.object().shape({
        shipmentType: yup.string(),
      }),
      items: yup.array().when('quote.shipmentType', {
        is: 'FTL',
        then: yup.array().of(FTLItemSchema).min(1),
        otherwise: yup.array().of(BookingRequestLTLItem).min(1),
      }),
      commonAccessorials: CommonAccessorialsSchema,
      customs: CustomsSchema,
      bookingDetails: yup.object().when('quote.shipmentType', {
        is: 'LTL',
        then: BookingDetailsSchema,
      }),
    })
    .test(
      'max-volume',
      '',
      testBookingRequestItems(
        'total volume',
        'maxTotalVolume',
        ({ height, width, length, quantity, sizeUoM }) =>
          // @ts-expect-error TODO Couldn't get to some of these in
          // https://plslogistics.atlassian.net/browse/GS-736
          DistanceInInches.to(height, sizeUoM) *
          // @ts-expect-error TODO Couldn't get to some of these in
          // https://plslogistics.atlassian.net/browse/GS-736
          DistanceInInches.to(width, sizeUoM) *
          // @ts-expect-error TODO Couldn't get to some of these in
          // https://plslogistics.atlassian.net/browse/GS-736
          DistanceInInches.to(length, sizeUoM) *
          // @ts-expect-error TODO Couldn't get to some of these in
          // https://plslogistics.atlassian.net/browse/GS-736
          quantity,
        in3 =>
          `${in3.toLocaleString()} cubic inches (${Math.floor(
            in3 / CUBIC_CM_TO_CUBIC_INCHES,
          ).toLocaleString()} cubic cm)`,
      ),
    )
    .test(
      'max-weight',
      '',
      testBookingRequestItems(
        'total weight',
        'maxTotalWeight',
        ({ weight, quantity, weightUoM }) =>
          // @ts-expect-error TODO Couldn't get to some of these in
          // https://plslogistics.atlassian.net/browse/GS-736
          WeightInLbs.to(weight, weightUoM) * quantity,
        lbs =>
          `${lbs.toLocaleString()} lbs (${Math.floor(
            lbs / KG_TO_LBS,
          ).toLocaleString()} kg)`,
      ),
    )
    .test(
      'max-quantity',
      '',
      testBookingRequestItems(
        'total quantity',
        'maxQuantity',
        ({ quantity }) => quantity || 0,
      ),
    ),
});

const insuranceNeeded = 'Please accept or decline insurance';

export const HolidayTest = (value: any, context: yup.TestContext) => {
  const pickupDate = value.date;
  const month = dayjs(pickupDate).get('month') + 1; // month is zero-indexed
  const date = dayjs(pickupDate).get('date');
  const year = dayjs(pickupDate).get('year');

  if (Holidays?.[year]?.[month]?.[date]) {
    throw context.createError({
      message: `Cannot schedule pickup/delivery (${dayjs(pickupDate).format(
        'MMMM D, YYYY',
      )}) on a holiday`,
    });
  }

  return true;
};

export const PaymentSchema = yup.object().shape({
  bookingRequest: yup.object().shape({
    origin: yup
      .object()
      .shape({
        address: yup.object().shape({
          date: yup.date(),
          timeOffset: yup.number(),
        }),
      })
      .test(
        'same-day-before-noon',
        'Cannot book a same-day shipment after 12 PM',
        value => {
          const timeOffset =
            value.address.timeOffset ??
            (new Date().getTimezoneOffset() / 60) * -1;
          return !(dayjs(value.date).isToday() && isPastNoon(timeOffset));
        },
      )
      .test('no-holiday-pickup', '', HolidayTest)
      .test(
        'no-weekend-pickup',
        'Cannot book a shipment on weekends',
        value => !isWeekend(value.date),
      ),
    bookingDetails: yup.object().shape({
      insuredValue: yup
        .number()
        .required(insuranceNeeded)
        .test(
          'Is set?',
          insuranceNeeded,
          value => value !== InsuranceValue.Unset,
        )
        .test(
          'Is calculated?',
          'Please calculate premium',
          value => value !== InsuranceValue.Uncalculated,
        ),
    }),
  }),
});
