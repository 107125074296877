import { useBoolean } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { createContext, FC, useContext, useEffect } from 'react';

import { BookingFlowContext } from '@/components/context/BookingFlowProvider';
import { retrieveFixtures } from '@/components/context/fixtures';
import { asString } from '@/utils/asString';

const FixtureContext = createContext(false);

export const useIsFixtureInitialize = () => {
  const initialized = useContext(FixtureContext);
  return initialized;
};

const LoadFixtureData: FC = ({ children }) => {
  const [initialized, { on: setInitialized }] = useBoolean();
  const { dispatch, rfq, bookingRequest } = useContext(BookingFlowContext);
  const router = useRouter();
  useEffect(() => {
    if (router.isReady && !initialized) {
      try {
        const {
          query: { dev },
        } = router;
        const { rfq, bookingRequest } = retrieveFixtures(asString(dev));

        if (bookingRequest) {
          dispatch({
            type: 'setBookingRequest',
            value: bookingRequest,
          });
        }
        if (rfq) {
          dispatch({
            type: 'setRFQ',
            value: rfq,
          });
        }
      } finally {
        setInitialized();
      }
    }
  }, [bookingRequest, router, dispatch, rfq, setInitialized, initialized]);

  return (
    <FixtureContext.Provider value={initialized}>
      {children}
    </FixtureContext.Provider>
  );
};
export default LoadFixtureData;
