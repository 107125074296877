import dayjs from 'dayjs';

import { BlankHazMatDetails } from '@/utils/BlankObject';
import {
  Booking,
  FTL_RFQ,
  LTL_RFQ,
  FullEndPoint,
  TimeAddress,
} from '@/utils/types';
import { LtlQuote } from 'types';

import { Truck_Type } from '../../publicTypes.d';
import { RawLtlRFQ, convertRawBooking, RawLoadDetails } from '../BackendTypes';
import { RequestFTLQuote_requestFTLQuote } from '../graphql/quotes/__generated__/RequestFTLQuote';

export const SampleRawLTLQuote: LtlQuote[] = [
  {
    __typename: 'LtlQuote',
    guid: '36348e94-2ca3-4486-85c0-d6cd24b8dd2b',
    transitTime: 5,
    cost: 336.86,
    baseRate: 336.86,
    deliveryDate: '2023-03-20',
    shipmentType: 'LTL',
    ltlAccessorialCharges: [],
    carrier: {
      __typename: 'QuoteCarrier',
      id: 0,
      name: 'R AND L CARRIERS',
      newLiability: 200,
      usedLiability: 10,
      reliabilityScore: 98,
    },
    proposal: {
      __typename: 'Proposal',
      guid: '36348e94-2ca3-4486-85c0-d6cd24b8dd2b',
      pricingResult: {
        __typename: 'PricingResult',
        carrierOrgId: 0,
        profileId: 7728,
        carrierName: 'R AND L CARRIERS',
        scac: 'RLCA',
        pricingType: 'BLANKET',
        serviceType: 'DIRECT',
        newProdLiability: 200,
        usedProdLiability: 10,
        transitTime: 5,
        totalMiles: 1480,
        currencyCode: 'USD',
        carrierFinalLinehaul: 162.6,
        carrierFuelSurcharge: 59.84,
        shipperFinalLinehaul: 235.65,
        shipperFuelSurcharge: 86.72,
        totalShipperCost: 336.86,
        accessorials: [
          {
            __typename: 'QuoteAccessorial',
            accessorialType: 'HZC',
            accessorialDescription: 'Hazmat Fee',
            carrierAccessorialCost: 10,
            applyCarrierCostBeforeFuel: false,
            shipperAccessorialCost: 14.49,
            applyShipperCostBeforeFuel: false,
            benchmarkAccessorialCost: 14.49,
            applyBenchmarkCostBeforeFuel: false,
          },
          {
            __typename: 'QuoteAccessorial',
            accessorialType: 'ODM',
            accessorialDescription: 'ODM - unitemized',
            carrierAccessorialCost: 0,
            applyCarrierCostBeforeFuel: false,
            shipperAccessorialCost: 0,
            applyShipperCostBeforeFuel: false,
            benchmarkAccessorialCost: 0,
            applyBenchmarkCostBeforeFuel: false,
          },
        ],
        carrierQuoteNumber: '17581063',
      },
    },
  },
];

export const sampleRawRFQ: RawLtlRFQ = {
  abandonCartUrl: false,
  errorOccured: false,
  errorMsg: '',
  origin: {
    userSelectedLiftgate: false,
    zip: {
      country: { name: '', dialingCode: '', id: 'USA' },
      zip: '94111',
      city: 'San Francisco',
      state: 'CA',
      prefCity: 'San Francisco',
      timeZone: '-8',
      lat: 37.79593620000001,
      lng: -122.4000032,
    },
    country: 'USA',
    locationType: 'LT2',
  },
  destination: {
    userSelectedLiftgate: false,
    zip: {
      country: { name: '', dialingCode: '', id: 'USA' },
      prefCity: 'Mammoth',
      timeZone: '-7',
      zip: '82190',
      city: 'Mammoth',
      state: 'WY',
      lat: 44.4620852,
      lng: -110.6424411,
    },
    country: 'USA',
    locationType: 'LT2',
  },
  quote: {
    quoteDate: '2021-04-07T15:26:41.150Z',
    deliveryDate: '2021-04-12T15:26:41.150Z',
  },
  customs: { broker: {}, vendor: {}, purchaser: {} },
  insurance: {},
  uom: { size: 'in', weight: 'lbs' },
  additionalServices: [],
  pickupDate: '2021-04-21',
  items: [
    {
      value: 0,
      itemCondition: 'NEW',
      dim_size: 'in',
      dim_weight: 'lbs',
      quantity: 1,
      productPackage: 'PLT',
      length: 44,
      width: 44,
      height: 10,
      weight: 111,
      totalWeight: 111,
      productClass: 'CLASS_100',
    },
  ],
  guid: '907e1e05-aac5-4517-e520-35bf90a66fbe',
  wizardStep: 2,
  wizardType: 'LTL',
  selectedTruckType: null,
  dim_weight: 'lbs',
  dim_size: 'in',
  transCountry: false,
  orderComplete: false,
  loadServiceTypeIds: [],
  termsOfSale: '',
  version: 1,
  equipmentTemperature: null,
  requester: 'goshipui',
  wizardDataId: null,
  quotes: [],
  isThirdPartyRedirect: true,
  orderDate: 1617809201229,
  source: 'CUTTLEFISH',
  ltlLifecycleTimeoutSeconds: 5,
};

export const SampleLTLRFQ: LTL_RFQ = {
  shipmentType: 'LTL',
  origin: {
    address: {
      postalCode: '94110',
      city: 'San Francisco',
      state: 'CA',
      country: 'USA',
      timeOffset: -8,
      lat: 37.7485824,
      lng: -122.4184108,
    },
    type: 'BUSINESS_W_DOCK',
    accessorials: {},
    date: dayjs().add(1, 'day').toDate(),
  },
  destination: {
    address: {
      postalCode: '84110',
      city: 'Salt Lake City',
      state: 'UT',
      country: 'USA',
      timeOffset: -7,
      lat: 40.7622779,
      lng: -111.8925217,
    },
    type: 'BUSINESS_W_DOCK',
    accessorials: {},
    date: dayjs().add(2, 'day').toDate(),
  },
  commonAccessorials: {},
  items: [
    {
      quantity: 1,
      packaging: 'PAL',
      sizeUoM: 'in',
      length: 55,
      width: 44,
      height: 30,
      weightUoM: 'lbs',
      weight: 150,
      freightClass: 'CLASS_100',
      value: 1000,
      itemCondition: 'NEW',
      stackable: false,
      hazardous: false,
      hazmatDetails: BlankHazMatDetails,
      description: '',
      tariff: '',
      countryOfOrigin: '',
    },
  ],
};

export const SampleRawFTLQuote: RequestFTLQuote_requestFTLQuote[] = [
  {
    cost: 8181.25,
    quoteDate: '2021-04-14',
    deliveryDate: '2021-04-22',
    guid: '0cb54683-c131-4257-80c4-9d34fac6cf9f',
    baseRate: 8181.25,
    shipmentType: 'FTL',
    truckType: Truck_Type.Van,
    transitTime: 1,
    __typename: 'FtlQuote',
    ftlAccessorialCharges: [],
  },
  {
    cost: 8181.25,
    quoteDate: '2021-04-14',
    deliveryDate: '2021-04-22',
    guid: '0cb54683-c131-4257-80c4-9d34fac6cf9f',
    baseRate: 8181.25,
    shipmentType: 'FTL',
    truckType: Truck_Type.Van,
    transitTime: 1,
    __typename: 'FtlQuote',
    ftlAccessorialCharges: [],
  },
  {
    cost: 8181.25,
    quoteDate: '2021-04-14',
    deliveryDate: '2021-04-22',
    guid: '0cb54683-c131-4257-80c4-9d34fac6cf9f',
    baseRate: 8181.25,
    shipmentType: 'FTL',
    truckType: Truck_Type.Van,
    transitTime: 1,
    __typename: 'FtlQuote',
    ftlAccessorialCharges: [],
  },
];

export const SampleFTLRFQ: FTL_RFQ = {
  shipmentType: 'FTL',
  truckType: 'flatbed',
  origin: {
    address: {
      postalCode: '94110',
      city: 'San Francisco',
      state: 'CA',
      country: 'USA',
      timeOffset: -8,
      lat: 37.7485824,
      lng: -122.4184108,
    },
    type: 'BUSINESS_W_DOCK',
    accessorials: {},
    date: dayjs().add(1, 'day').toDate(),
  },
  destination: {
    address: {
      postalCode: '84110',
      city: 'Salt Lake City',
      state: 'UT',
      country: 'USA',
      timeOffset: -5,
      lat: 40.762277,
      lng: -111.8925217,
    },
    type: 'BUSINESS_W_DOCK',
    accessorials: {},
    date: dayjs().add(2, 'day').toDate(),
  },
  commonAccessorials: {},
  items: [
    {
      quantity: 1,
      packaging: 'PAL',
      sizeUoM: 'in',
      length: 55,
      width: 44,
      height: 30,
      weightUoM: 'lbs',
      weight: 150,
      freightClass: 'CLASS_100',
      value: 1000,
      itemCondition: 'NEW',
      stackable: false,
      hazardous: false,
      hazmatDetails: BlankHazMatDetails,
      description: '',
      tariff: '',
      countryOfOrigin: '',
    },
  ],
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ep1: FullEndPoint = {
  address: {
    firstName: 'Harry',
    lastName: 'Truman',
    phone: '(415)555-8403',
    email: 'hoo@mail.mx',
    companyName: 'Truman Haberdashery',
    streetAddress: '700 Haight St.',
    streetAddress1: '',
    city: 'San Francisco',
    state: 'CA',
    country: 'USA',
    postalCode: '94117',
    timeOffset: -8,
    lat: 37.7717185,
    lng: -122.4438929,
  },
  instructions: '',
  type: 'AIRPORT',
  accessorials: {},
  date: new Date(),
  startTime: dayjs().add(7, 'day').hour(8).minute(30).toDate(),
  endTime: dayjs().add(7, 'day').hour(16).minute(30).toDate(),
  saveToAddressBook: true,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ep2: FullEndPoint = {
  address: {
    firstName: 'Pat',
    lastName: 'Nixon',
    phone: '(212)555-8403',
    email: 'hoo@mail.mx',
    companyName: 'Truman Haberdashery',
    streetAddress: '2 Main St.',
    streetAddress1: '',
    city: 'New York',
    state: 'NY',
    country: 'USA',
    postalCode: '10001',
    timeOffset: -5,
    lat: 40.75368539999999,
    lng: -73.9991637,
  },
  instructions: '',
  date: new Date(),
  type: 'AIRPORT',
  accessorials: {},
  startTime: dayjs().add(7, 'day').hour(8).minute(30).toDate(),
  endTime: dayjs().add(7, 'day').hour(16).minute(30).toDate(),
  saveToAddressBook: true,
};
type GenBooking = {
  id: string;
  transitTime: number;
  deliveryDate: string;
  pickupDate: string;
  cost: number;
  origin_contactName: string;
  origin_contactPhone: string;
  origin_contactEmail: string;
  origin_name: string;
  origin_streetAddress: string;
  origin_city: string;
  origin_state: string;
  origin_postalCode: string;
  origin_instructions: string;
  destination_contactName: string;
  destination_contactPhone: string;
  destination_contactEmail: string;
  destination_name: string;
  destination_streetAddress: string;
  destination_city: string;
  destination_state: string;
  destination_postalCode: string;
  destination_instructions: string;
  bol: string;
  qid: string;
};

type GenRawBookingSummary = {
  bol: string;
  origin_city: string;
  origin_state: string;
  destination_city: string;
  destination_state: string;
  pickupDate: string;
  deliveryDate: string;
  totalCost: number;
  carrierId: number;
};

const convertFromGeneratedBookingSummary = ({
  bol,
  origin_city,
  origin_state,
  destination_city,
  destination_state,
  pickupDate,
  deliveryDate,
  totalCost,
  carrierId,
}: GenRawBookingSummary): Booking =>
  convertRawBooking({
    bol,
    loadStatus: { loadStatus: 'In-transit' },
    origin: `${origin_city}, ${origin_state}`,
    destination: `${destination_city}, ${destination_state}`,
    pickupDate,
    deliveryDate,
    totalCost,
    status: '',
    carrierName: '',
    carrierId,
  });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const convertFromGeneratedBooking = (g: GenBooking) => ({
  id: g.id,
  bookingStatus: 'in-transit',
  bookingRequest: {
    items: [],
    quote: {
      shipmentType: 'FTL',
      truckType: 'van',
      guid: g.id,
      transitTime: g.transitTime,
      deliveryDate: new Date(g.deliveryDate),
      pickupDate: new Date(g.pickupDate),
      cost: g.cost,
    },
    origin: {
      contactName: g.origin_contactName,
      contactPhone: g.origin_contactPhone,
      contactEmail: g.origin_contactEmail,
      address: {
        name: g.origin_name,
        streetAddress: g.origin_streetAddress,
        streetAddress1: '',
        city: g.origin_city,
        state: g.origin_state,
        country: 'USA',
        postalCode: g.origin_postalCode,
      },
      instructions: g.origin_instructions,
      startTime: dayjs().add(7, 'day').hour(8).minute(30).toDate(),
      endTime: dayjs().add(7, 'day').hour(16).minute(30).toDate(),
    },
    destination: {
      contactName: g.destination_contactName,
      contactPhone: g.destination_contactPhone,
      contactEmail: g.destination_contactEmail,
      address: {
        name: g.destination_name,
        streetAddress: g.destination_streetAddress,
        streetAddress1: '',
        city: g.destination_city,
        state: g.destination_state,
        country: 'USA',
        postalCode: g.destination_postalCode,
      },
      instructions: g.destination_instructions,
      startTime: dayjs().add(7, 'day').hour(8).minute(30).toDate(),
      endTime: dayjs().add(7, 'day').hour(16).minute(30).toDate(),
    },
    bookingDetails: {
      insuredValue: 0,
      promoCode: 'GOSHIPFTL',
    },
  },
  bol: g.bol,
});

export const SampleBookings: Booking[] = [
  {
    bol: '63845',
    origin_city: 'Denver',
    origin_state: 'CO',
    destination_city: 'El Paso',
    destination_state: 'TX',
    pickupDate: '2021-02-08',
    deliveryDate: '2020-06-04',
    totalCost: 338.32,
    carrierId: 198680,
  },
  {
    bol: '52747',
    origin_city: 'Fullerton',
    origin_state: 'CA',
    destination_city: 'Helena',
    destination_state: 'MT',
    pickupDate: '2020-07-31',
    deliveryDate: '2020-06-29',
    totalCost: 791.3,
    carrierId: 33609,
  },
  {
    bol: '7073',
    origin_city: 'Mobile',
    origin_state: 'AL',
    destination_city: 'Bronx',
    destination_state: 'NY',
    pickupDate: '2021-01-06',
    deliveryDate: '2021-02-19',
    totalCost: 547.7,
    carrierId: 33609,
  },
  {
    bol: '60',
    origin_city: 'Pueblo',
    origin_state: 'CO',
    destination_city: 'Indianapolis',
    destination_state: 'IN',
    pickupDate: '2020-05-28',
    deliveryDate: '2020-07-18',
    totalCost: 160.94,
    carrierId: 18142,
  },
  {
    bol: '7',
    origin_city: 'Bonita Springs',
    origin_state: 'FL',
    destination_city: 'Durham',
    destination_state: 'NC',
    pickupDate: '2021-01-07',
    deliveryDate: '2021-03-13',
    totalCost: 829.13,
    carrierId: 214939,
  },
  {
    bol: '03',
    origin_city: 'Fresno',
    origin_state: 'CA',
    destination_city: 'Lubbock',
    destination_state: 'TX',
    pickupDate: '2020-08-29',
    deliveryDate: '2020-05-16',
    totalCost: 435.32,
    carrierId: 33609,
  },
  {
    bol: '89265',
    origin_city: 'Mobile',
    origin_state: 'AL',
    destination_city: 'Saint Petersburg',
    destination_state: 'FL',
    pickupDate: '2020-09-03',
    deliveryDate: '2020-06-07',
    totalCost: 764.76,
    carrierId: 33609,
  },
  {
    bol: '79',
    origin_city: 'Hyattsville',
    origin_state: 'MD',
    destination_city: 'San Diego',
    destination_state: 'CA',
    pickupDate: '2020-08-14',
    deliveryDate: '2021-04-14',
    totalCost: 493.93,
    carrierId: 33609,
  },
  {
    bol: '68',
    origin_city: 'Scranton',
    origin_state: 'PA',
    destination_city: 'Chicago',
    destination_state: 'IL',
    pickupDate: '2020-07-29',
    deliveryDate: '2020-05-20',
    totalCost: 930.21,
    carrierId: 18142,
  },
  {
    bol: '815',
    origin_city: 'Jacksonville',
    origin_state: 'FL',
    destination_city: 'Salt Lake City',
    destination_state: 'UT',
    pickupDate: '2020-07-24',
    deliveryDate: '2021-01-10',
    totalCost: 540.72,
    carrierId: 18142,
  },
].map(convertFromGeneratedBookingSummary);

export const SampleZipAddress: TimeAddress[] = [
  {
    city: 'San Jose',
    state: 'CA',
    postalCode: '95108',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Rocky Mountain House',
    state: 'AB',
    postalCode: 'T4T',
    country: 'CAN',
    timeOffset: -6,
    lat: 0,
    lng: 0,
  },
  {
    city: 'San Rafael',
    state: 'CA',
    postalCode: '94913',
    country: 'USA',
    timeOffset: -7,
    lat: 38.02,
    lng: -122.55,
  },
  {
    city: 'New York City',
    state: 'NY',
    postalCode: '10060',
    country: 'USA',
    timeOffset: -5,
    lat: 40.75,
    lng: -73.99,
  },
  {
    city: 'San Jose',
    state: 'CA',
    postalCode: '95160',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Claresholm',
    state: 'AB',
    postalCode: 'V2T',
    country: 'CAN',
    timeOffset: -6,
    lat: 0,
    lng: 0,
  },
  {
    city: 'Sacramento',
    state: 'CA',
    postalCode: '95894',
    country: 'USA',
    timeOffset: -7,
    lat: 38.5574091,
    lng: -121.4848927,
  },
  {
    city: 'Nanton',
    state: 'AB',
    postalCode: 'L5M',
    country: 'CAN',
    timeOffset: -6,
    lat: 0,
    lng: 0,
  },
  {
    city: 'Jamaica',
    state: 'NY',
    postalCode: '11480',
    country: 'USA',
    timeOffset: -5,
    lat: 0,
    lng: 0,
  },
  {
    city: 'San Jose',
    state: 'CA',
    postalCode: '95160',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Fox Creek',
    state: 'AB',
    postalCode: 'E4B',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Lacombe',
    state: 'AB',
    postalCode: 'T4L',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Fullerton',
    state: 'CA',
    postalCode: '92640',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Pasadena',
    state: 'CA',
    postalCode: '91186',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Sacramento',
    state: 'CA',
    postalCode: '94297',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Bronx',
    state: 'NY',
    postalCode: '10459',
    country: 'USA',
    timeOffset: -5,
    lat: 40.8285039,
    lng: -73.8918897,
  },
  {
    city: 'San Diego',
    state: 'CA',
    postalCode: '92165',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'New York City',
    state: 'NY',
    postalCode: '10155',
    country: 'USA',
    timeOffset: -5,
    lat: 40.8285039,
    lng: -73.8918897,
  },
  {
    city: 'San Rafael',
    state: 'CA',
    postalCode: '94913',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Oceanside',
    state: 'CA',
    postalCode: '92056',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Canmore',
    state: 'AB',
    postalCode: 'T1W',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Taber',
    state: 'AB',
    postalCode: 'T1G',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'San Jose',
    state: 'CA',
    postalCode: '95108',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Philadelphia',
    state: 'PA',
    postalCode: '19191',
    country: 'USA',
    timeOffset: -5,
    lat: 40.8285039,
    lng: -73.8918897,
  },
  {
    city: 'Santa Rosa',
    state: 'CA',
    postalCode: '95405',
    country: 'USA',
    timeOffset: -7,
    lat: 38.43922999999999,
    lng: -122.6673018,
  },
  {
    city: 'Devon',
    state: 'AB',
    postalCode: 'T9G',
    country: 'CAN',
    timeOffset: -6,
    lat: 40.8285039,
    lng: -73.8918897,
  },
  {
    city: 'Long Beach',
    state: 'CA',
    postalCode: '90805',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Sacramento',
    state: 'CA',
    postalCode: '94207',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Taber',
    state: 'AB',
    postalCode: 'T1G',
    country: 'CAN',
    timeOffset: -6,
    lat: 0,
    lng: 0,
  },
  {
    city: 'Canmore',
    state: 'AB',
    postalCode: 'T1W',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Oceanside',
    state: 'CA',
    postalCode: '92056',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Philadelphia',
    state: 'PA',
    postalCode: '19146',
    country: 'USA',
    timeOffset: -5,
    lat: 39.9396284,
    lng: -75.18663959999999,
  },
  {
    city: 'Fresno',
    state: 'CA',
    postalCode: '93715',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Blackfalds',
    state: 'AB',
    postalCode: 'J5K',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Syracuse',
    state: 'NY',
    postalCode: '13205',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Elmira',
    state: 'NY',
    postalCode: '14905',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Lethbridge',
    state: 'AB',
    postalCode: 'T1K',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Sacramento',
    state: 'CA',
    postalCode: '95894',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Simi Valley',
    state: 'CA',
    postalCode: '93094',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'San Francisco',
    state: 'CA',
    postalCode: '94159',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Drayton Valley',
    state: 'AB',
    postalCode: 'T7A',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Albany',
    state: 'NY',
    postalCode: '12210',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'High River',
    state: 'AB',
    postalCode: 'T1V',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Philadelphia',
    state: 'PA',
    postalCode: '19172',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Port Washington',
    state: 'NY',
    postalCode: '11054',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Oakland',
    state: 'CA',
    postalCode: '94627',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Lacombe',
    state: 'AB',
    postalCode: 'T4L',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Pincher Creek',
    state: 'AB',
    postalCode: 'J0J',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'New York City',
    state: 'NY',
    postalCode: '10160',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Killam',
    state: 'AB',
    postalCode: 'A0A',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'San Bernardino',
    state: 'CA',
    postalCode: '92415',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Blackfalds',
    state: 'AB',
    postalCode: 'J5K',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Harrisburg',
    state: 'PA',
    postalCode: '17126',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Lancaster',
    state: 'PA',
    postalCode: '17622',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Fullerton',
    state: 'CA',
    postalCode: '92835',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Long Beach',
    state: 'CA',
    postalCode: '90805',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Palmdale',
    state: 'CA',
    postalCode: '93591',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Flushing',
    state: 'NY',
    postalCode: '11388',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Manning',
    state: 'AB',
    postalCode: 'V3A',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Oakland',
    state: 'CA',
    postalCode: '94611',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Stony Plain',
    state: 'AB',
    postalCode: 'T7Z',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Leduc',
    state: 'AB',
    postalCode: 'T9E',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'New York City',
    state: 'NY',
    postalCode: '10170',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Grande Prairie',
    state: 'AB',
    postalCode: 'R3J',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Albany',
    state: 'NY',
    postalCode: '12210',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Buffalo',
    state: 'NY',
    postalCode: '14205',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Philadelphia',
    state: 'PA',
    postalCode: '19120',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Garden Grove',
    state: 'CA',
    postalCode: '92645',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Jamaica',
    state: 'NY',
    postalCode: '11480',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Philadelphia',
    state: 'PA',
    postalCode: '19141',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Devon',
    state: 'AB',
    postalCode: 'T9G',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Los Angeles',
    state: 'CA',
    postalCode: '90030',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Sacramento',
    state: 'CA',
    postalCode: '94273',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Garden Grove',
    state: 'CA',
    postalCode: '92844',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Fresno',
    state: 'CA',
    postalCode: '93715',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'San Diego',
    state: 'CA',
    postalCode: '92165',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Oceanside',
    state: 'CA',
    postalCode: '92056',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Jasper Park Lodge',
    state: 'AB',
    postalCode: 'H3R',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Pasadena',
    state: 'CA',
    postalCode: '91199',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Harrisburg',
    state: 'PA',
    postalCode: '17126',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Grimshaw',
    state: 'AB',
    postalCode: 'N4W',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'San Jose',
    state: 'CA',
    postalCode: '95138',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Albany',
    state: 'NY',
    postalCode: '12210',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Harrisburg',
    state: 'PA',
    postalCode: '17110',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Los Angeles',
    state: 'CA',
    postalCode: '90005',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Rocky Mountain House',
    state: 'AB',
    postalCode: 'T4T',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Fullerton',
    state: 'CA',
    postalCode: '92640',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Flushing',
    state: 'NY',
    postalCode: '11388',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Harrisburg',
    state: 'PA',
    postalCode: '17121',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Spirit River',
    state: 'AB',
    postalCode: 'V2G',
    country: 'CAN',
    timeOffset: -6,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'New York City',
    state: 'NY',
    postalCode: '10009',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Philadelphia',
    state: 'PA',
    postalCode: '19178',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Harrisburg',
    state: 'PA',
    postalCode: '17110',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Rochester',
    state: 'NY',
    postalCode: '14604',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Corona',
    state: 'CA',
    postalCode: '92883',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Van Nuys',
    state: 'CA',
    postalCode: '91406',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Sacramento',
    state: 'CA',
    postalCode: '95833',
    country: 'USA',
    timeOffset: -7,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Erie',
    state: 'PA',
    postalCode: '16534',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Albany',
    state: 'NY',
    postalCode: '12205',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
  {
    city: 'Great Neck',
    state: 'NY',
    postalCode: '11024',
    country: 'USA',
    timeOffset: -5,
    lat: 37.34,
    lng: -121.89,
  },
];

export const SampleRawLoadDetailsPayload: RawLoadDetails = {
  bol: '14036758',
  carrierName: 'YRC FREIGHT',
  deliveryDate: '2021-05-14',
  deliveryEnd: '2021-05-14 16:00',
  deliveryStart: '2021-05-14 08:00',
  destContactEmail: 'john@doe.com',
  destContactName: 'John Doe',
  destContactPhone: '+1 (555) 555-5555',
  destCountry: 'USA',
  destination: '3120 UNIONVILLE RD CRANBERRY TWP, PA 94111',
  insurancePremium: 87.5,
  insuranceSumInsured: '25,000.00',
  insurancePolicyNumber: 'FAMAS-64',
  loadId: 14036758,
  loadNotes: '  ',
  loadStatus: 'Dispatched',
  orderDate: '2021-04-30',
  origin: '3120 UNIONVILLE RD CRANBERRY TWP, PA 16066',
  originCountry: 'USA',
  pickupContactEmail: 'john@doe.com',
  pickupContactName: 'John Doe',
  pickupContactPhone: '+1 (555) 555-5555',
  pickupDate: '2021-05-09',
  pickupEnd: '2021-05-09 16:00',
  pickupStart: '2021-05-09 08:00',
  status: 'PP',
  totalPaid: 1214.57,
  totalValue: 2555,
  totalWeight: 2500,
  trackingDates: {},
  transCountry: false,
};
