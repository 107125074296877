import { TimeAddress } from '@/utils/types';

import {
  AddressLookupError,
  TimezoneLookupError,
  UtilityContext,
} from '../../utils/types/UtilityContext';
const useTimezoneByZip = ({
  state,
}: TimeAddress): {
  error: TimezoneLookupError | null;
  loading: boolean;
  offset: number;
} => ({ error: null, loading: false, offset: state === 'NY' ? 5 : 8 });

const useAddressLookup = (
  s: string,
): {
  error: AddressLookupError | null;
  loading: boolean;
  addresses: TimeAddress[];
} => ({
  error: null,
  loading: false,
  addresses: [
    s === 'CA'
      ? {
          postalCode: '94117',
          city: 'San Francisco',
          state: 'CA',
          country: 'USA',
          lat: 37.7717185,
          lng: -122.4438929,
          timeOffset: -8,
        }
      : {
          postalCode: '02134',
          city: 'Boston',
          state: 'MA',
          country: 'USA',
          lat: 42.3593008,
          lng: -71.1270268,
          timeOffset: -5,
        },
  ],
});
const MockUtilityContext: UtilityContext = {
  useTimezoneByZip,
  useAddressLookup,
};

export default MockUtilityContext;
