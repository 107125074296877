import { useBoolean } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import { IpLocation } from '@/backend/BackendTypes';
import graphqlClient from '@/backend/graphql';
import { countries } from '@/components/booking/AllCountrySelect';
import {
  clearTokens,
  getIPLocation,
  retrieveAccessToken,
  saveAccessToken,
  saveIPLocation,
  saveRefreshToken,
} from '@/utils/storage';
import { DraftUser, NetworkError, AuthUser, User } from '@/utils/types';
import {
  SignInError,
  SignUpError,
  UserContext,
  GetUserError,
  ResetPasswordError,
} from '@/utils/types/UserContext';

import { User as ViewerUser } from './../types';
import saveFailedTransaction from './graphql/transaction/saveFailedTransaction';
import changePasswordGQL from './graphql/user/changePassword';
import getUser from './graphql/user/getUser';
import forgotPasswordGQL from './graphqlpublic/user/forgotPassword';
import signInGql from './graphqlpublic/user/signIn';
import signUpGql from './graphqlpublic/user/signUp';
import updatePasswordGQL from './graphqlpublic/user/updatePassword';
import { getLatestAccessToken } from './Tokens';
import 'isomorphic-fetch';

const getIpLocation = async () => {
  const resp = await fetch('/api/ip-location', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await resp.json();
};

const validateErrorCode = (errorCode: string) => {
  if (
    errorCode === 'incorrect_cvc' ||
    errorCode === 'generic_decline' ||
    errorCode === 'incorrect_zip' ||
    errorCode === 'failed_address_line1_check' ||
    errorCode === 'failed_address_zip_check' ||
    errorCode === 'stolen_card' ||
    errorCode === 'invalid_expiry_year' ||
    errorCode === 'invalid_expiry_month' ||
    errorCode === 'fraudulent' ||
    errorCode === 'lost_card' ||
    errorCode === 'card_declined' ||
    errorCode === 'non_us_ip' ||
    errorCode === 'stolen_card'
  )
    return true;
  else {
    return false;
  }
};

const useBackendUserContext = (backend: string): UserContext => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [loading, { on: setLoading, off: clearLoading }] = useBoolean();
  const [initialized, setInitialized] = useState(false);
  const [featureFlags, setFeatureFlags] = useState<Record<string, unknown>>({});
  const [isUserActive, setUserActive] = useState(true);
  const [vendor, setVendor] = useState<boolean>();
  const [userId, setUserId] = useState<string>();
  const [fullName, setFullName] = useState<string>('');
  const [organizationName, setOrganizationName] = useState<string>('');
  const [userViewer, setUserViewer] = useState<ViewerUser>();

  const loadingBlock = useCallback(
    async <T>(f: () => Promise<T>): Promise<T | NetworkError> => {
      setLoading();
      try {
        const rv = await f();
        clearLoading();
        return rv;
      } catch (e) {
        clearLoading();
        console.error(e);
        return 'NetworkError';
      }
    },
    [clearLoading, setLoading],
  );

  const checkUser = useCallback(async (): Promise<boolean> => {
    const url = `${backend}/check/user`;
    const accessToken = await getLatestAccessToken();
    const resp = await fetch(url, {
      mode: 'cors',
      cache: 'no-cache',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const { payload } = await resp.json();
    return payload;
  }, [backend]);

  const fetchUser = useCallback(async () => {
    if (!backend) {
      return;
    }
    const accessToken = await getLatestAccessToken();
    if (!accessToken) {
      setUser(null);
      return;
    }
    const url = `${backend}/auth/current_user`;
    const resp = await fetch(url, {
      mode: 'cors',
      cache: 'no-cache',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const u: AuthUser = await resp.json();

    setUser(u);

    setUserActive(await checkUser());

    const { user } = await getUser();

    if (user) {
      setUserViewer(user);
    }
    const featureFlags = user?.featureFlags || {};
    const vendor = user?.vendor || false;
    const userId = user?.id || null;

    setFullName(user?.fullName || '');
    setOrganizationName(user?.organizationName || '');
    setFeatureFlags(featureFlags);
    setVendor(vendor);
    setUserId(userId);
  }, [backend, checkUser]);

  useEffect(() => {
    loadingBlock(fetchUser);
    setInitialized(true);
  }, [backend, fetchUser, loadingBlock]);

  const unimplemented = () => {
    throw new Error('not implemented');
  };

  const doSignin = async (
    email: string,
    password: string,
  ): Promise<SignInError | null> => {
    const { error, accessToken, refreshToken } = await signInGql({
      email: email.toLocaleLowerCase(),
      password,
    });

    if (error) {
      return error;
    }

    saveAccessToken(accessToken);
    saveRefreshToken(refreshToken);

    graphqlClient.resetStore();
    fetchUser();

    return null;
  };

  const signIn = async (
    email: string,
    password: string,
  ): Promise<SignInError | null> =>
    loadingBlock(() => doSignin(email, password));

  const signUp = async (
    user: DraftUser,
    referralCode?: string,
  ): Promise<SignUpError | null> =>
    loadingBlock(
      async (): Promise<SignUpError | null> => {
        const { error, accessToken, refreshToken } = await signUpGql(
          user,
          referralCode,
        );

        if (error) {
          return error;
        }

        // Sign in
        saveAccessToken(accessToken);
        saveRefreshToken(refreshToken);

        graphqlClient.resetStore();
        await fetchUser();
        return null;
      },
    );

  const signOut = async () => {
    setUser(null);
    graphqlClient.resetStore();
    clearTokens();
  };

  const changePassword = async (
    oldPassword: string,
    newPassword: string,
  ): Promise<boolean> => {
    const { data } = await changePasswordGQL(oldPassword, newPassword);
    return data.success;
  };

  const updatePassword = async (
    uuid: string,
    password: string,
  ): Promise<boolean | string> => {
    const { data } = await updatePasswordGQL(uuid, password);
    if (!data.success) {
      return data.error;
    }
    return data.success;
  };

  const resendVerificationEmail = async (): Promise<boolean> => {
    const url = `${backend}/resend/verification-email`;
    const accessToken = retrieveAccessToken();
    const resp = await fetch(url, {
      mode: 'cors',
      cache: 'no-cache',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return resp.ok;
  };

  const recordPaymentAttempt = async (
    errorCode: string,
    id: string,
    amount: number,
  ): Promise<boolean> => {
    if (!validateErrorCode(errorCode)) return false;
    if (getIPLocation() === '') {
      await getIp();
    }
    const ipLocation = JSON.parse(getIPLocation());
    const filteredCountry = countries.filter(
      country => country.alpha2 === ipLocation.country,
    );
    let country = 'USA';
    if (filteredCountry.length) {
      country = filteredCountry[0].alpha3;
    } else {
      country = 'IND';
    }
    const { data } = await saveFailedTransaction(
      amount,
      errorCode,
      ipLocation.city,
      ipLocation.state,
      country,
      id,
      ipLocation.ip,
    );

    if (data && data.blocked) {
      return true;
    } else {
      return false;
    }
  };

  const getIp = async (): Promise<IpLocation> => {
    const ipLocation = await getIpLocation();
    saveIPLocation(JSON.stringify(ipLocation));
    return ipLocation;
  };

  const useGetUser = (): {
    error: GetUserError | null;
    loading: boolean;
    user: User | null;
  } => {
    const [loading, { on: setLoading, off: clearLoading }] = useBoolean();
    const [error, setError] = useState<NetworkError | null>(null);
    // TODO Clean up scoping / naming mess in this file so `user` is not set in outside scope
    // (Should be `authUser`)
    const [fullUser, setFullUser] = useState<User | null>(null);

    useEffect(() => {
      const fetchUser = async () => {
        const url = `${backend}/getUser`;

        setLoading();

        try {
          const accessToken = await getLatestAccessToken();
          const resp = await fetch(url, {
            mode: 'cors',
            cache: 'no-cache',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const jsonResp = await resp.json();

          if (!resp.ok) {
            throw new Error(`${resp.status} ${resp.statusText}`);
          }

          setFullUser(jsonResp.payload || null);
        } catch (e) {
          console.error('Error in useGetUser', e);
          setFullUser(null);
          setError('NetworkError');
        } finally {
          clearLoading();
        }
      };

      if (user && initialized) {
        fetchUser();
      }
      // TODO Load user and initialized in scope via context rather than relying
      // on outside scope. *Hint* Do not remove user and initialized here
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, initialized, setLoading, clearLoading]);

    return {
      error,
      loading,
      user: fullUser,
    };
  };

  const useResetPassword = () => {
    const [loading, { on: setLoading, off: clearLoading }] = useBoolean();
    const [reset, { on: setReset }] = useBoolean();
    const [error, setError] = useState<ResetPasswordError | null>(null);
    const resetPassword = useCallback(
      async (email: string) => {
        try {
          setLoading();
          setError(null);
          const { data } = await forgotPasswordGQL(email);
          if (data.success) {
            setReset();
            return null;
          } else {
            setError('NetworkError');
            return error;
          }
        } finally {
          clearLoading();
        }
      },
      [clearLoading, setLoading, setReset],
    );

    return {
      error,
      loading,
      reset,
      resetPassword,
    };
  };

  const useGetUserStatus = () => {
    const accessToken = retrieveAccessToken();
    if (!accessToken) {
      setUserActive(true);
    }
    return isUserActive;
  };

  return {
    user,
    initialized,
    loading,
    signIn,
    signUp,
    updateUser: unimplemented,
    signOut,
    changePassword,
    checkUser,
    recordPaymentAttempt,
    getIp,
    useGetUser,
    useResetPassword,
    featureFlags,
    vendor,
    userId,
    resendVerificationEmail,
    useGetUserStatus,
    fullName,
    organizationName,
    updatePassword,
    userViewer,
  };
};
export default useBackendUserContext;
