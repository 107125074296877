import { gql, useMutation } from '@apollo/client';

import { BookingLog } from '@/utils/types';
import { Mutation } from 'types';

const CREATE_BOOKING_LOG = gql`
  mutation CreateBookingLog(
    $originZip: String!
    $destinationZip: String!
    $amount: Float!
    $pickupDate: Date!
    $rfq: JSON
    $quotes: JSON
    $selectedQuote: JSON
    $quoteLogUuid: String
  ) {
    createBookingLog(
      input: {
        amount: $amount
        originZip: $originZip
        destinationZip: $destinationZip
        pickupDate: $pickupDate
        rfq: $rfq
        quotes: $quotes
        selectedQuote: $selectedQuote
        quoteLogUuid: $quoteLogUuid
      }
    ) {
      bookingLog {
        id
      }
      error
    }
  }
`;

export default function useSaveBookingLogGQL() {
  const [saveBookingLogQL, { data, error, loading }] = useMutation<Mutation>(
    CREATE_BOOKING_LOG,
  );
  const saveBookingLog = async (bookingLog: BookingLog) => {
    const { data } = await saveBookingLogQL({
      variables: bookingLog,
    });
    return data ? data.createBookingLog : null;
  };
  return [saveBookingLog, { data, error, loading }] as const;
}
