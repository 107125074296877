import { FormControl, Input, InputProps } from '@chakra-ui/react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { get } from 'lodash';

import { formErrorText } from '@/styles/Colors';
import { useSubmitOnEnter } from '@/utils/hooks/useSubmitOnEnter';

import LabelText from './booking/LabelText';
import { HelpPopup } from './HelpPopup';

export type ValidatedFieldProps = {
  name: string;
  children?: React.FC<FieldProps>;
  label?: string;
  sublabel?: string;
  placeholder?: string;
  annotation?: string;
  help?: JSX.Element;
  errorFields?: string[];
  disabled?: boolean;
} & InputProps;
export default function ValidatedField({
  label,
  sublabel,
  name,
  children,
  annotation,
  help,
  errorFields,
  disabled = false,
  ...props
}: ValidatedFieldProps) {
  const { errors, touched, submitCount } = useFormikContext();
  const onKeyPress = useSubmitOnEnter();
  const fieldErrorObject = get(errors, name);
  const fieldErrors = errorFields
    ? errorFields
        .map(errorField => get(fieldErrorObject, errorField))
        .filter(Boolean)
        .join(',')
    : (fieldErrorObject as string);

  const isInvalid = !!(fieldErrors && (get(touched, name) || submitCount > 0));

  return (
    <Field name={name}>
      {(fieldArgs: FieldProps) => (
        <FormControl isInvalid={isInvalid} className="mb-3">
          {label && (
            <div
              style={{ marginBottom: 3, lineHeight: 0 }}
              className="flex items-center">
              <LabelText>{label}</LabelText>
              <span className="text-xs font-extralight italic ml-2">
                {sublabel}
              </span>
              {help && <HelpPopup>{help}</HelpPopup>}
            </div>
          )}
          {typeof children !== 'undefined' ? (
            children(fieldArgs)
          ) : (
            <Input
              bgColor="white"
              onKeyPress={onKeyPress}
              {...fieldArgs.field}
              value={fieldArgs.field.value || ''}
              disabled={disabled}
              {...props}
            />
          )}
          {annotation && !isInvalid ? (
            <div className="h-3 pt-0.5 text-xs">{annotation}</div>
          ) : (
            <div
              className="h-3 pt-0.5 text-xs"
              style={{ color: formErrorText }}>
              {isInvalid ? String(fieldErrors) : null}
            </div>
          )}
        </FormControl>
      )}
    </Field>
  );
}
