import * as yup from 'yup';

export const PhoneSchema = yup
  .string()
  .required('Phone is required')
  .min(15, 'Invalid Phone number')
  .matches(
    /^(\+?1[\s.-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    'Phone must be in valid format',
  );
