import { gql } from '@apollo/client';

import graphqlClient from '@/backend/graphql';

import { GetLegacyBookings } from './__generated__/GetLegacyBookings';

const GET_LEGACY_BOOKINGS = gql`
  query GetLegacyBookings {
    viewer {
      legacyBookings {
        id
        url
        shipmentType
        origin {
          address {
            city
            state
          }
          date
        }
        destination {
          address {
            city
            state
          }
          date
        }
        carrier {
          id
          name
        }
        loadStatus
        txnId
        trackingNumber
        scacCode
        carrierPhoneNumber
      }
    }
  }
`;

export default async function getLegacyBookings() {
  const resp = await graphqlClient.query<GetLegacyBookings>({
    query: GET_LEGACY_BOOKINGS,
  });
  const bookings = resp?.data?.viewer?.legacyBookings || [];
  return { bookings };
}
