import { gql } from '@apollo/client';

import client from '..';

const CREATE_FAILED_TRANSACTION = gql`
  mutation CreateFailedTransaction(
    $amount: Float!
    $code: String!
    $city: String!
    $state: String!
    $country: String!
    $transactionId: String!
    $ip: String!
  ) {
    createFailedTransaction(
      input: {
        amount: $amount
        code: $code
        city: $city
        state: $state
        country: $country
        transactionId: $transactionId
        ip: $ip
      }
    ) {
      attempt
      error
      blocked
    }
  }
`;

export default async function saveFailedTransaction(
  amount: number,
  code: string,
  city: string,
  state: string,
  country: string,
  transactionId: string,
  ip: string,
) {
  const resp = await client.mutate({
    mutation: CREATE_FAILED_TRANSACTION,
    variables: {
      amount,
      code,
      city,
      state,
      country,
      transactionId,
      ip,
    },
  });

  const data = resp.data.createFailedTransaction;

  return { data };
}
