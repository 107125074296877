import { capitalize, isFunction, sum } from 'lodash';
import * as yup from 'yup';

import { DistanceInInches, UoMType, WeightInLbs } from '@/utils/calculateClass';
import {
  EndPointAccessorials,
  BookingRequest,
  Item,
  RFQ,
  ShipmentType,
  TruckType,
  isLtlQuote,
} from '@/utils/types';

import { isDomestic } from './isDomestic';
import { PhoneSchema } from './PhoneSchema';

// weight and dimension restrictions
const VanLimits = {
  maxWeight: 45000,
  maxTotalWeight: 45000,
  maxLength: 636,
  maxWidth: 101,
  maxHeight: 109,
  maxQuantity: 999,
  maxTotalVolume: 10000000,
};

const FlatbedLimits = {
  maxWeight: 45000,
  maxTotalWeight: 45000,
  maxLength: 636,
  maxWidth: 102,
  maxHeight: 102,
  maxQuantity: 999,
  maxTotalVolume: 10000000,
};

const ReeferLimits = {
  maxWeight: 43500,
  maxTotalWeight: 43500,
  maxLength: 636,
  maxWidth: 98,
  maxHeight: 102,
  maxQuantity: 999,
  maxTotalVolume: 10000000,
};

const LTLLimits = {
  maxWeight: 2500,
  // TODO seems like minWeight is not enforced; legacy app does not enforce either
  // Delete?
  minWeight: 100,
  maxQuantity: 6,
  maxTotalVolume: 1296000,
  maxTotalWeight: 25000,
};

export const LiftedLTLLimits = {
  ...LTLLimits,
  maxLength: 144,
  maxWidth: 84,
  maxHeight: 72,
};

export const FTLLimits = {
  van: VanLimits,
  flatbed: FlatbedLimits,
  reefer: ReeferLimits,
};

export const UnliftedLTLLimits = {
  ...LTLLimits,
  maxLength: 144,
  maxWidth: 72,
  maxHeight: 84,
};

const getUoM = (parent: Record<string, string>, uomType: UoMType) =>
  parent[uomType.fieldUoM] || uomType.defaultUoM;

export const FreeFieldsStringSchema = yup
  .string()
  .required('Required')
  .matches(
    /^([A-Za-z0-9_.,-:;()?¿!"'\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/,
    'Must be only letters, numbers, and punctuation',
  )
  .matches(/^(?!\s+$).*/, 'This field must not contain only spaces');

export const ItemDescriptionSchema = yup
  .string()
  .required('Description is required')
  .max(100, 'Description cannot exceed 100 characters')
  .matches(
    /^([A-Za-z0-9_.,-:;()?¿!"'\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/,
    'Must be only letters, numbers, and punctuation',
  );

export const getLimits = (
  shipmentType: ShipmentType,
  originAccessorials: EndPointAccessorials,
  destAccessorials: EndPointAccessorials,
  truckType?: TruckType | null,
) => {
  if (shipmentType === 'LTL' || !truckType) {
    return originAccessorials?.liftgate || destAccessorials?.liftgate
      ? LiftedLTLLimits
      : UnliftedLTLLimits;
  }
  return FTLLimits[truckType];
};

export type DimensionalLimits = ReturnType<typeof getLimits>;

const boundedNumber = (name: string) =>
  yup
    .number()
    .required(`${capitalize(name)} is required`)
    .min(1, `${capitalize(name)} is required`);

const boundedQuantity = (name: string, max: number) =>
  boundedNumber(name).max(
    max,
    `${capitalize(name)} must be no more than ${max}`,
  );

const boundedDimension = (
  name: string,
  maxName: keyof DimensionalLimits,
  uomType: UoMType,
  sourceType: 'rfq' | 'bookingRequest',
) =>
  boundedNumber(name).test(
    name,
    '',
    // @ts-expect-error TODO Couldn't get to some of these in
    // https://plslogistics.atlassian.net/browse/GS-736
    (
      value,
      testContext: yup.TestContext<{
        bookingRequest: BookingRequest;
        rfq: RFQ;
      }>,
    ) => {
      const context = testContext?.options?.context;
      if (!context) {
        return;
      }
      const quote =
        sourceType === 'rfq' ? context[sourceType] : context[sourceType].quote;

      const { shipmentType } = quote;
      const truckType = shipmentType === 'LTL' ? null : quote.truckType;

      const originAccessorials = context[sourceType].origin.accessorials;
      const destAccessorials = context[sourceType].destination.accessorials;

      const limits = getLimits(
        shipmentType,
        originAccessorials,
        destAccessorials,
        truckType,
      );

      const uom: string = getUoM(testContext.parent, uomType);
      const limit = Math.floor(uomType.from(limits[maxName], uom));
      if (value && value > limit) {
        throw testContext.createError({
          message: `${capitalize(
            name,
          )} must be no more than ${limit.toLocaleString()} ${uom}`,
        });
      }
      return true;
    },
  );

export const testRfqItems = (
  name: string,
  maxName: keyof DimensionalLimits,
  getValue: (item: Item) => number,
  measure?: string | ((n: number) => string),
  // @ts-expect-error TODO Couldn't get to some of these in
  // https://plslogistics.atlassian.net/browse/GS-736
) => (value, testContext: yup.TestContext) => {
  const rfq: RFQ = value;

  const limits = getLimits(
    rfq.shipmentType,
    rfq.origin.accessorials,
    rfq.destination.accessorials,
    rfq.shipmentType === 'LTL' ? null : rfq.truckType,
  );

  const limit = limits[maxName];
  const total = sum(rfq.items.map(getValue));
  if (total > limit) {
    const measureText = isFunction(measure)
      ? measure(limit)
      : measure
      ? measure
      : limit;
    throw testContext.createError({
      message: `${capitalize(name)} must be no more than ${measureText}`,
      path: `${(testContext as any).type}`,
    });
  }

  return true;
};

export const testBookingRequestItems = (
  name: string,
  maxName: keyof DimensionalLimits,
  getValue: (item: Item) => number,
  measure?: string | ((n: number) => string),
  // @ts-expect-error TODO Couldn't get to some of these in
  // https://plslogistics.atlassian.net/browse/GS-736
) => (value, testContext: yup.TestContext) => {
  const bookingRequest: BookingRequest = value;
  const limits = getLimits(
    bookingRequest.quote.shipmentType,
    bookingRequest.origin.accessorials,
    bookingRequest.destination.accessorials,
    isLtlQuote(bookingRequest.quote)
      ? undefined
      : bookingRequest.quote.truckType,
  );
  const limit = limits[maxName];
  const total = sum(bookingRequest.items.map(getValue));

  if ((limit || limit === 0) && total > limit) {
    const measureText = isFunction(measure)
      ? measure(limit)
      : measure
      ? measure
      : limit;
    throw testContext.createError({
      message: `${capitalize(name)} must be no more than ${measureText}`,
      path: `${(testContext as any).type}`,
    });
  }

  return true;
};

// LTL Item pre-quote
const commonLTLItemValidations = {
  hazardous: yup.boolean(),
  packaging: yup.string().required('Package type is required'),
  hazmatDetails: yup.object().when('hazardous', {
    is: true,
    then: yup.object().shape({
      unNumber: yup
        .string()
        .required('UN number is required')
        .matches(/^UN\d\d\d\d$/i, 'UN number must be in the format UN1234'),
      packGroupNumber: yup.string().required('Package group is required'),
      emergencyContactCompany: yup
        .string()
        .required('Emergency contact company is required')
        .max(32, 'Emergency contact company must be at most 32 characters'), // required by database
      emergencyContactPhone: PhoneSchema,
      hazmatClass: yup.string().required('Hazmat class is required'),
      contractNumber: yup.string().required('Contract is required'),
      instructions: FreeFieldsStringSchema.required(
        'Instructions is required',
      ).max(3000, 'Instructions is too long'),
    }),
    otherwise: yup.object().nullable(),
  }),
};

export const RfqLTLItem = yup.object().shape({
  ...commonLTLItemValidations,
  height: boundedDimension('height', 'maxHeight', DistanceInInches, 'rfq'),
  width: boundedDimension('width', 'maxWidth', DistanceInInches, 'rfq'),
  length: boundedDimension('length', 'maxLength', DistanceInInches, 'rfq'),
  quantity: boundedQuantity('quantity', 6),
  weight: boundedDimension('weight', 'maxWeight', WeightInLbs, 'rfq'),
  description: ItemDescriptionSchema,
  value: yup
    .number()
    .required('Value is required')
    .max(99999, 'Item value must be less than or equal to 99999'),
});

const TariffRE = /^\d\d\d\d\.\d\d\.\d\d\d\d$/;
// LTL item post-quote
export const BookingRequestLTLItem = yup.object().shape({
  ...commonLTLItemValidations,
  height: boundedDimension(
    'height',
    'maxHeight',
    DistanceInInches,
    'bookingRequest',
  ),
  width: boundedDimension(
    'width',
    'maxWidth',
    DistanceInInches,
    'bookingRequest',
  ),
  length: boundedDimension(
    'length',
    'maxLength',
    DistanceInInches,
    'bookingRequest',
  ),
  quantity: boundedQuantity('quantity', 6),
  weight: boundedDimension(
    'weight',
    'maxWeight',
    WeightInLbs,
    'bookingRequest',
  ),
  description: ItemDescriptionSchema,
  value: yup
    .number()
    .required('Value is required')
    .max(99999, 'Item value must be less than or equal to 99999'),
  tariff: yup.string().test(
    'tariff',
    'Tariff must be in the format 1234.56.7890',
    // @ts-expect-error TODO Couldn't get to some of these in
    // https://plslogistics.atlassian.net/browse/GS-736
    (value, testContext: yup.TestContext<{ bookingRequest: BookingRequest }>) =>
      // @ts-expect-error TODO Couldn't get to some of these in
      // https://plslogistics.atlassian.net/browse/GS-736
      isDomestic(testContext.options.context?.bookingRequest) ||
      !testContext.options.context?.bookingRequest.customs
        .createCustomsInvoice ||
      // @ts-expect-error TODO Couldn't get to some of these in
      // https://plslogistics.atlassian.net/browse/GS-736
      !!TariffRE.exec(value),
  ),
  countryOfOrigin: yup.string().test(
    'country-of-origin',
    'Please select country of origin',
    // @ts-expect-error TODO Couldn't get to some of these in
    // https://plslogistics.atlassian.net/browse/GS-736
    (value, testContext: yup.TestContext<{ bookingRequest: BookingRequest }>) =>
      // @ts-expect-error TODO Couldn't get to some of these in
      // https://plslogistics.atlassian.net/browse/GS-736
      isDomestic(testContext.options.context?.bookingRequest) ||
      !testContext.options.context?.bookingRequest.customs
        .createCustomsInvoice ||
      !!value?.length,
  ),
});

// FTL item post-quote only
export const FTLItemSchema = yup.object().shape({
  packaging: yup.string().required('Package type is required'),
  height: boundedDimension(
    'height',
    'maxHeight',
    DistanceInInches,
    'bookingRequest',
  ),
  width: boundedDimension(
    'width',
    'maxWidth',
    DistanceInInches,
    'bookingRequest',
  ),
  length: boundedDimension(
    'length',
    'maxLength',
    DistanceInInches,
    'bookingRequest',
  ),
  quantity: yup
    .number()
    .required('Quantity is required')
    .min(1)
    .max(999, 'Item quantity must be less than or equal to 999'),
  value: yup
    .number()
    .required('Value is required')
    .max(99999, 'Item value must be less than or equal to 99999'),
  weight: boundedDimension(
    'weight',
    'maxWeight',
    WeightInLbs,
    'bookingRequest',
  ),
  description: ItemDescriptionSchema,
});

export const RfqFTLItem = yup.object().shape({
  packaging: yup.string().required('Package type is required'),
  height: boundedDimension('height', 'maxHeight', DistanceInInches, 'rfq'),
  width: boundedDimension('width', 'maxWidth', DistanceInInches, 'rfq'),
  length: boundedDimension('length', 'maxLength', DistanceInInches, 'rfq'),
  quantity: yup
    .number()
    .required('Quantity is required')
    .min(1)
    .max(999, 'Item quantity must be less than or equal to 999'),
  value: yup
    .number()
    .required('Value is required')
    .max(99999, 'Item value must be less than or equal to 99999'),
  weight: boundedDimension('weight', 'maxWeight', WeightInLbs, 'rfq'),
  description: ItemDescriptionSchema,
});
