import { captureException } from '@sentry/nextjs';
import jwtDecode from 'jwt-decode';

import {
  saveAccessToken,
  saveRefreshToken,
  retrieveAccessToken,
  retrieveRefreshToken,
} from '@/utils/storage';

import refreshTokenGQL from './graphqlpublic/user/refreshToken';

/**
 * for a standard JWT token, return the expiration date in ms
 */
const getExpirationDate = (token: string): number => {
  if (!token) {
    return 0;
  }
  const { exp } = jwtDecode(token) as { exp: number };
  return typeof exp === 'number' ? 1000 * exp : 0;
};

const aboutToExpire = (d: number): boolean => !!d && d - Date.now() < 36000;

const refreshAccessToken = async () => {
  const refToken = retrieveRefreshToken();
  try {
    const { error, accessToken, refreshToken } = await refreshTokenGQL({
      refToken,
    });
    if (!error && accessToken && refreshToken) {
      saveAccessToken(accessToken);
      saveRefreshToken(refreshToken);
      return accessToken;
    }
  } catch (e) {
    captureException(e);
  }
  return '';
};

export const isSignedIn = () => !!retrieveAccessToken();

export const getLatestAccessToken = async (): Promise<string> => {
  const accessToken = retrieveAccessToken();
  const expirationDate = getExpirationDate(accessToken);
  if (aboutToExpire(expirationDate)) {
    return refreshAccessToken();
  }
  return accessToken;
};
export const updateUserTokens = (accessToken: string, refreshToken: string) => {
  saveAccessToken(accessToken);
  saveRefreshToken(refreshToken);
};
