import { useCallback, useRef, useState } from 'react';

type OnCloseCallbackFn = (success: boolean) => void;
export type SignInMode = 'signin' | 'signup' | 'forgot' | '';

export type SignInContext = {
  signInMode: SignInMode;
  openSignInModal: (
    mode: Exclude<SignInMode, ''>,
    onCloseCallback?: OnCloseCallbackFn,
  ) => void;
  // @ts-expect-error TODO Couldn't get to some of these in
  // https://plslogistics.atlassian.net/browse/GS-736
  closeSignInModal: (success) => void;
  isSigningIn: boolean;
  setIsSigningIn: (b: boolean) => void;
};

export default function useSignInContext(): SignInContext {
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [signInMode, setSigninMode] = useState<SignInMode>('');
  const onCloseCallback = useRef<OnCloseCallbackFn>(null);

  const openSignInModal = useCallback(
    (mode: Exclude<SignInMode, ''>, cb?: OnCloseCallbackFn) => {
      // @ts-expect-error TODO Couldn't get to some of these in
      // https://plslogistics.atlassian.net/browse/GS-736
      onCloseCallback.current = cb || null;
      setSigninMode(mode);
    },
    [],
  );

  const closeSignInModal = useCallback((success: boolean) => {
    onCloseCallback.current && onCloseCallback.current(success);
    setSigninMode('');
  }, []);

  return {
    signInMode,
    openSignInModal,
    closeSignInModal,
    isSigningIn,
    setIsSigningIn,
  };
}
