import { gql } from '@apollo/client';

import { publicClient } from '../../graphql';

const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      error
      success
    }
  }
`;

export default async function forgotPasswordGQL(email: string) {
  const resp = await publicClient.mutate({
    mutation: FORGOT_PASSWORD,
    variables: {
      email,
    },
  });

  const data = resp.data.forgotPassword;

  return { data };
}
