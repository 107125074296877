import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

import { publicClient } from '@/backend/graphql';
import { TimeAddress } from '@/utils/types';
import {
  countryByPostalCode,
  validatePostalCode,
} from '@/utils/validatePostalCode';
import { Query } from 'publicTypes';

const CITY_STATE_SEARCH = gql`
  query CityStateSearch($postalCode: String!, $country: String!) {
    cityStateSearch(postalCode: $postalCode, country: $country) {
      city
      state
      country
      timeOffset
      postalCode
      lat
      lng
    }
  }
`;

export default function useCityStateSearch(postalCode: string) {
  const [fetchCityState, { data, error, loading }] = useLazyQuery<Query>(
    CITY_STATE_SEARCH,
    {
      client: publicClient,
    },
  );

  useEffect(() => {
    if (postalCode.length < 5) {
      return;
    }

    if (validatePostalCode(postalCode)) {
      fetchCityState({
        variables: { postalCode, country: countryByPostalCode(postalCode) },
      });
    }
  }, [fetchCityState, postalCode]);

  const timeAddresses = (data?.cityStateSearch as TimeAddress[]) ?? [];
  return { timeAddresses, error, loading };
}
