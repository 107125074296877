import { gql } from '@apollo/client';

import client from '..';

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(
      input: { oldPassword: $oldPassword, newPassword: $newPassword }
    ) {
      error
      success
    }
  }
`;

export default async function changePasswordGQL(
  oldPassword: string,
  newPassword: string,
) {
  const resp = await client.mutate({
    mutation: CHANGE_PASSWORD,
    variables: {
      oldPassword,
      newPassword,
    },
  });

  const data = resp.data.changePassword;

  return { data };
}
