import { gql } from '@apollo/client';

import { TransactionCreateResponse } from 'types';

import client from '..';

const CREATE_UPCHARGE_TRANSACTION = gql`
  mutation CreateUpchargeTransaction($amount: Float!, $upchargeId: String!) {
    createUpchargeTransaction(
      input: { amount: $amount, upchargeId: $upchargeId }
    ) {
      transactionCreateResponse {
        id
        token
        amount
        status
      }
      error
    }
  }
`;
export default async function createUpchargeTransactionMutation(
  amount: number,
  upchargeId: string,
) {
  const resp = await client.mutate({
    mutation: CREATE_UPCHARGE_TRANSACTION,
    variables: {
      amount,
      upchargeId,
    },
  });

  const data = resp.data.createUpchargeTransaction
    .transactionCreateResponse as TransactionCreateResponse;

  return { data };
}
