import { gql } from '@apollo/client';

import graphqlClient from '@/backend/graphql';

import { GetCarriers } from './__generated__/GetCarriers';

const GET_CARRIERS = gql`
  query GetCarriers($scacCodes: [String!]) {
    getCarriers(scacCodes: $scacCodes) {
      carrierName
      scacCode
      phoneNumber
    }
  }
`;

export default async function getCarriers(scacCodes: string[]) {
  const resp = await graphqlClient.query<GetCarriers>({
    query: GET_CARRIERS,
    variables: { scacCodes },
  });

  const carriers = resp?.data?.getCarriers;
  return { carriers };
}
