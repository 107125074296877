import { gql } from '@apollo/client';

import graphqlClient, { publicClient } from '@/backend/graphql';

import { FtlRfqInput } from '../../../publicTypes.d';

import { RequestFTLQuote } from './__generated__/RequestFTLQuote';

const REQUEST_FTL_QUOTE = gql`
  query RequestFTLQuote($rfq: FtlRfqInput!) {
    requestFTLQuote(rfq: $rfq) {
      guid
      truckType
      transitTime
      cost
      baseRate
      deliveryDate
      quoteDate
      shipmentType
      ftlAccessorialCharges {
        code
        description
        charge
      }
    }
  }
`;

export default async function requestFTLQuote(
  rfq: FtlRfqInput,
  loggedIn: boolean,
) {
  let resp;
  if (loggedIn) {
    resp = await graphqlClient.query<RequestFTLQuote>({
      query: REQUEST_FTL_QUOTE,
      variables: { rfq },
    });
  } else {
    resp = await publicClient.query<RequestFTLQuote>({
      query: REQUEST_FTL_QUOTE,
      variables: { rfq },
    });
  }

  const ftlQuotes = resp?.data?.requestFTLQuote ?? [];

  return ftlQuotes;
}
