import { gql, useMutation } from '@apollo/client';

import { BookingRequest, CostBreakdown } from '@/utils/types';

import { PrepareCuttlefishBooking } from './__generated__/PrepareCuttlefishBooking';

const PREPARE_BOOKING = gql`
  mutation PrepareCuttlefishBooking(
    $bookingRequest: BookingRequest!
    $costBreakdown: CostBreakdownInput!
    $bookingLogId: String!
  ) {
    prepareCuttlefishBooking(
      input: {
        bookingRequest: $bookingRequest
        costBreakdown: $costBreakdown
        bookingLogId: $bookingLogId
      }
    ) {
      error
      booking {
        bookingId
        shippingCost
        creditAmount
        totalCost
        transactionId
        transactionToken
        transactionStatus
        insurancePremium
        promoCodeDiscount
      }
    }
  }
`;

export default function usePrepareBooking() {
  const [
    prepareBookingOrderQL,
    { data, loading, error },
  ] = useMutation<PrepareCuttlefishBooking>(PREPARE_BOOKING);

  const prepareBooking = async (
    bookingRequest: BookingRequest,
    costBreakdown: CostBreakdown,
    bookingLogId: string,
  ) => {
    if (bookingRequest && costBreakdown && bookingLogId) {
      const { data } = await prepareBookingOrderQL({
        variables: {
          bookingRequest: {
            ...bookingRequest,
            origin: {
              ...bookingRequest.origin,
              accessorials: { ...bookingRequest.origin.accessorials },
              address: {
                streetAddress: bookingRequest.origin.address.streetAddress,
                streetAddress1: bookingRequest.origin.address.streetAddress1,
                timeOffset: bookingRequest.origin.address.timeOffset,
                postalCode: bookingRequest.origin.address.postalCode,
                city: bookingRequest.origin.address.city,
                state: bookingRequest.origin.address.state,
                country: bookingRequest.origin.address.country,
                lat: bookingRequest.origin.address.lat,
                lng: bookingRequest.origin.address.lng,
                firstName: bookingRequest.origin.address.firstName,
                lastName: bookingRequest.origin.address.lastName,
                companyName: bookingRequest.origin.address.companyName,
                phone: bookingRequest.origin.address.phone,
                email: bookingRequest.origin.address.email,
              },
            },
            destination: {
              ...bookingRequest.destination,
              accessorials: { ...bookingRequest.destination.accessorials },
              address: {
                streetAddress: bookingRequest.destination.address.streetAddress,
                streetAddress1:
                  bookingRequest.destination.address.streetAddress1,
                timeOffset: bookingRequest.destination.address.timeOffset,
                postalCode: bookingRequest.destination.address.postalCode,
                city: bookingRequest.destination.address.city,
                state: bookingRequest.destination.address.state,
                country: bookingRequest.destination.address.country,
                lat: bookingRequest.destination.address.lat,
                lng: bookingRequest.destination.address.lng,
                firstName: bookingRequest.destination.address.firstName,
                lastName: bookingRequest.destination.address.lastName,
                companyName: bookingRequest.destination.address.companyName,
                phone: bookingRequest.destination.address.phone,
                email: bookingRequest.destination.address.email,
              },
            },
            billing: {
              accessorials: {
                driverAssist: false,
                liftgate: false,
                inside: false,
                appointment: false,
                noFreeze: false,
              },
              address: {
                streetAddress: bookingRequest.billing.address.streetAddress,
                streetAddress1: bookingRequest.billing.address.streetAddress1,
                timeOffset: bookingRequest.billing.address.timeOffset,
                postalCode: bookingRequest.billing.address.postalCode,
                city: bookingRequest.billing.address.city,
                state: bookingRequest.billing.address.state,
                country: bookingRequest.billing.address.country,
                lat: bookingRequest.billing.address.lat,
                lng: bookingRequest.billing.address.lng,
                firstName: bookingRequest.billing.address.firstName,
                lastName: bookingRequest.billing.address.lastName,
                companyName: bookingRequest.billing.address.companyName,
                phone: bookingRequest.billing.address.phone,
                email: bookingRequest.billing.address.email,
              },
              date: bookingRequest.origin.date,
              endTime: bookingRequest.origin.date,
              instructions: '',
              saveToAddressBook: false,
              startTime: bookingRequest.origin.date,
              type: 'BUSINESS_WOUT_DOCK',
            },
            quoteId: bookingRequest.quote.guid,
            customs: {
              ...bookingRequest.customs,
              broker: {
                streetAddress: bookingRequest.customs.broker.streetAddress,
                streetAddress1: bookingRequest.customs.broker.streetAddress1,
                timeOffset: bookingRequest.customs.broker.timeOffset,
                postalCode: bookingRequest.customs.broker.postalCode,
                city: bookingRequest.customs.broker.city,
                state: bookingRequest.customs.broker.state,
                country: bookingRequest.customs.broker.country,
                lat: bookingRequest.customs.broker.lat,
                lng: bookingRequest.customs.broker.lng,
                firstName: bookingRequest.customs.broker.firstName,
                lastName: bookingRequest.customs.broker.lastName,
                companyName: bookingRequest.customs.broker.companyName,
                phone: bookingRequest.customs.broker.phone,
                email: bookingRequest.customs.broker.email,
                usePlsBroker: bookingRequest.customs.broker.usePlsBroker,
                brokerAuthorized:
                  bookingRequest.customs.broker.brokerAuthorized,
              },
              vendor: {
                streetAddress: bookingRequest.customs.vendor.streetAddress,
                streetAddress1: bookingRequest.customs.vendor.streetAddress1,
                timeOffset: bookingRequest.customs.vendor.timeOffset,
                postalCode: bookingRequest.customs.vendor.postalCode,
                city: bookingRequest.customs.vendor.city,
                state: bookingRequest.customs.vendor.state,
                country: bookingRequest.customs.vendor.country,
                lat: bookingRequest.customs.vendor.lat,
                lng: bookingRequest.customs.vendor.lng,
                firstName: bookingRequest.customs.vendor.firstName,
                lastName: bookingRequest.customs.vendor.lastName,
                companyName: bookingRequest.customs.vendor.companyName,
                phone: bookingRequest.customs.vendor.phone,
                email: bookingRequest.customs.vendor.email,
                usePickup: bookingRequest.customs.vendor.usePickup,
                useDelivery: bookingRequest.customs.vendor.useDelivery,
              },
              purchaser: {
                streetAddress: bookingRequest.customs.purchaser.streetAddress,
                streetAddress1: bookingRequest.customs.purchaser.streetAddress1,
                timeOffset: bookingRequest.customs.purchaser.timeOffset,
                postalCode: bookingRequest.customs.purchaser.postalCode,
                city: bookingRequest.customs.purchaser.city,
                state: bookingRequest.customs.purchaser.state,
                country: bookingRequest.customs.purchaser.country,
                lat: bookingRequest.customs.purchaser.lat,
                lng: bookingRequest.customs.purchaser.lng,
                firstName: bookingRequest.customs.purchaser.firstName,
                lastName: bookingRequest.customs.purchaser.lastName,
                companyName: bookingRequest.customs.purchaser.companyName,
                phone: bookingRequest.customs.purchaser.phone,
                email: bookingRequest.customs.purchaser.email,
                usePickup: bookingRequest.customs.vendor.usePickup,
                useDelivery: bookingRequest.customs.vendor.useDelivery,
              },
            },
          },
          costBreakdown: {
            cost: costBreakdown.cost,
            creditAmount: costBreakdown.creditAmount,
            finalCost: costBreakdown.finalCost,
            premium: costBreakdown.premium,
            promoCodeDiscount: costBreakdown.promoCodeDiscount,
          },
          bookingLogId,
        },
      });
      return data ? data.prepareCuttlefishBooking : null;
    }
    return Promise.resolve(null);
  };
  return [prepareBooking, { data, loading, error }] as const;
}
