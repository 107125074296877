import { BookingRequest } from '../types';

export const isDomestic = ({
  origin: {
    address: { country: oCountry },
  },
  destination: {
    address: { country: dCountry },
  },
}: BookingRequest) => oCountry === dCountry;
