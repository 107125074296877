import { find, round, toPairs } from 'lodash';

import { FreightClass, Item } from '@/utils/types';

/**
 * Below class density calculation logic taken from GoShip Angular app
 */

const CLASS_DENSITIES: {
  [key in FreightClass]: { min: number; max: number };
} = {
  CLASS_60: { min: 30, max: Number.MAX_VALUE },
  CLASS_65: { min: 22.5, max: 30 },
  CLASS_70: { min: 15, max: 22.5 },
  CLASS_85: { min: 12, max: 15 },
  CLASS_92_5: { min: 10, max: 12 },
  CLASS_100: { min: 8, max: 10 },
  CLASS_125: { min: 6, max: 8 },
  CLASS_175: { min: 4, max: 6 },
  CLASS_250: { min: 2, max: 4 },
  CLASS_300: { min: 1, max: 2 },
  CLASS_400: { min: Number.MIN_VALUE, max: 1 },
  CLASS_50: { min: -2, max: -1 },
  CLASS_55: { min: -2, max: -1 },
  CLASS_77_5: { min: -2, max: -1 },
  CLASS_110: { min: -2, max: -1 },
  CLASS_500: { min: -2, max: -1 },
  CLASS_150: { min: -2, max: -1 },
  CLASS_200: { min: -2, max: -1 },
};

export const CM_TO_INCHES = 0.39370079;
export const CUBIC_CM_TO_CUBIC_INCHES =
  CM_TO_INCHES * CM_TO_INCHES * CM_TO_INCHES;
export const KG_TO_LBS = 2.20462262185;
export const INCHES_IN_CUBIC_FOOT = 1728;

// normalize metric values to Imperial UOM
function getImperialDensity(item: Item) {
  // https://plslogistics.atlassian.net/browse/GS-736
  const optimalItemWeight = WeightInLbs.to(item.weight || 0, item.weightUoM);
  const totalWeightLbs = optimalItemWeight * (item?.quantity || 0);

  const length =
    // @ts-expect-error TODO Couldn't get to some of these in
    // https://plslogistics.atlassian.net/browse/GS-736
    item.sizeUoM === 'in' ? item.length : round(item.length * CM_TO_INCHES, 3);
  const height =
    // @ts-expect-error TODO Couldn't get to some of these in
    // https://plslogistics.atlassian.net/browse/GS-736
    item.sizeUoM === 'in' ? item.height : round(item.height * CM_TO_INCHES, 3);
  const width =
    // @ts-expect-error TODO Couldn't get to some of these in
    // https://plslogistics.atlassian.net/browse/GS-736
    item.sizeUoM === 'in' ? item.width : round(item.width * CM_TO_INCHES, 3);
  const calculatedWeight = totalWeightLbs < 100 ? 100 : totalWeightLbs;
  return (
    calculatedWeight /
    // @ts-expect-error TODO Couldn't get to some of these in
    ((length * width * height * item.quantity) / INCHES_IN_CUBIC_FOOT)
  );
}

function calculateDensity(item: Item) {
  const densityPCF = getImperialDensity(item);
  return parseFloat(densityPCF < 0.0001 ? '0' : densityPCF.toFixed(3));
}

export function calculateClass(item: Item): FreightClass {
  const densityPCF = calculateDensity(item);

  if (densityPCF > 0) {
    // @ts-expect-error TODO Couldn't get to some of these in
    // https://plslogistics.atlassian.net/browse/GS-736
    return find(toPairs(CLASS_DENSITIES), function (commodityClass) {
      const bounds = commodityClass[1];
      return bounds.min <= densityPCF && bounds.max > densityPCF;
    })[0] as FreightClass;
  } else {
    return 'CLASS_400';
  }
}

export type UoMType = {
  defaultUoM: string;
  fieldUoM: string;
  from: (d: number, uom: string) => number;
  to: (d: number, uom: string) => number;
};

// @ts-expect-error TODO Couldn't get to some of these in
// https://plslogistics.atlassian.net/browse/GS-736
const makeUoM = (defaultUoM: string, factor: number, fieldUoM) => ({
  defaultUoM,
  fieldUoM,
  from: (d: number, uom: string) =>
    uom === defaultUoM ? d : Math.round(d / factor),
  to: (d: number, uom: string) =>
    uom === defaultUoM ? d : Math.round(d * factor),
});

export const DistanceInInches = makeUoM('in', CM_TO_INCHES, 'sizeUoM');
export const WeightInLbs = makeUoM('lbs', KG_TO_LBS, 'weightUoM');
