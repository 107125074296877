import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AppProps } from 'next/app';
import Script from 'next/script';
import React from 'react';
import { Helmet } from 'react-helmet';

import graphqlClient from '@/backend/graphql';
import AppFooter from '@/components/AppFooter';
import AppHeader from '@/components/AppHeader';
import BookingFlowProvider from '@/components/context/BookingFlowProvider';
import { GSAppContextProvider } from '@/components/context/GSAppContext';
import { appBgColor } from '@/styles/Colors';

import '@/styles/globals.css';
import LoadFixtureData from '../components/LoadFixtureData';

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(utc);

// Compile-time checks
if (!process.env.NEXT_PUBLIC_BALANCE_SCRIPT_URL) {
  throw new Error(
    'Please ensure env var NEXT_PUBLIC_BALANCE_SCRIPT_URL are set in your .env.local file',
  );
}

/**
 * App contains the top-most layout of the application. It contains
 * persistent UI elements such as the app header and footer.
 */
export default function App(props: AppProps) {
  const { Component, pageProps } = props;

  const googleTagId = process.env.NEXT_PUBLIC_GOOGLE_TAG_ID || 'AW-832768220';

  return (
    <div style={{ backgroundColor: appBgColor }}>
      <Helmet htmlAttributes={{ lang: 'en-US' }}>
        <title>GoShip</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"></meta>
        <meta
          name="description"
          content="Your go-to for LTL shipping quotes and full truckload freight quotes. Connect with top LTL carriers and get transparent rates."></meta>
      </Helmet>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleTagId}`}></Script>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', '${googleTagId}');
          `,
        }}></Script>
      <Script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDB_lKkbE9mzqqB-Xkame_zI-Nz3DGgAaU&libraries=places" />
      <Script
        type="text/javascript"
        src="//cdn-4.convertexperiments.com/js/1004562-1004417.js"></Script>
      <Script
        type="text/javascript"
        src="//script.crazyegg.com/pages/scripts/0119/4335.js"
        async></Script>

      <ChakraProvider>
        <GSAppContextProvider {...props}>
          <ApolloProvider client={graphqlClient}>
            <AppHeader />
            <BookingFlowProvider>
              <LoadFixtureData>
                <div
                  className="max-w-5xl"
                  style={{
                    margin: '0 auto',
                  }}>
                  <Component {...pageProps} />
                </div>
              </LoadFixtureData>
            </BookingFlowProvider>
            <AppFooter />
          </ApolloProvider>
        </GSAppContextProvider>
      </ChakraProvider>
    </div>
  );
}
