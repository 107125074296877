import { gql, useMutation } from '@apollo/client';

import { CostCalculateInput } from '../../../types.d';

import { CalculateCost } from './__generated__/CalculateCost';

const CALCULATE_COST = gql`
  mutation CalculateCost($costBreakdown: CostCalculateInput!) {
    calculateCost(input: { costBreakdown: $costBreakdown }) {
      error
      costBreakdown {
        cost
        finalCost
        premium
        creditAmount
        promoCodeDiscount
      }
    }
  }
`;

export default function useCalculateCost() {
  const [
    calculateCostQL,
    { data, loading, error },
  ] = useMutation<CalculateCost>(CALCULATE_COST);

  const calculateCost = async (costCalculateInput: CostCalculateInput) => {
    if (costCalculateInput) {
      const { data } = await calculateCostQL({
        variables: {
          costBreakdown: {
            ...costCalculateInput,
          },
        },
      });
      return data ? data.calculateCost : null;
    }
    return Promise.resolve(null);
  };
  return [calculateCost, { data, loading, error }] as const;
}
