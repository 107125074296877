import { Input } from '@chakra-ui/react';
import { FieldInputProps, useFormikContext } from 'formik';
import { filter } from 'lodash';
import { forwardRef, HTMLAttributes, Ref, useCallback } from 'react';

const isDigit = (s: string) => s >= '0' && s <= '9';
const omitLeadingOne = (s: string) => (s[0] === '1' ? s.substring(1) : s);

const formatPhoneNumber = (s: string) => {
  const n = omitLeadingOne(filter(s, isDigit).join(''));
  return n.length === 10
    ? `+1(${n.substring(0, 3)})${n.substring(3, 6)}-${n.substring(6)}`
    : s;
};

const PhoneNumberInput = forwardRef(
  (
    props: FieldInputProps<string> & HTMLAttributes<HTMLInputElement>,
    ref: Ref<HTMLInputElement>,
  ) => {
    const { getFieldHelpers } = useFormikContext();
    const onBlur = useCallback(
      (c: unknown) => {
        const { setValue } = getFieldHelpers(props.name);
        setValue(formatPhoneNumber(props.value));
        if (props.onBlur) {
          props.onBlur(c);
        }
      },
      [getFieldHelpers, props],
    );

    return (
      <Input
        backgroundColor="white"
        ref={ref}
        type="tel"
        placeholder="212-555-1290"
        {...props}
        onBlur={onBlur}
      />
    );
  },
);

export default PhoneNumberInput;
