import { createContext, Dispatch, useReducer } from 'react';

import { BlankBookingRequest, BlankRFQ } from '@/utils/BlankObject';
import { BookingConfirmation, RFQ, BookingRequest } from '@/utils/types';

import DevContextPanel from './DevContextPanel';

type BookingFlowProviderProps = {
  children: React.ReactNode;
};

export type BookingFlowState = {
  rfq: RFQ;
  bookingRequest: BookingRequest;
  agreeToTOS: boolean;
  confirmation?: BookingConfirmation;
};

export type BookingFlowAction =
  | {
      type: 'setRFQ';
      value: RFQ;
    }
  | {
      type: 'setBookingRequest';
      value: BookingRequest;
    }
  | {
      type: 'setAgreeToTOS';
      value: boolean;
    }
  | {
      type: 'reset';
    };

function bookingFlowReducer(
  state: BookingFlowState,
  action: BookingFlowAction,
): BookingFlowState {
  switch (action.type) {
    case 'setRFQ':
      return {
        ...state,
        rfq: action.value,
      };
    case 'setBookingRequest':
      return {
        ...state,
        bookingRequest: action.value,
      };
    case 'setAgreeToTOS':
      return {
        ...state,
        agreeToTOS: action.value,
      };
    case 'reset':
      return {
        ...state,
        ...genBookingStateDefaultValues(),
      };
    default:
      throw new Error();
  }
}

export type BookingFlowContextValues = BookingFlowState & {
  dispatch: Dispatch<BookingFlowAction>;
};

// @ts-expect-error TODO Couldn't get to some of these in
// https://plslogistics.atlassian.net/browse/GS-736
export const BookingFlowContext = createContext<BookingFlowContextValues>(null);

const isDevelopment = process.env.NODE_ENV === 'development';

const genBookingStateDefaultValues = (): BookingFlowState => ({
  rfq: BlankRFQ,
  bookingRequest: BlankBookingRequest,
  agreeToTOS: false,
});

export default function BookingFlowProvider(props: BookingFlowProviderProps) {
  const { children } = props;

  const [state, dispatch] = useReducer(
    bookingFlowReducer,
    genBookingStateDefaultValues(),
  );

  // This is what will be shared with the entire child component tree
  const context = {
    ...state,
    dispatch,
  };

  return (
    <BookingFlowContext.Provider value={context}>
      {children}
      {isDevelopment && <DevContextPanel />}
    </BookingFlowContext.Provider>
  );
}
