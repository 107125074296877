import { map, filter } from 'lodash';

import {
  BasicCommonAccessorials,
  EndPointAccessorials,
  TruckType,
} from './types';

export type Accessorials = Record<string, boolean>;

type AccessorialDescriptor = {
  id: string;
  label: string;
  explanation?: string;
};

export type AccessorialDescription<T extends Accessorials> = {
  [k in keyof T]?: AccessorialDescriptor;
};

export type CommonAccessorialsDescription = AccessorialDescription<BasicCommonAccessorials>;

const van: CommonAccessorialsDescription = {
  noFreeze: { id: '22', label: 'Protect from freezing' },
  loadBars: { id: '23', label: 'Load Bars' },
  loadLocks: { id: '24', label: 'Load Locks' },
  straps: { id: '17', label: 'Straps' },
};

const reefer: CommonAccessorialsDescription = {
  straps: { id: '17', label: 'Straps' },
};

const flatbed: CommonAccessorialsDescription = {
  chains: { id: '18', label: 'Chains' },
  coilRacks: { id: '15', label: 'Coil Racks' },
  edgeProtectors: { id: '16', label: 'Edge Protectors' },
  straps: { id: '17', label: 'Straps' },
  tarps: { id: '1', label: 'Tarps' },
};

export const AccessorialDescriptionByTruckType = {
  van,
  reefer,
  flatbed,
} as const;

export type EndPointAccessorialsDescription = AccessorialDescription<EndPointAccessorials>;

export const LTLOriginEndPointAccessorials: EndPointAccessorialsDescription = {
  liftgate: {
    id: 'LFC',
    label: 'Liftgate',
    explanation: '(needed if there’s no dock or forklift)',
  },
  noFreeze: { id: 'PCH', label: 'Protect from freezing' },
  inside: { id: 'IPU', label: 'Inside Pickup' },
};

export const LTLDestinationEndPointAccessorials: EndPointAccessorialsDescription = {
  liftgate: {
    id: 'LFT',
    label: 'Liftgate',
    explanation: '(needed if there’s no dock or forklift)',
  },
  appointment: { id: 'NDR', label: 'Notify Delivery/Appointment' },
  inside: { id: 'IDL', label: 'Inside Delivery' },
};
export const FTLPickupCommonAccessorials: CommonAccessorialsDescription = {
  noFreeze: {
    id: 'PCH',
    label: 'Protect from Freezing',
    explanation: '',
  },
  loadBars: { id: '23', label: 'Load Bars' },
  loadLocks: { id: '24', label: 'Load Locks' },
  ppe: { id: '20', label: 'Personal Protection Equipment' },
  straps: { id: '17', label: 'Straps' },
};

export const FTLOriginEndPointAccessorials: EndPointAccessorialsDescription = {
  driverAssist: {
    id: '21',
    label: 'Driver Assist at Load',
    explanation: '',
  },
};

export const FTLDestinationEndPointAccessorials: EndPointAccessorialsDescription = {
  driverAssist: {
    id: '27',
    label: 'Driver Assist at Unload',
    explanation: '',
  },
};

export const EndPointAccessorialDescriptionByTruckType = {
  origin: {
    driverAssist: { id: '21', label: 'Driver Assist at Load' },
  },
  destination: {
    driverAssist: { id: '27', label: 'Driver Assist at Unload' },
  },
};

export const FTLEndPointAccessorialDescription = {
  origin: {
    driverAssist: { id: '21', label: 'Driver Assist at Load' },
  },
  destination: {
    driverAssist: { id: '27', label: 'Driver Assist at Unload' },
  },
};

export const AllCommonAccessorials: (keyof BasicCommonAccessorials)[] = [
  'chains',
  'coilRacks',
  'edgeProtectors',
  'loadBars',
  'loadLocks',
  'ppe',
  'straps',
  'tarps',
  'noFreeze',
];

export const AllEndpointAccessorials: (keyof EndPointAccessorials)[] = [
  'liftgate',
  'driverAssist',
  'appointment',
  'inside',
  'noFreeze',
];

export const getCommonAccessorial = (truckType: TruckType) => {
  if (truckType === 'van') return van;
  if (truckType === 'flatbed') return flatbed;
  if (truckType === 'reefer') return reefer;
  return van;
};

export const getSelectedAccessorials = <T extends Accessorials>(
  accessorialsDescription: AccessorialDescription<T> | undefined,
  accessorials: T,
  allAccessorials: (keyof T)[],
): AccessorialDescriptor[] =>
  // @ts-expect-error TODO Couldn't get to some of these in
  // https://plslogistics.atlassian.net/browse/GS-736
  map(
    filter(
      allAccessorials,
      name =>
        accessorialsDescription &&
        accessorialsDescription[name] &&
        accessorials[name],
    ),
    // @ts-expect-error TODO Couldn't get to some of these in
    // https://plslogistics.atlassian.net/browse/GS-736
    (accessoryName: string) => accessorialsDescription[accessoryName],
  );
