import dayjs from 'dayjs';

import {
  BookingRequest,
  Customs,
  FTLQuote,
  FullEndPoint,
  Item,
  LTL_RFQ,
  RFQ,
  HazMatDetails,
} from '@/utils/types';

import { Truck_Type } from '../publicTypes.d';

export const BlankHazMatDetails: HazMatDetails = {
  reportableQty: false,
  unNumber: '',
  packGroupNumber: '',
  emergencyContactPhone: '',
  emergencyContactCompany: '',
  contractNumber: '',
  instructions: '',
  hazmatClass: '',
};
export const genEmptyItem = (): Item => ({
  sizeUoM: 'in',
  weightUoM: 'lbs',
  itemCondition: 'NEW',
  value: undefined,
  width: undefined,
  height: undefined,
  length: undefined,
  weight: undefined,
  quantity: undefined,
  packaging: '',
  freightClass: 'CLASS_50',
  stackable: false,
  hazardous: false,
  hazmatDetails: BlankHazMatDetails,
  description: '',
  tariff: '',
  countryOfOrigin: '',
});

const skipToMonday = (d: dayjs.Dayjs) => {
  const day = d.get('day');
  return d.add(day === 0 ? 1 : day === 6 ? 2 : 0, 'day');
};

const nextBusinessDay = () =>
  skipToMonday(dayjs().add(1, 'day').add(3, 'hours')).toDate();

export const BlankRFQ: LTL_RFQ = {
  shipmentType: 'LTL',
  origin: {
    address: {
      country: 'USA',
      postalCode: '',
      state: '',
      city: '',
      timeOffset: -5,
      lat: 0,
      lng: 0,
    },
    type: 'RESIDENTIAL',
    accessorials: {
      liftgate: true,
    },
    date: nextBusinessDay(),
  },
  destination: {
    address: {
      country: 'USA',
      postalCode: '',
      state: '',
      city: '',
      timeOffset: -5,
      lat: 0,
      lng: 0,
    },
    type: 'RESIDENTIAL',
    accessorials: {
      liftgate: true,
    },
    date: nextBusinessDay(),
  },
  items: [genEmptyItem()],
  commonAccessorials: {},
};

export const BlankAddress = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  companyName: '',
  streetAddress: '',
  streetAddress1: '',
  postalCode: '',
  city: '',
  state: '',
  country: null,
  timeOffset: -8,
  lat: 0,
  lng: 0,
};

const atHour = (d: Date, h: number): Date =>
  dayjs(d).hour(h).minute(0).second(0).millisecond(0).toDate();

const genEmptyFullEndpoint = (): FullEndPoint => ({
  // @ts-expect-error TODO Couldn't get to some of these in
  // https://plslogistics.atlassian.net/browse/GS-736
  address: BlankAddress,
  instructions: '',
  startTime: atHour(new Date(), 8),
  endTime: atHour(new Date(), 16),
  // @ts-expect-error TODO Couldn't get to some of these in
  // https://plslogistics.atlassian.net/browse/GS-736
  type: null,
  accessorials: {},
  date: new Date(),
  saveToAddressBook: true,
});

const BlankQuote: FTLQuote = {
  shipmentType: 'FTL',
  guid: '',
  pickupDate: new Date(),
  deliveryDate: new Date(),
  cost: 0,
  transitTime: 0,
  truckType: Truck_Type.Van,
  baseRate: 0,
};

export const BlankCustoms: Customs = {
  broker: {
    ...BlankAddress,
    usePlsBroker: true,
    brokerAuthorized: false,
    country: 'USA',
  },
  vendor: {
    ...BlankAddress,
    useDelivery: false,
    usePickup: false,
    country: 'USA',
  },
  purchaser: {
    ...BlankAddress,
    useDelivery: false,
    usePickup: false,
    country: 'CAN',
  },
  termsOfSale: '',
  createCustomsInvoice: false,
};

export const BlankBookingRequest: BookingRequest = {
  origin: {
    ...genEmptyFullEndpoint(),
  },
  destination: {
    ...genEmptyFullEndpoint(),
  },
  billing: {
    ...genEmptyFullEndpoint(),
  },
  items: [genEmptyItem()],
  quote: BlankQuote,
  commonAccessorials: {
    // For Formik to work properly
    straps: undefined,
    ppe: undefined,
    tarps: undefined,
    chains: undefined,
    loadBars: undefined,
    noFreeze: undefined,
    coilRacks: undefined,
    loadLocks: undefined,
    temperature: undefined,
    edgeProtectors: undefined,
  },
  bookingDetails: {
    insuredValue: 0,
    promoCode: '',
    acknowledgeShipmentAccuracy: false,
  },
  customs: BlankCustoms,
};

export const isBlankBookingRequest = (bookingRequest: BookingRequest) =>
  bookingRequest === BlankBookingRequest;

export const isBlankRFQ = (rfq: RFQ) => rfq === BlankRFQ;
