import { captureException } from '@sentry/nextjs';
import { noop } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga4';
import { GA4 } from 'react-ga4/types/ga4';

import { User } from 'types';

export type TrackingContext = {
  trackSignUp: (args: { referralCode?: string }) => void;
  trackPurchase: (
    id: string,
    revenue: number,
    shipping: number,
    carrierName: string,
    customerName: string,
    promoCode: string,
    discount: number,
    shipmentType: string,
    bookingId: string,
  ) => void;
  trackStartPayment: (
    revenue: number,
    carrierName: string,
    customerName: string,
    promoCode: string,
    discount: number,
    shipmentType: string,
    shipping: number,
    bookingId: string,
    user?: User,
  ) => void;
};

const useTracking = (): TrackingContext => {
  const router = useRouter();
  const notifyGA = useRef(noop);
  const notifyHotJar = useRef<(s: string) => void>(noop);
  const [ga, setGA] = useState<GA4>();
  useEffect(() => {
    const installGoogleAnalytics = async (id: string) => {
      ReactGA.initialize(id, { gaOptions: { debug_mode: true } });
      setGA(() => ReactGA);
      const notify = () => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
      };
      notify();
      notifyGA.current = notify;
    };

    const installHotJar = async (hjid: number) => {
      const { hotjar } = await import('react-hotjar');
      hotjar.initialize(hjid, 6);
      notifyHotJar.current = (path: string) => hotjar.stateChange(path);
    };

    const installGoogleTagManager = async (gtmId: string) => {
      const { default: TagManager } = await import('react-gtm-module');
      const tagManagerArgs = {
        gtmId: gtmId,
      };
      TagManager.initialize(tagManagerArgs);
    };

    if (process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID) {
      installGoogleAnalytics(process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID);
    }

    if (process.env.NEXT_PUBLIC_GTM_ID) {
      installGoogleTagManager(process.env.NEXT_PUBLIC_GTM_ID);
    }

    installHotJar(2687181);
  }, []);
  useEffect(() => {
    notifyGA.current();
    notifyHotJar.current(router.asPath);
  }, [router.asPath]);

  const trackSignUp = async ({ referralCode }: { referralCode?: string }) => {
    try {
      const { default: ReactGA } = await import('react-ga4');

      if (referralCode) {
        ReactGA.event({
          category: 'ReferralSignUp',
          action: 'success',
        });
      }
      ReactGA.event({
        category: 'SignUp',
        action: 'success',
      });
    } catch (e) {
      captureException(e);
    }
  };

  const trackPurchase = async (
    id: string,
    revenue: number,
    shipping: number,
    carrierName: string,
    customerName: string,
    promoCode: string,
    discount: number,
    shipmentType: string,
    bookingId: string,
  ) => {
    try {
      if (ga) {
        ga.gtag(
          'config',
          process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID || 'G-CY4NQYEL8J',
          {
            user_id: customerName,
          },
        );
        ga.gtag('event', 'purchase', {
          transaction_id: id,
          value: revenue,
          currency: 'USD',
          shipping,
          coupon: promoCode,
          items: [
            {
              item_id: bookingId,
              item_name: carrierName,
              affiliation: customerName,
              coupon: promoCode,
              discount,
              index: 0,
              item_brand: 'GoShip',
              item_category: shipmentType,
              item_variant: 'green',
              price: shipping,
              quantity: 1,
            },
          ],
        });
      } else {
        ReactGA.initialize(
          process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID || 'G-CY4NQYEL8J',
          {
            gaOptions: { debug_mode: true },
          },
        );
        setGA(() => ReactGA);
        ReactGA.gtag(
          'config',
          process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID || 'G-CY4NQYEL8J',
          {
            user_id: customerName,
          },
        );
        ReactGA.gtag('event', 'purchase', {
          transaction_id: id,
          value: revenue,
          currency: 'USD',
          shipping,
          coupon: promoCode,
          items: [
            {
              item_id: bookingId,
              item_name: carrierName,
              affiliation: customerName,
              coupon: promoCode,
              discount,
              index: 0,
              item_brand: 'GoShip',
              item_category: shipmentType,
              item_variant: 'green',
              price: shipping,
              quantity: 1,
            },
          ],
        });
      }
    } catch (e) {
      captureException(e);
    }
  };

  const trackStartPayment = async (
    revenue: number,
    carrierName: string,
    customerName: string,
    promoCode: string,
    discount: number,
    shipmentType: string,
    shipping: number,
    bookingId: string,
    user?: User,
  ) => {
    try {
      if (ga) {
        if (user)
          ga.gtag('set', 'user_data', {
            email: user.email,
            phone_number: user.phoneNumber,
          });
        ga.gtag(
          'config',
          process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID || 'G-CY4NQYEL8J',
        );
        ga.gtag('event', 'add_payment_info', {
          value: revenue,
          currency: 'USD',
          coupon: promoCode,
          items: [
            {
              item_id: bookingId,
              item_name: carrierName,
              affiliation: customerName,
              coupon: promoCode,
              discount,
              index: 0,
              item_brand: 'GoShip',
              item_category: shipmentType,
              item_variant: 'green',
              price: shipping,
              quantity: 1,
            },
          ],
        });
      } else {
        ReactGA.initialize(
          process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID || 'G-CY4NQYEL8J',
          {
            gaOptions: { debug_mode: true },
          },
        );
        setGA(() => ReactGA);
        if (user)
          ReactGA.gtag('set', 'user_data', {
            email: user.email,
            phone_number: user.phoneNumber,
          });
        ReactGA.gtag(
          'config',
          process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID || 'G-CY4NQYEL8J',
        );
        ReactGA.gtag('event', 'add_payment_info', {
          value: revenue,
          currency: 'USD',
          coupon: promoCode,
          items: [
            {
              item_id: bookingId,
              item_name: carrierName,
              affiliation: customerName,
              coupon: promoCode,
              discount,
              index: 0,
              item_brand: 'GoShip',
              item_category: shipmentType,
              item_variant: 'green',
              price: shipping,
              quantity: 1,
            },
          ],
        });
      }
    } catch (e) {
      captureException(e);
    }
  };

  return {
    trackSignUp,
    trackPurchase,
    trackStartPayment,
  };
};

export default useTracking;
