import { gql } from '@apollo/client';

import { publicClient } from '../../graphql';

const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(input: { refreshToken: $refreshToken }) {
      accessToken
      refreshToken
      error
    }
  }
`;

export default async function refreshTokenGQL({
  refToken,
}: {
  refToken: string;
}) {
  const resp = await publicClient.mutate({
    mutation: REFRESH_TOKEN,
    variables: {
      refreshToken: refToken,
    },
  });

  if (resp.errors) {
    return { error: 'NetworkError' };
  }

  const accessToken = resp.data?.refreshToken?.accessToken;
  const refreshToken = resp.data?.refreshToken?.refreshToken;
  const error = resp.data?.refreshToken?.error;

  return { error, accessToken, refreshToken };
}
