/* eslint-disable react/jsx-no-target-blank */
import { anchorLink } from '@/styles/Colors';

export default function TOSCallout() {
  return (
    <div className="px-2 mb-4 text-xs">
      By entering phone number you consent to receive a call or text from
      GoShip.
      <br />
      <br />
      By signing in to your account, you are agreeing to the current GoShip{' '}
      <a
        target="_blank"
        href="https://www.goship.com/goship-terms"
        style={{ color: anchorLink }}>
        Terms of Use
      </a>
    </div>
  );
}
