import { gql } from '@apollo/client';

import graphqlClient, { publicClient } from '@/backend/graphql';
import { Query } from 'publicTypes';

import { LtlRfqInput } from '../../../types.d';

const REQUEST_LTL_QUOTE = gql`
  query RequestLTLQuote($rfq: LtlRfqInput!) {
    requestLTLQuote(rfq: $rfq) {
      guid
      transitTime
      cost
      baseRate
      deliveryDate
      shipmentType
      ltlAccessorialCharges {
        code
        description
        charge
      }
      carrier {
        name
        newLiability
        usedLiability
        reliabilityScore
      }
      proposal {
        guid
        pricingResult {
          profileId
          carrierName
          scac
          pricingType
          serviceType
          newProdLiability
          usedProdLiability
          transitTime
          totalMiles
          currencyCode
          carrierFinalLinehaul
          carrierFuelSurcharge
          shipperFinalLinehaul
          shipperFuelSurcharge
          totalShipperCost
          accessorials {
            accessorialType
            accessorialDescription
            carrierAccessorialCost
            applyCarrierCostBeforeFuel
            shipperAccessorialCost
            applyShipperCostBeforeFuel
            benchmarkAccessorialCost
            applyBenchmarkCostBeforeFuel
          }
          carrierQuoteNumber
        }
      }
    }
  }
`;

export default async function requestLTLQuote(
  rfq: LtlRfqInput,
  loggedIn: boolean,
) {
  let resp;
  if (loggedIn) {
    resp = await graphqlClient.query<Query>({
      query: REQUEST_LTL_QUOTE,
      variables: { rfq },
      fetchPolicy: 'network-only',
    });
  } else {
    resp = await publicClient.query<Query>({
      query: REQUEST_LTL_QUOTE,
      variables: { rfq },
      fetchPolicy: 'network-only',
    });
  }

  const ltlQuotes = resp?.data?.requestLTLQuote ?? [];

  return ltlQuotes;
}
